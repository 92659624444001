import $ from 'jquery';
import { sendApiRequest } from '../../data-manager/lib/opalConnector';

export async function getIpGeoData() {
	try {
		return (await $.getJSON('https://get.geojs.io/v1/ip/geo.json'));
	} catch (error) {
		throw new Error(`Cannot get tour data file at 'http://ip-api.com/json/'! Error: ${error}`);
	}
}

export async function postAnatlyticsData(gaData) {
	await sendApiRequest({ url: `/analytics/ga`, type: 'POST', data: gaData, auth: false });
}