import { cleanUndefinedKeysRecurse } from './util';
import Vector from './Vector';

export default class CameraTransform {
	constructor({
		translation,
		rotation,
	} = {}, { maxDecimals } = {}) {
		this.translation = new Vector(translation, { maxDecimals });
		this.rotation = new Vector(rotation, { maxDecimals });
	}

	serialize() {
		const ser = {
			translation: this.translation.serialize(),
			rotation: this.rotation.serialize(),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	isDefault() { return this.hasDefaultTranslation() && this.hasDefaultRotation(); }
	hasDefaultTranslation() { return this.translation.isDefault(); }
	hasDefaultRotation() { return this.rotation.isDefault(); }
}