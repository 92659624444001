import { cleanUndefinedKeysRecurse } from '../lib/util';
import UserRole from './UserRole';
import { userRoleTypes } from './userTypes';

/**
 * @typedef {import('./UserRole').UserRoleData & ChildClassData} UserProjectRoleData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _userProjectRoleDataVer
 * @ignore
 */

const latestDataVer = 1;

export default class UserProjectRole extends UserRole {
	static _targetType = userRoleTypes.project;

	/**
	 * @param {UserProjectRoleData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {UserProjectRoleData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._userProjectRoleDataVer = inputData._userProjectRoleDataVer || latestDataVer;

		if (this._userProjectRoleDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._userProjectRoleDataVer}', should be '${latestDataVer}'`); }
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_userProjectRoleDataVer: this._userProjectRoleDataVer,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_userProjectRoleDataVer: this._userProjectRoleDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}
}