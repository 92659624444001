import { dataUrlMatcher, globalBlobUrlMatcher } from './lib/util';
import { getUiKey, removeUiKey, saveUiKey } from '../main-ui/ui-util';
import { createCompany, deleteCompany, deleteProject, getCompanyMetadata, getCompanyUsers, getProjectMetadata, getProjects, getProjectUsers, getUserAccessibleCompanies, updateCompany, updateProject, addComment, deleteComment, getComments, updateComment, uploadUserCreatedBlob, createProject, undeleteCompany, undeleteProject, undeleteComment, getSingleComment } from './display-data/metadataExt';
import { createDeliverable, deleteDeliverable, getAllDeliverables, getDeliverable, undeleteDeliverable, updateDeliverable } from './display-data/tourDataExt';
import { deleteUserRole as extDeleteUserRole, getAllUserRoles, getUserRole, inviteUser, updateUserRole } from './users/userExt';
import { headUri } from './lib/utilExt';
import DataNotifier from './DataNotifier';
import UserRole from './users/UserRole';
import User from './users/User';
import Project from './display-data/projects/Project';
import Company from './display-data/companies/Company';
import Comment from './display-data/comments/Comment';
import _deliverableFactory from './display-data/deliverables/deliverableFactory';
import Deliverable from './display-data/deliverables/Deliverable';
import URI from 'urijs';

class DataManager {
	/** @type {Company[]} */
	#companies = [];
	/** @type {User[]} */
	#users = [];

	constructor(data) {
		this.notifier = new DataNotifier();
		if (data) {
			data.companies.forEach(company => this.#addCompany(company));
			data.users.forEach(user => this.#addUser(user));
		}
	}

	serialize() {
		const output = {
			companies: this.companies.map(company => company.serialize()),
			users: this.users.map(user => user.toJSON()),
		};

		return output;
	}

	clearCompanies() {
		this.#companies.length = 0;
	}

	/**
	 * @param {import('./display-data/companies/Company').CompanyData} inputObj 
	 * @returns {Company}
	 */
	#addCompany(inputObj) {
		const newCompany = new Company(inputObj);
		const existingCompany = this.getCompany(newCompany.companyId);

		if (existingCompany) {
			existingCompany._reInit(inputObj);
			return existingCompany;
		} else {
			this.#companies.push(newCompany);
			return newCompany;
		}
	}

	get companies() { return this.#companies; }
	getCompany(companyId) { return this.#companies.find(company => company.companyId === companyId); }

	#removeCompany(companyId) {
		const companyIndex = this.#companies.findIndex(comp => comp.companyId === companyId);
		if (companyIndex !== -1) { this.#companies.splice(companyIndex, 1); }
	}

	#addUser(inputObj) {
		const newUser = new User(inputObj);
		const existingUser = this.getUserById(newUser.userId);

		if (existingUser) {
			existingUser._reInit(inputObj);
		} else {
			this.#users.push(newUser);
		}

		this.#users.sort((first, second) => { // sort the users alphabetically (except if empty)
			if (!first.firstName) return 1;
			if (!second.firstName) return -1;
			return first.firstName.localeCompare(second.firstName);
		});

		return existingUser || newUser;
	}

	get users() { return this.#users; }
	getUserById(userId) { return this.#users.find(user => user.userId === userId); }
	getUserByEmail(email) { return this.#users.find(user => user.email === email.toLowerCase().trim()); }

	#removeUser(userId) {
		const userIndex = this.#users.findIndex(user => user.userId === userId);
		if (userIndex !== -1) { this.#users.splice(userIndex, 1); }
	}


	/*****************************************************************/
	/****************** COMPANY DATA API OPERATIONS ******************/
	/*****************************************************************/

	async createNewCompany({ companyName }) {
		const tempCompany = new Company({
			companyName
		});
		const company = await createCompany(tempCompany.serialize());
		const returnCompany = this.#addCompany(company);

		this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: [returnCompany] } });

		return returnCompany;
	}

	async downloadAllCompanies({ includeDeleted = false } = {}) {
		const companies = await getUserAccessibleCompanies({ includeDeleted });
		const returnCompanies = companies.map(company => this.#addCompany(company));
		this.companies.sort((a, b) => a.companyName.localeCompare(b.companyName));
		this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: returnCompanies } });
	}

	async downloadCompany(companyId) {
		const company = await getCompanyMetadata(companyId);
		const returnCompany = this.#addCompany(company);
		this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: [returnCompany], companyId } });
		return returnCompany;
	}

	/**
	 * @param {Company} company 
	 */
	async updateCompany(company) {
		if (dataUrlMatcher.test(company.logoFile)) {
			const getUrl = await uploadLocalUrl(company.logoFile, { companyId: company.companyId });
			company.logoFile = new URI(getUrl).filename(); // save only the filename since we already have the datalocation
		}
		await updateCompany(company.companyId, company.getUpdatableData());
		this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: [company], companyId: company.companyId } });
	}

	/**
	 * @param {Company} company 
	 */
	async deleteCompany(company) {
		await deleteCompany(company.companyId);
		this.#removeCompany(company.companyId);
		this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: [company], companyId: company.companyId } });
	}

	/**
	 * @param {Company} company 
	 */
	async undeleteCompany(company) {
		await undeleteCompany(company.companyId);
		await this.downloadCompany(company.companyId);
	}

	/*****************************************************************/
	/****************** PROJECT DATA API OPERATIONS ******************/
	/*****************************************************************/

	async createNewProject({ companyId, projectName }) {
		const existingCompany = this.getCompany(companyId);
		if (!existingCompany) throw new Error(`Cannot create new project with non-existant companyID ${companyId}`);

		const tempProject = new Project({ companyId, projectName });
		const projectData = await createProject(existingCompany.companyId, tempProject.serialize());
		const realProject = existingCompany._addProject(projectData);
		this.notifier.dispatchEvent({ eventId: 'dataProjectChange', data: { updatedProjects: [realProject], companyId } });
		return realProject;
	}

	async downloadAllProjects(companyId, { includeDeleted = false, includeArchived = false } = {}) {
		let existingCompany = this.getCompany(companyId);

		const companyExists = !!existingCompany;
		if (!companyExists) existingCompany = this.#addCompany({ companyId }); // add a placeholder so other data functions know we're retrieving data for it

		const { projects, company } = await getProjects(companyId, { includeCompanyData: !companyExists, includeDeleted, includeArchived });

		if (!companyExists) existingCompany = this.#addCompany(company);
		projects.map(project => existingCompany._addProject(project));
		existingCompany.projects.sort((a, b) => a.projectName.localeCompare(b.projectName));
		const newProjects = existingCompany.projects.map(project => project);

		if (!companyExists) { this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: [existingCompany], companyId } }); }
		// TODO we've been passing a single companyId/projectId in the data here, but instead we should probably pass every ID that has changed? maybe we don't care since that data is in the new projects data anyway?
		this.notifier.dispatchEvent({ eventId: 'dataProjectChange', data: { updatedProjects: newProjects, companyId } });

		return newProjects;
	}

	async downloadProject(companyId, projectId, { includeDeleted = false } = {}) {
		let existingCompany = this.getCompany(companyId);

		const companyExists = !!existingCompany;
		if (!companyExists) existingCompany = this.#addCompany({ companyId }); // add a placeholder so other data functions know we're retrieving data for it

		const { project, company } = await getProjectMetadata(companyId, projectId, { includeCompanyData: !companyExists, includeDeleted });

		if (!companyExists) existingCompany = this.#addCompany(company);
		const newProject = existingCompany._addProject(project);

		if (!companyExists) { this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: [existingCompany], companyId } }); }
		this.notifier.dispatchEvent({ eventId: 'dataProjectChange', data: { updatedProjects: [newProject], companyId, projectId } });

		return newProject;
	}

	/**
	 * @param {Project} project 
	 */
	async updateProject(project) {
		if (dataUrlMatcher.test(project.logoFile)) {
			const getUrl = await uploadLocalUrl(project.logoFile, { companyId: project.companyId, projectId: project.projectId });
			project.logoFile = new URI(getUrl).filename(); // save only the filename since we already have the datalocation
		}
		await updateProject(project.companyId, project.projectId, project.getUpdatableData());
		this.notifier.dispatchEvent({ eventId: 'dataProjectChange', data: { updatedProjects: [project], companyId: project.companyId, projectId: project.projectId } });
	}

	/**
	 * @param {Project} project 
	 */
	async unarchiveProject(project) {
		await updateProject(project.companyId, project.projectId, project.getUpdatableData(), { isCurrentlyArchived: true });
		this.notifier.dispatchEvent({ eventId: 'dataProjectChange', data: { updatedProjects: [project], companyId: project.companyId, projectId: project.projectId } });
	}

	/**
	 * @param {Project} project 
	 */
	async deleteProject(project) {
		await deleteProject(project.companyId, project.projectId);
		const company = this.getCompany(project.companyId);
		company._removeProject(project.projectId);
		this.notifier.dispatchEvent({ eventId: 'dataProjectChange', data: { updatedProjects: [project], companyId: project.companyId, projectId: project.projectId } });
	}

	/**
	 * @param {Project} project 
	 */
	async undeleteProject(project) {
		await undeleteProject(project.companyId, project.projectId);
		await this.downloadProject(project.companyId, project.projectId);
	}

	/***********************************************************************/
	/****************** DELIVERABLE DATA API OPERATIONS ******************/
	/***********************************************************************/

	// TODO this isn't used right now (we do it through the publisher), may not work properly when we initally set it up
	async createNewDeliverable({ type, companyId, projectId, revisionName, visibility }) {
		const existingCompany = this.getCompany(companyId);
		if (!existingCompany) throw new Error(`Cannot create new revision with non-existant companyID ${companyId}`);

		const existingProject = existingCompany.getProject(projectId);
		if (!existingProject) throw new Error(`Cannot create new revision with non-existant projectId ${projectId}`);

		const tempDeliverable = _deliverableFactory({ companyId, projectId, revisionName, visibility, type });
		const deliverable = await createDeliverable(existingCompany.companyId, existingProject.projectId, tempDeliverable.serialize());
		return existingProject._addDeliverable(deliverable);
	}

	async downloadAllDeliverables(companyId, projectId, { includeViewerData = false, includeDeleted = false, type } = {}) {
		let existingCompany = this.getCompany(companyId);

		const companyExists = !!existingCompany;
		if (!companyExists) existingCompany = this.#addCompany({ companyId }); // add a placeholder so other data functions know we're retrieving data for it

		let existingProject = existingCompany.getProject(projectId);

		const projectExists = !!existingProject;
		if (!projectExists) existingProject = existingCompany._addProject({ companyId, projectId });

		const { deliverables, company, project } = await getAllDeliverables(companyId, projectId, { includeViewerData, includeCompanyData: !companyExists, includeProjectData: !projectExists, includeDeleted, type });

		if (!companyExists) existingCompany = this.#addCompany(company);
		if (!projectExists) existingProject = existingCompany._addProject(project);

		/** @type {Deliverable[]} */
		const newDeliverables = deliverables.map(del => {
			return existingProject._addDeliverable(del);
		});

		if (includeViewerData === true) {
			existingProject._fillScenePointers();
		}

		if (!companyExists) { this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: [existingCompany], companyId } }); }
		if (!companyExists) { this.notifier.dispatchEvent({ eventId: 'dataProjectChange', data: { updatedProjects: [existingProject], companyId, projectId } }); }
		this.notifier.dispatchEvent({ eventId: 'dataDeliverableChange', data: { updatedDeliverables: newDeliverables, projectId } });

		return newDeliverables;
	}

	async downloadDeliverable(companyId, projectId, deliverableId, { includeViewerData = false, includeDeleted = false } = {}) {
		let existingCompany = this.getCompany(companyId);

		const companyExists = !!existingCompany;
		if (!companyExists) existingCompany = this.#addCompany({ companyId }); // add a placeholder so other data functions know we're retrieving data for it

		let existingProject = existingCompany.getProject(projectId);

		const projectExists = !!existingProject;
		if (!projectExists) existingProject = existingCompany._addProject({ companyId, projectId });

		const { deliverable, company, project } = await getDeliverable(companyId, projectId, deliverableId, { includeViewerData, includeCompanyData: !companyExists, includeProjectData: !projectExists, includeDeleted });

		if (!companyExists) existingCompany = this.#addCompany(company);
		if (!projectExists) existingProject = existingCompany._addProject(project);

		if (deliverable) {
			const newDeliverable = existingProject._addDeliverable(deliverable);

			if (includeViewerData === true) {
				existingProject._fillScenePointers();
			}

			if (!companyExists) { this.notifier.dispatchEvent({ eventId: 'dataCompanyChange', data: { updatedCompanies: [existingCompany], companyId } }); }
			if (!projectExists) { this.notifier.dispatchEvent({ eventId: 'dataProjectChange', data: { updatedProjects: [existingProject], companyId, projectId } }); }
			this.notifier.dispatchEvent({ eventId: 'dataDeliverableChange', data: { updatedDeliverables: [newDeliverable], projectId } });

			return newDeliverable;
		}
	}

	/**
	 * @param {Deliverable} deliverable 
	 */
	async updateDeliverable(deliverable, { includeTourData = false } = {}) {
		await updateDeliverable(deliverable.companyId, deliverable.projectId, deliverable.deliverableId, deliverable.getUpdatableData({ includeTourData }));
		// don't update the existing deliverable since it'll replace all the internals which might be in reference elsewhere (like scenes, etc). Everything should already be up-to-date anyway.

		this.notifier.dispatchEvent({ eventId: 'dataDeliverableChange', data: { updatedDeliverables: [deliverable], projectId: deliverable.projectId } });
	}

	/**
	 * @param {Deliverable} deliverable 
	 */
	async deleteDeliverable(deliverable) {
		if (!deliverable.isLocalOnly) {
			await deleteDeliverable(deliverable.companyId, deliverable.projectId, deliverable.deliverableId);
		}

		const company = this.getCompany(deliverable.companyId);
		const project = company.getProject(deliverable.projectId);
		project._removeDeliverable(deliverable.deliverableId);
		project._fillScenePointers(); // deleted rev may have been pointed to by other scenes, so redo those
		this.notifier.dispatchEvent({ eventId: 'dataDeliverableChange', data: { updatedDeliverables: [deliverable], projectId: deliverable.projectId } });
	}

	/**
	 * @param {Deliverable} deliverable 
	 */
	async undeleteDeliverable(deliverable) {
		const undeletedDel = await undeleteDeliverable(deliverable.companyId, deliverable.projectId, deliverable.deliverableId);
		deliverable.project._addDeliverable(undeletedDel); // reinit this data to make sure it's the latest
	}


	/*****************************************************************/
	/****************** COMMENT DATA API OPERATIONS ******************/
	/*****************************************************************/

	async downloadAllComments(companyId, projectId, { includeDeleted = false } = {}) {
		const company = this.getCompany(companyId);
		const project = company?.getProject(projectId);

		if (!project) throw new Error(`Unable to get comments; project not downloaded ${projectId}`);

		// TODO implement params
		const comments = await getComments(companyId, projectId, { includeDeleted });
		const returnComments = comments.map(comment => project._addComment(comment));

		this.notifier.dispatchEvent({ eventId: 'dataCommentChange', data: { updatedComments: returnComments, companyId, projectId } });
	}

	async downloadComment(companyId, projectId, comUuid, { includeDeleted = false } = {}) {
		const company = this.getCompany(companyId);
		const project = company?.getProject(projectId);

		if (!project) throw new Error(`Unable to get comments; project not downloaded ${projectId}`);

		const comment = await getSingleComment(companyId, projectId, comUuid, { includeDeleted });

		const returnComment = project._addComment(comment);

		this.notifier.dispatchEvent({ eventId: 'dataCommentChange', data: { updatedComments: [returnComment], companyId, projectId } });
	}

	/**
	 * @param {Comment} comment 
	 */
	async saveNewComment(comment) {
		await uploadBlobsInComment(comment);

		const newComment = await addComment(comment);

		const company = this.getCompany(newComment.companyId);
		const project = company.getProject(newComment.projectId);

		const returnComment = project._addComment(newComment);

		this.notifier.dispatchEvent({ eventId: 'dataCommentChange', data: { updatedComments: [returnComment], companyId: returnComment.companyId, projectId: returnComment.projectId } });

		return returnComment;
	}

	/**
	 * @param {Comment} comment 
	 */
	async updateComment(comment) {
		await uploadBlobsInComment(comment);
		const updatedCommentData = new Comment(await updateComment(comment, comment.getUpdatableData()));

		comment._reInit(updatedCommentData); // make sure we add in any data that might have been updated in the backend, like resolved status data

		this.notifier.dispatchEvent({ eventId: 'dataCommentChange', data: { updatedComments: [comment], companyId: comment.companyId, projectId: comment.projectId } });
	}

	/**
	 * @param {Comment} comment 
	 */
	async deleteComment(comment) {
		const updatedComment = new Comment(await deleteComment(comment));

		// This is the only thing that should have changed
		comment.deletionData = updatedComment.deletionData;

		this.notifier.dispatchEvent({ eventId: 'dataCommentChange', data: { updatedComments: [comment], companyId: comment.companyId, projectId: comment.projectId } });
	}

	/**
	 * @param {Comment} comment 
	 */
	async undeleteComment(comment) {
		const updatedComment = new Comment(await undeleteComment(comment));

		// This is the only thing that should have changed
		comment.deletionData = updatedComment.deletionData;

		this.notifier.dispatchEvent({ eventId: 'dataCommentChange', data: { updatedComments: [comment], companyId: comment.companyId, projectId: comment.projectId } });
	}


	/**************************************************************/
	/****************** USER DATA API OPERATIONS ******************/
	/**************************************************************/

	async downloadProjectUsers(companyId, projectId, { includeCompanyUsers = true, includeAbvrUsers = true } = {}) {
		const users = await getProjectUsers(companyId, projectId, { includeCompanyUsers, includeAbvrUsers });
		const newUsers = users.map(user => this.#addUser(user));
		this.notifier.dispatchEvent({ eventId: 'dataUsersChange', data: { updatedUsers: newUsers } });
	}

	async downloadCompanyUsers(companyId, { includeAbvrUsers = true } = {}) {
		const users = await getCompanyUsers(companyId, { includeAbvrUsers });
		const newUsers = users.map(user => this.#addUser(user));
		this.notifier.dispatchEvent({ eventId: 'dataUsersChange', data: { updatedUsers: newUsers } });
	}

	/**
	 * 
	 * @param {{
	 * 		email: string,
	 * 		project: Project,
	 * 		accessLevel: string,
	 * 		orgRole: string,
	 * }} param0
	 * @param {{
	 * 		shouldSendEmail: boolean,
	 * }} param1
	 * @returns 
	 */
	async inviteNewUser({ email, project, accessLevel, orgRole, }, { shouldSendEmail = true } = {}) {
		const user = await inviteUser({ email, companyId: project.companyId, projectId: project.projectId, accessLevel, orgRole }, { shouldSendEmail });
		const newUser = this.#addUser(user);

		this.notifier.dispatchEvent({ eventId: 'dataUsersChange', data: { updatedUsers: [newUser] } });
		return newUser;
	}


	/*******************************************************************/
	/****************** USER ROLE DATA API OPERATIONS ******************/
	/*******************************************************************/

	async downloadAllUserRoles(userId) {
		let existingUser = this.getUserById(userId);
		const userExists = !!existingUser;

		const { roles, user } = await getAllUserRoles(userId, { includeUserData: !userExists });
		if (!userExists) existingUser = this.#addUser(user);

		const newRoles = roles.map(role => existingUser._addRole(role));

		this.notifier.dispatchEvent({ eventId: 'dataUsersChange', data: { updatedUsers: [existingUser] } });

		return newRoles;
	}

	async downloadUserCompanyRole(userId, companyId, { effectiveRole = false } = {}) {
		let existingUser = this.getUserById(userId);
		const userExists = !!existingUser;

		const { role, user } = await getUserRole(userId, companyId, { effectiveRole, includeUserData: !userExists });
		if (!userExists) existingUser = this.#addUser(user);

		const newRole = existingUser._addRole(role);

		this.notifier.dispatchEvent({ eventId: 'dataUsersChange', data: { updatedUsers: [existingUser] } });

		return newRole;
	}

	async downloadUserProjectRole(userId, companyId, projectId, { effectiveRole = false } = {}) {
		let existingUser = this.getUserById(userId);
		const userExists = !!existingUser;

		const { role, user } = await getUserRole(userId, companyId, { projectId, effectiveRole, includeUserData: !userExists });
		if (!userExists) existingUser = this.#addUser(user);

		const newRole = existingUser._addRole(role);

		this.notifier.dispatchEvent({ eventId: 'dataUsersChange', data: { updatedUsers: [existingUser] } });

		return newRole;
	}

	/**
	 * @param {UserRole} userRole 
	 */
	async updateUserRole(userRole) {
		const user = this.getUserById(userRole.userId);
		if (!user.roles.includes(userRole)) throw new Error('User role does not exist on this user');

		await updateUserRole(userRole.serialize(), userRole.getUpdatableData());

		this.notifier.dispatchEvent({ eventId: 'dataUsersChange', data: { updatedUsers: [user] } });
	}

	/**
	 * @param {UserRole} userRole 
	 */
	async deleteUserRole(userRole) {
		const user = this.getUserById(userRole.userId);
		if (!user.roles.includes(userRole)) throw new Error('User role does not exist on this user');

		await extDeleteUserRole(userRole.serialize());
		user._removeRole(userRole);

		if (user.roles.length === 0) this.#removeUser(user.userId);

		this.notifier.dispatchEvent({ eventId: 'dataUsersChange', data: { updatedUsers: [user] } });
	}

	async doesUriExist(absoluteUri) {
		try {
			await headUri(absoluteUri, { logError: false });
			return true;
		} catch (error) {
			if (error.status === 404 || error.status === 403) {
				return false;
			} else {
				throw error;
			}
		}
	}

	// Only use this if the browser will start downloading a file, since otherwise it will navigate to this location!
	async startDownload(absoluteUrl) {
		window.location = absoluteUrl;
	}
}

/**
 * @param {Comment} comment 
 */
async function uploadBlobsInComment(comment) {
	const blobs = [];
	comment.text.replace(globalBlobUrlMatcher, urlStr => {
		blobs.push(urlStr);
		return urlStr;
	});

	if (blobs.length) {
		await Promise.all(blobs.map(async localBlobUrl => {
			try {
				const remoteContentUrl = await uploadLocalUrl(localBlobUrl, { companyId: comment.companyId, projectId: comment.projectId });

				comment.text = comment.text.replace(localBlobUrl, remoteContentUrl);
			} catch (error) {
				console.error('Cannot upload blobs in comment', error);
				error.message = `Cannot upload blobs in comment: ${error.message}`;
				throw error;
			}
		}));
	}
}

async function uploadLocalUrl(localUrl, { companyId, projectId }) {
	const response = await fetch(localUrl);
	let blob = await response.blob();
	// blob = await blob.toJPEG(); // TODO this is currently larger than the png file, so deal with it later

	if (blob.size > 10 * 2 ** 20) {
		throw new Error('Image in comment is too large, images must be smaller than 10MB');
	}

	const remoteContentUrl = await uploadUserCreatedBlob(companyId, projectId, blob);
	return remoteContentUrl;
}

let siz = new DataManager();

if (import.meta.hot) {
	import.meta.hot.on('vite:beforeFullReload', () => {
		saveUiKey('siz', siz.serialize(), { global: true });
	});
}

const saved = getUiKey('siz', { global: true });
if (saved && saved !== 'undefined') {
	try {
		console.log('[ABVR HMR] Setting sizzle data from saved...');
		const parsed = saved; // this is now automatically parsed in getUiKey
		setSizData(parsed);
	} catch (e) {
		console.error(saved);
		console.error(e);
	}

	removeUiKey('siz', { global: true });
}

// Used for hot-reload
function setSizData(data) {
	siz = new DataManager(data);
	window.siz = siz;
}

export default siz;
window.siz = siz;