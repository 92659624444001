import $ from 'jquery';
import URI from 'urijs';
import { getDocument } from 'pdfjs-dist/build/pdf.min.js'; // When there's an issue about rebuilding native modules for the canvas package, you need to include the GTK library like this: https://github.com/Automattic/node-canvas/wiki/Installation:-Windows
// NOTE: Cannot update pdfjs-dist to v3.x because it is dependent on canvas which needs a native rebuild with electron, and that fails for some reason
import 'pdfjs-dist/build/pdf.worker.entry.js'; // Need to include this in our package, otherwise we get the following error: `Error: No "GlobalWorkerOptions.workerSrc" specified.`
import { isDevelopment, globalUrlMatcher } from '../data-manager/lib/util';
import Bowser from 'bowser';
import { svgToDataURL } from './ui-svgToData';

const browser = Bowser.getParser(window.navigator.userAgent);

export function setupDevMode() { // If we're in development, setup our viewer so we know it (yellow top bar)
	$('#topNav').removeClass('bg-dark');
	$('#topNav').addClass('bg-warning');
}

export function browserIsMobile() {
	return browser.getPlatformType(true) === 'mobile';
}

export function browserIsTablet() {
	return browser.getPlatformType(true) === 'tablet';
}

export function browserIsDesktop() {
	return browser.getPlatformType(true) === 'desktop';
}

export function setupFormValidation(form) {
	// Fetch all the forms we want to apply custom Bootstrap validation styles to loop over them and prevent submission
	let forms;
	if (form) {
		forms = $(form).find('.needs-validation').addBack('.needs-validation'); // addback will add the inital element if we actually pass a form
	} else {
		forms = $('.needs-validation');
	}

	forms.each((index, form) => {
		$(form).on('submit', (event) => {
			performFormValidation(form, event);
		});
	});
}

// Use this function to perform form validation externally instead of setting up an automated validation on form submission. Optionally handle bubbling 
export function performFormValidation(form, event) {
	if (!$(form).hasClass('needs-validation')) {
		console.error(`Form ${form} does not have the 'needs-validation' class - is this the correct element?`);
		return false;
	}

	$(form).find('.date-form-validation').each((index, dateInput) => { // go through each of the date inputs to make sure it's a valid format
		if (isNaN(Date.parse($(dateInput).val()))) { // if it's an invalid date
			dateInput.setCustomValidity('invalid date'); // sets the validation to fail for this element so the form.checkValidity() call below will fail
		} else {
			dateInput.setCustomValidity('');
		}
	});

	let isValid = false;
	if (form.checkValidity() === false) {
		$(form).removeClass('is-valid');
		event?.stopImmediatePropagation(); // don't execute other handlers on this same element
	} else {
		$(form).addClass('is-valid');
		isValid = true;
	}

	$(form).addClass('was-validated');
	event?.preventDefault();
	event?.stopPropagation(); // TODO this will cause the submit function to not bubble up! we need to fix the rest of the forms that use this

	return isValid;
}

export function fixHtml(str) {
	if (!str && str !== 0) { return str; } // if undefined, NULL, etc
	if (typeof str !== 'string') { str = String(str); }
	return str.replace(/\r\n\s*|\n\s*|\r\s*|\t/g, ''); // removes any newlines & spaces after, and tabs. jquery input needs this
}

export function linkify(text) {
	return text.replace(globalUrlMatcher, (urlStr) => {
		if (urlIsImage(urlStr)) {
			// const url = window.URI(urlStr);
			return `<a href="${urlStr}" target="_blank"><img class="comment-image img-thumbnail mt-1" src="${urlStr}"></a>`;
		} else if (urlIsBlob(urlStr)) {
			if (window.navigator && window.navigator.msSaveOrOpenBlob) { // required for Edge (https://stackoverflow.com/questions/24007073/open-links-made-by-createobjecturl-in-ie11), (https://stackoverflow.com/questions/44070437/how-to-get-a-file-or-blob-from-an-url-in-javascript)
				return `<span onclick="fetch('${urlStr}').then(res => res.blob()).then(blob => window.navigator.msSaveOrOpenBlob(blob, 'clipboard.jpg'))" style="cursor: pointer;"><img class="comment-image img-thumbnail mt-1" src="${urlStr}"></span>`;
			}
			return `<a href="${urlStr}" target="_blank"><img class="comment-image img-thumbnail mt-1" src="${urlStr}"></a>`;
		} else {
			return `<a href="${urlStr}" target="_blank">Link</a>`;
		}
	});
}

function urlIsImage(url) {
	return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

function urlIsBlob(url) {
	return (url.match(/^blob:/) != null);
}

export function addUriAsRedirectParam(uriObj, { redirectUri = new URI() } = {}) {
	const curState = URI.build({ path: redirectUri.path(), query: redirectUri.query(), fragment: redirectUri.fragment() });
	uriObj.addQuery('abvrState', curState);
}

export function getRedirectParamFromCurUri() {
	const badSignOutRedir = getUiKey('badTokenSignOutKey', { global: true }); // this will be saved if opalConnector forces us to sign out, so go back to that point
	if (badSignOutRedir) {
		console.warn('GOT BAD TOKEN SIGN OUT KEY', badSignOutRedir);
		window.localStorage.removeItem('badTokenSignOutKey');
	}

	return badSignOutRedir || (new URI().query(true).abvrState) || '';
}

export function getRedirectUrl({ defaultPath }) {
	const redirectUrl = new URI(getRedirectParamFromCurUri() || defaultPath).absoluteTo(new URI());

	return redirectUrl;
}

export function saveUiKey(key, value, { global = false } = {}) {
	if (!global) { key += `-${window.location.pathname}`; }
	if (value === undefined || value === null) {
		window.localStorage.removeItem(key); // have to remove it, otherwise it'll save it as 'undefined' or 'null'
	} else {
		if (typeof value === 'object') value = JSON.stringify(value);
		window.localStorage.setItem(key, value);
	}
}

export function getUiKey(key, { global = false } = {}) {
	if (!global) { key += `-${window.location.pathname}`; }
	const raw = window.localStorage.getItem(key);
	if (raw === 'undefined') return undefined;
	if (raw === 'null') return null;

	try {
		const obj = JSON.parse(raw);
		return obj;
	} catch (e) {
		return raw;
	}
}

export function removeUiKey(key, { global = false } = {}) {
	if (!global) { key += `-${window.location.pathname}`; }
	return window.localStorage.removeItem(key);
}

export function setSelectToValueExists(selectEl, value) {
	const jSelectEl = $(selectEl);
	if (jSelectEl.find(`option[value=${value}]`).length) { jSelectEl.val(value); }
}

export function logAppData() {
	console.log('****************************************************************');
	console.log(`Starting Sizzle version '${process.env.ABVR_VERSION}' in '${process.env.ABVR_STAGE}'...`);
	console.log('version:', process.env.ABVR_VERSION);
	console.log('isDevelopment:', isDevelopment());
	console.log('NODE_ENV:', process.env.NODE_ENV);
	console.log('__dirname:', global.__dirname);
	console.log('location.href:', location.href);
	console.log('****************************************************************');
}

/**
 * 
 * @param {URI} uri 
 * @param {Object} param1 
 * @param {boolean} param1.includeCurUriAsRedirect 
 * @param {boolean} param1.replaceHistory 
 */
export function navigateToPage(uri, { includeCurUriAsRedirect, replaceHistory } = {}) {
	const badSignOutRedir = getUiKey('badTokenSignOutKey', { global: true }); // this will be saved if opalConnector forces us to sign out, so go back to that point
	const tempUri = new URI(uri);
	includeCurUriAsRedirect && addUriAsRedirectParam(tempUri, { redirectUri: badSignOutRedir ? new URI(badSignOutRedir) : new URI() }); // badSignOutRedir takes precedence over the current URI when it comes to using it as a redirect param
	replaceHistory ? window.location.replace(tempUri) : window.location.assign(tempUri);
}

export function removeClassesMatching($el, partialString) {
	$el.removeClass((index, className) => { // remove all the text- color classes
		const matchTest = new RegExp(`(^|\\s)${partialString}\\S+`, 'g');
		return (className.match(matchTest) || []).join(' ');
	});
}

export function getColorClass(color) {
	const colors = {
		red: 'text-danger',
		yellow: 'text-warning',
		green: 'text-success',
		blue: 'text-primary',
		gray: 'text-muted',
		black: 'text-dark',
		white: 'text-white',
	};

	return colors[color];
}

export async function imageFileToDataUrl(inputFile, {
	jpgQuality = 0.5, // good compression but artifacts if you zoom in really far. should be fine for our case
} = {}) {
	if (!inputFile) { return; }
	const canvas = $(`<canvas class="d-none"></canvas>`)[0]; // this is the canvas we'll use to create our url
	const pageScale = 1; // if we use a smaller number, it won't fill the whole map area
	let svg;

	switch (inputFile.type) {
		case 'application/pdf':
			const pdf = await getDocument(inputFile.path).promise;
			const page = await pdf.getPage(1);
			const viewport = page.getViewport({ scale: pageScale });
			canvas.height = viewport.height;
			canvas.width = viewport.width;
			await page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport }).promise; // render the page onto the canvas
			break;
		case 'image/jpeg':
		case 'image/jpg':
		case 'image/png':
		case 'image/avif':
		case 'image/webp':
			const image = new Image();
			await new Promise((resolve, reject) => {
				image.onload = function () {
					canvas.width = this.naturalWidth;
					canvas.height = this.naturalHeight;
					canvas.getContext('2d').drawImage(this, 0, 0);
					resolve();
				};

				image.onerror = function (event) {
					console.error(event);
					reject(`Could not download image ${inputFile.path}`);
				};

				image.src = inputFile.path;
			});
			break;
		case 'image/svg+xml':
			const reader = new FileReader();
			await new Promise((resolve, reject) => {
				reader.onload = function (e) {
					const svgData = e.target.result; // as text
					// Could come in with encodeing stuff at the top like <?xml version="1.0" encoding="utf-8"?>, so add the text to the div so we can use the children function
					const $svgData = $('<div></div>').append($(svgData));
					svg = $svgData.children('svg')[0];
					resolve();
				};

				reader.onerror = function (event) {
					console.error(event);
					reject(`Could not download image ${inputFile.path}`);
				};

				reader.readAsText(inputFile);
			});

			break;
		default:
			throw new Error(`Cannot create dataurl from type '${inputFile.type}'`);
	}

	let dataUrl;
	switch (inputFile.type) {
		case 'application/pdf':
		case 'image/jpeg':
		case 'image/jpg':
			dataUrl = canvas.toDataURL('image/jpeg', jpgQuality);
			break;
		case 'image/png':
		case 'image/avif':
		case 'image/webp':
			dataUrl = canvas.toDataURL('image/png');
			break;
		case 'image/svg+xml':
			dataUrl = await svgToDataURL(svg);
			break;
		default:
			throw new Error(`Missing output dataurl for type '${inputFile.type}`);
	}

	canvas.remove();
	return dataUrl;
}