import { cleanUndefinedKeysRecurse } from '../../lib/util';
import Deliverable from './Deliverable';
import { deliverableTypes } from './deliverableTypes';

/**
 * @typedef {import('./Deliverable').DeliverableData & ChildClassData} PublicTourData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _publicTourDataVer
 * @property {string} url
 * @ignore
 */

const latestDataVer = 1;

export default class PublicTour extends Deliverable {
    static _targetType = deliverableTypes.publicTour;

    /**
     * @param {PublicTourData} inputData 
     */
    constructor(inputData) {
        super(inputData);
    }

    /**
     * @param {PublicTourData} inputData 
     */
    _reInit(inputData) {
        super._reInit(inputData);
        this._publicTourDataVer = inputData._publicTourDataVer || latestDataVer;

        if (this._publicTourDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._publicTourDataVer}, should be ${latestDataVer}`); }

        this.url = inputData.url;
    }

    serialize() {
        const ser = {
            ...super.serialize(),
            _publicTourDataVer: this._publicTourDataVer,
            url: this.url,
        };

        return cleanUndefinedKeysRecurse(ser);
    }

    getUpdatableData() {
        // we can only request to directly update these
        const updates = {
            ...super.getUpdatableData(),
            _publicTourDataVer: this._publicTourDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
            url: this.url,
        };

        return cleanUndefinedKeysRecurse(updates);
    }
}