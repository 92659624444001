import Vector from '../../lib/Vector';
import { cleanUndefinedKeysRecurse, makeSimpleObjCopy } from '../../lib/util';
import Scene from '../scenes/Scene';
import { renderingTypes } from './renderingTypes';

/**
 * @typedef {Object} RenderingData
 * @property {number} _renderingDataVer
 * @property {renderingTypes} type
 * @property {string} renderId
 * @property {Date} renderDate
 * @property {Vector} renderedSize
 * @property {string} tileLocation
 * @property {string} tileTemplateStr
 * @property {string} previewImg
 * @property {string} thumbImg
 * @property {Object} capturedOptions
 */

const latestDataVer = 2;

/**
 * Contains data associated with a specific rendering (physical image) for a specific scene. Each SceneRenderingData holds the location of the associated image tiles, and which 
 * rendering options were rendered in that specific image (lighting scenario, finish scheme, etc). A scene can contain mutiple of these renderings, showing one at a time based
 * on selected viewer options.
 */
export default class Rendering {
	static _targetType = 'base';

	/** @type {Scene} */
	#tourScene;

	/**
	 * @param {RenderingData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {RenderingData} inputData 
	 */
	_reInit(inputData) {
		this._renderingDataVer = inputData._renderingDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._renderingDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._renderingDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.renderId = inputData.renderId;
		this.renderDate = inputData.renderDate ? new Date(inputData.renderDate) : undefined;
		this.tileLocation = inputData.tileLocation;
		this.tileTemplateStr = inputData.tileTemplateStr;
		this.previewImg = inputData.previewImg;
		this.thumbImg = inputData.thumbImg;
		this.renderedSize = new Vector(inputData.renderedSize);
		// TODO should this be its own class at some point?
		this.capturedOptions = {};
		if (inputData.capturedOptions) {
			Object.keys(inputData.capturedOptions).forEach(key => {
				this.#addRenderedOption({ categoryId: key, optionId: inputData.capturedOptions[key] });
			});
		}

		if (this.renderedSize.x && this.renderedSize.y) {
			this.aspectRatio = this.renderedSize.x / this.renderedSize.y;
		} else {
			// If we don't have a rendered size (old image), just use 2 (probably pano anyway)
			this.aspectRatio = 2;
		}
	}

	serialize() {
		const ser = {
			_renderingDataVer: this._renderingDataVer,
			type: this.type,
			renderId: this.renderId,
			renderDate: this.renderDate?.toISOString(),
			renderedSize: this.renderedSize.serialize(),
			tileLocation: this.tileLocation,
			tileTemplateStr: this.tileTemplateStr,
			previewImg: this.previewImg,
			thumbImg: this.thumbImg,
			capturedOptions: makeSimpleObjCopy(this.capturedOptions),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_renderingDataVer: this._renderingDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			renderDate: this.renderDate?.toISOString(),
			renderedSize: this.renderedSize.serialize(),
			capturedOptions: makeSimpleObjCopy(this.capturedOptions),
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	set scene(tourScene) { this.#tourScene = tourScene; }
	get scene() { return this.#tourScene; }

	/**
	 * Returns an encoded relative tile location url. The tile folders may include unsanitized chars like # and {}, so we need to sanitize it 
	 * for each subfolder in the path (otherwise it'll encode the slashes too).
	 * @returns {string}
	 */
	get encodedTileLocation() {
		const paths = this.tileLocation.replaceAll('\\', '/').split('/');
		// TODO: '+' chars don't work because krpano doesn't encode them away from the image name, but hte encode tile location function does!
		return paths.map(path => encodeURIComponent(path)).join('/').replaceAll('%2B', '+');
	}

	#addRenderedOption({ categoryId, optionId }) { this.capturedOptions[categoryId] = optionId; }

	/**
	 * Finds the SceneRenderingOptionChoice object that matches the provided category ID. Returns undefined if there's no captured option for that category
	 * in this rendering. Ex. This rendering captured the 'Light Scheme' option for category 'Finish Scheme'
	 * @param {string} categoryId 
	 */
	getRenderedOptionForCategory(categoryId) {
		return this.capturedOptions[categoryId];
	}
}