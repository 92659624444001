/**
The missing SVG.toDataURL library for your SVG elements.

Usage: SVGElement.toDataURL( type, { options } )

Returns: the data URL, except when using native PNG renderer (needs callback).

type	MIME type of the exported data.
        Default: image/svg+xml.
        Must support: image/png.
        Additional: image/jpeg.

options is a map of options: {
    [the rest of the options only apply when type='image/png' or type='image/jpeg']

    renderer: 'native'|'canvg'
        PNG renderer to use. Native renderer¹ might cause a security exception.
        Default: canvg if available, otherwise native.
}

See original paper¹ for more info on SVG to Canvas exporting.

¹ http://svgopen.org/2010/papers/62-From_SVG_to_Canvas_and_Back/#svg_to_canvas
 */

export async function svgToDataURL(_svg, type, options) {
    function defaultDebug(s) {
        // console.log('SVG.toDataURL:', s);
        return s;
    }
    const debug = options?.debug || defaultDebug;

    function exportSVG() {
        const svg_xml = XMLSerialize(_svg);
        const svg_dataurl = base64dataURLencode(svg_xml);
        debug(type + ' length: ' + svg_dataurl.length);

        return svg_dataurl;
    }

    function XMLSerialize(svg) {
        if (window.XMLSerializer) {
            debug('using standard XMLSerializer.serializeToString');
            return (new XMLSerializer()).serializeToString(svg);
        }
    }

    function base64dataURLencode(s) {
        let b64 = 'data:image/svg+xml;base64,';

        // https://developer.mozilla.org/en/DOM/window.btoa
        if (window.btoa) {
            debug('using window.btoa for base64 encoding');
            b64 += btoa(s);
        }

        return b64;
    }

    async function exportImage(type) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // TODO: if (options.keepOutsideViewport), do some translation magic?

        const svg_img = new Image();
        const svg_xml = XMLSerialize(_svg);
        svg_img.src = base64dataURLencode(svg_xml);

        return await new Promise((resolve, reject) => {
            svg_img.onload = function () {
                debug('exported image size: ' + [svg_img.width, svg_img.height]);
                canvas.width = svg_img.width;
                canvas.height = svg_img.height;
                ctx.drawImage(svg_img, 0, 0);

                // SECURITY_ERR WILL HAPPEN NOW
                const png_dataurl = canvas.toDataURL(type);
                debug(type + ' length: ' + png_dataurl.length);

                resolve(png_dataurl);
            };

            svg_img.onerror = function () {
                reject(`Can't export! Maybe your browser doesn't support ` +
                    'SVG in img element or SVG input for Canvas drawImage?\n' +
                    'http://en.wikipedia.org/wiki/SVG#Native_support');
            };
        });
    }

    // BEGIN MAIN

    type ||= 'image/svg+xml';
    options ||= {};

    switch (type) {
        case 'image/svg+xml':
            return exportSVG();

        case 'image/png':
        case 'image/jpeg':
            if (!options.renderer) {
                if (window.canvg) options.renderer = 'canvg';
                else options.renderer = 'native';
            }

            switch (options.renderer) {
                case 'native':
                    debug('using native renderer for png export. THIS MIGHT FAIL.');
                    return await exportImage(type);

                default:
                    debug(`unknown png renderer given, doing noting (${options.renderer})`);
            }

            break;

        default:
            debug(`Sorry! Exporting as '${type}' is not supported!`);
    }
}