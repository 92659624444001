import { cleanUndefinedKeysRecurse, rad } from './util';

export default class Vector {
	constructor({
		x = 0,
		y = 0,
		z = 0,
	} = {}, { maxDecimals } = {}) {
		this.x = parseFloat(x);
		this.y = parseFloat(y);
		this.z = parseFloat(z);

		if (maxDecimals !== undefined && maxDecimals !== null) {
			this.x = +this.x.toFixed(maxDecimals);
			this.y = +this.y.toFixed(maxDecimals);
			this.z = +this.z.toFixed(maxDecimals);
		}
	}

	serialize() {
		const ser = {
			x: +this.x.toFixed(2),
			y: +this.y.toFixed(2),
			z: +this.z.toFixed(2),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	static fromArgs(x, y, z) { return new Vector({ x, y, z }); }

	toRad() {
		return {
			x: rad(this.x),
			y: rad(this.y),
			z: rad(this.z),
		};
	}

	isDefault({ x = 0, y = 0, z = 0 } = {}) {
		return (this.x === x &&
			this.y === y &&
			this.z === z);
	}

	negative() { return Vector.fromArgs(-this.x, -this.y, -this.z); }

	add(v) {
		if (v instanceof Vector) return Vector.fromArgs(this.x + v.x, this.y + v.y, this.z + v.z);
		else return Vector.fromArgs(this.x + v, this.y + v, this.z + v);
	}

	subtract(v) {
		if (v instanceof Vector) return Vector.fromArgs(this.x - v.x, this.y - v.y, this.z - v.z);
		else return Vector.fromArgs(this.x - v, this.y - v, this.z - v);
	}

	multiply(v) {
		if (v instanceof Vector) return Vector.fromArgs(this.x * v.x, this.y * v.y, this.z * v.z);
		else return Vector.fromArgs(this.x * v, this.y * v, this.z * v);
	}

	divideBy(v) {
		if (v instanceof Vector) return Vector.fromArgs(this.x / v.x, this.y / v.y, this.z / v.z);
		else return Vector.fromArgs(this.x / v, this.y / v, this.z / v);
	}

	equals(v) { return this.x === v.x && this.y === v.y && this.z === v.z; }

	dot(v) { return this.x * v.x + this.y * v.y + this.z * v.z; }

	cross(v) {
		return Vector.fromArgs(
			this.y * v.z - this.z * v.y,
			this.z * v.x - this.x * v.z,
			this.x * v.y - this.y * v.x
		);
	}

	length() { return Math.sqrt(this.dot(this)); }
	unit() { return this.divideBy(this.length()); }

	min() { return Math.min(Math.min(this.x, this.y), this.z); }
	max() { return Math.max(Math.max(this.x, this.y), this.z); }

	toAngles() {
		return {
			theta: Math.atan2(this.z, this.x),
			phi: Math.asin(this.y / this.length())
		};
	}

	fromAngle(angleVector) { return Math.acos(this.dot(angleVector) / (this.length() * angleVector.length())); }

	toArray(n) { return [this.x, this.y, this.z].slice(0, n || 3); }

	clone() { return new Vector(this); }

	rotateXY(degAngle, { origin = new Vector() } = {}) {
		const rads = rad(degAngle);
		const cos = Math.cos(rads);
		const sin = Math.sin(rads);

		return new Vector({
			x: (cos * (this.x - origin.x)) + (sin * (this.y - origin.y)) + origin.x,
			y: (cos * (this.y - origin.y)) - (sin * (this.x - origin.x)) + origin.y,
			z: this.z,
		});

	}

	static randomDirection() { return Vector.fromAngles(Math.random() * Math.PI * 2, Math.asin(Math.random() * 2 - 1)); }

	static lerp(a, b, fraction) { return b.subtract(a).multiply(fraction).add(a); }

	/**
	 * @param {Vector[]} vectorArray 
	 */
	static maxBounds(vectorArray) {
		return new Vector({
			x: Math.max(...vectorArray.map(v => v.x)),
			y: Math.max(...vectorArray.map(v => v.y)),
			z: Math.max(...vectorArray.map(v => v.z)),
		});
	}

	/**
	 * @param {Vector[]} vectorArray 
	 */
	static minBounds(vectorArray) {
		return new Vector({
			x: Math.min(...vectorArray.map(v => v.x)),
			y: Math.min(...vectorArray.map(v => v.y)),
			z: Math.min(...vectorArray.map(v => v.z)),
		});
	}

	/**
	 * @param {Vector[]} vectorArray 
	 */
	static range(vectorArray) {
		const max = Vector.maxBounds(vectorArray);
		const min = Vector.minBounds(vectorArray);

		return new Vector({
			x: max.x - min.x,
			y: max.y - min.y,
			z: max.z - min.z,
		});
	}
}