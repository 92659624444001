import { postFormData, sendApiRequest } from '../lib/opalConnector';

/** @module */

// TODO should move these into individual files for each type of metadata (Company, Project, Deliverable, etc)

export async function createCompany(inputData) {
	const { company } = await sendApiRequest({ url: `/companies`, type: 'POST', data: inputData });
	return company;
}

export async function getUserAccessibleCompanies({ includeDeleted = false } = {}) {
	const { companies } = await sendApiRequest({ url: `/companies`, queryParams: { includeDeleted } });
	return companies;
}

export async function getCompanyMetadata(companyId) {
	const { company } = await sendApiRequest({ url: `/companies/${companyId}` });
	return company;
}

export async function updateCompany(companyId, updatedData, { isCurrentlyDeleted } = {}) {
	// Since we may need to update a company before un-deleteing it, we need to tell it if it's currently deleted otherwise it comes back with a 404
	const { company } = await sendApiRequest({ url: `/companies/${companyId}`, type: 'PUT', data: updatedData, queryParams: { undelete: isCurrentlyDeleted } });
	return company;
}

export async function deleteCompany(companyId) {
	const { company } = await sendApiRequest({ url: `/companies/${companyId}`, type: 'DELETE' });
	return company;
}

export async function undeleteCompany(companyId) {
	const { company } = await sendApiRequest({ url: `/companies/${companyId}`, type: 'PUT', queryParams: { undelete: true } });
	return company;
}




export async function createProject(companyId, inputData) {
	const { project } = await sendApiRequest({ url: `/companies/${companyId}/projects`, type: 'POST', data: inputData });
	return project;
}

export async function getProjects(companyId, { includeCompanyData = false, includeDeleted = false, includeArchived = false, } = {}) {
	const { projects, company } = await sendApiRequest({ url: `/companies/${companyId}/projects`, queryParams: { includeCompanyData, includeDeleted, includeArchived } });
	return { projects, company };
}

export async function getProjectMetadata(companyId, projectId, { includeCompanyData = false, includeDeleted = false } = {}) {
	const { project, company } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}`, queryParams: { includeCompanyData, includeDeleted } });
	return { project, company };
}

export async function updateProject(companyId, projectId, updatedData, { isCurrentlyDeleted, isCurrentlyArchived } = {}) {
	// Since we may need to update a project before un-deleteing it, we need to tell it if it's currently deleted otherwise it comes back with a 404. Same with unarchiving it.
	const { project } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}`, type: 'PUT', data: updatedData, queryParams: { undelete: isCurrentlyDeleted, unarchive: isCurrentlyArchived } });
	return project;
}

export async function deleteProject(companyId, projectId) {
	const { project } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}`, type: 'DELETE' });
	return project;
}

export async function undeleteProject(companyId, projectId) {
	const { project } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}`, type: 'PUT', queryParams: { undelete: true } });
	return project;
}




export async function getProjectUsers(companyId, projectId, { includeCompanyUsers = true, includeAbvrUsers = true } = {}) {
	const { users } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/users`, queryParams: { includeCompanyUsers, includeAbvrUsers } });
	return users;
}

export async function getCompanyUsers(companyId, { includeAbvrUsers = true } = {}) {
	const { users } = await sendApiRequest({ url: `/companies/${companyId}/users`, queryParams: { includeAbvrUsers } });
	return users;
}




export async function addComment(commentObj) {
	const { comment } = await sendApiRequest({ url: `/companies/${commentObj.companyId}/projects/${commentObj.projectId}/comments`, type: 'POST', data: commentObj });
	return comment;
}

export async function getComments(companyId, projectId, { includeDeleted = false } = {}) {
	let { comments, lastEvaluatedKey: exclusiveStartKey } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/comments`, queryParams: { includeDeleted } });

	while (exclusiveStartKey) {
		const paginatedResponse = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/comments`, queryParams: { includeDeleted, exclusiveStartKey } });
		comments = comments.concat(paginatedResponse.comments);
		exclusiveStartKey = paginatedResponse.lastEvaluatedKey;
	}

	return comments;
}

export async function getSingleComment(companyId, projectId, uuid, { includeDeleted = false } = {}) {
	const { comment } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/comments/${uuid}`, queryParams: { includeDeleted } });
	return comment;
}

export async function updateComment(commentObj, updatedData) {
	const { comment } = await sendApiRequest({ url: `/companies/${commentObj.companyId}/projects/${commentObj.projectId}/comments/${commentObj.uuid}`, type: 'PUT', data: updatedData });
	return comment;
}

// TODO should change the response to not return the raw data, but what we actually want. we're currently not doing anything with the returned value right now anyway
export async function deleteComment(commentObj) {
	const { comment } = await sendApiRequest({ url: `/companies/${commentObj.companyId}/projects/${commentObj.projectId}/comments/${commentObj.uuid}`, queryParams: { includeReplies: true }, type: 'DELETE' });
	return comment;
}

export async function undeleteComment(commentObj) {
	const { comment } = await sendApiRequest({ url: `/companies/${commentObj.companyId}/projects/${commentObj.projectId}/comments/${commentObj.uuid}`, type: 'PUT', queryParams: { undelete: true } });
	return comment;
}

export async function uploadUserCreatedBlob(companyId, projectId, blob) {
	function getFileExtensionFromBlob(blob) {
		switch (blob.type) {
			case 'image/jpeg':
				return 'jpg';
			case 'image/svg+xml':
				return 'svg';

			case 'image/png': // example of default
			default:
				return blob.type.split('/')[1];
		}
	}

	const { fields, postUrl, getUrl } = await sendApiRequest({ url: `/companies/${companyId}${projectId ? `/projects/${projectId}` : ''}/user-content-upload-url?fileExt=${getFileExtensionFromBlob(blob)}&size=${blob.size}` });

	const form = new FormData();
	for (const field in fields) { form.append(field, fields[field]); }
	form.append('file', blob);

	await postFormData({ absoluteUrl: postUrl, formData: form, auth: false });

	return getUrl;
}