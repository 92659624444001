import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { sceneLevelTypes } from './sceneLevelTypes';

/**
 * @typedef {Object} SceneLevelData
 * @property {number} _sceneLevelDataVer
 * @property {sceneLevelTypes} type
 * @property {number} tileSize
 * @property {boolean} isPreviewLevel
 */

const latestDataVer = 2;

export default class SceneLevel {
	static _targetType = 'base';

	/**
	 * @param {SceneLevelData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {SceneLevelData} inputData 
	 */
	_reInit(inputData) {
		this._sceneLevelDataVer = inputData._sceneLevelDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._sceneLevelDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._sceneLevelDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.tileSize = parseFloat(inputData.tileSize) || 0;
		this.isPreviewLevel = inputData.isPreviewLevel || false;
	}

	serialize() {
		const ser = {
			_sceneLevelDataVer: this._sceneLevelDataVer,
			type: this.type,
			tileSize: this.tileSize,
			isPreviewLevel: this.isPreviewLevel,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_sceneLevelDataVer: this._sceneLevelDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			tileSize: this.tileSize,
			isPreviewLevel: this.isPreviewLevel,
		};

		return cleanUndefinedKeysRecurse(updates);
	}
}