import { cleanUndefinedKeysRecurse } from '../../lib/util';
import NavMap from '../nav-maps/NavMap';
import RenderCategory from '../renderings/RenderCategory';
import Scene from '../scenes/Scene';
import Deliverable from './Deliverable';

/**
 * @typedef {import('./Deliverable').DeliverableData & ChildClassData} VirtualTourRevData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _virtualTourRevDataVer
 * @property {Scene[]} scenes
 * @property {NavMap[]} navMaps
 * @property {RenderCategory[]} renderCategories
 * @property {string} startSceneId
 * @ignore
 */

const latestDataVer = 1;

export default class VirtualTourRev extends Deliverable {
    static _targetType = 'virtualTourRev';

    /**
     * @param {VirtualTourRevData} inputData 
     */
    constructor(inputData) {
        super(inputData);
    }

    /**
     * @param {VirtualTourRevData} inputData 
     */
    _reInit(inputData) {
        super._reInit(inputData);
        this._virtualTourRevDataVer = inputData._virtualTourRevDataVer || latestDataVer;

        if (this._virtualTourRevDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._virtualTourRevDataVer}, should be ${latestDataVer}`); }

        // TODO this doesn't really reinit anything since we start with a blank array. We really need to keep references to any obj and remove ones that aren't in hte new input data
        // TODO could make these all private vars first with getters
        /** @type {Scene[]} */
        this.scenes = []; inputData.scenes?.forEach(scene => this._createScene(scene));
        /** @type {NavMap[]} */
        this.navMaps = []; inputData.navMaps?.forEach(map => this._createNavMap(map));
        /** @type {RenderCategory[]} */
        this.renderCategories = []; inputData.renderCategories?.forEach(category => this._createRenderCategory(category));
        this.startSceneId = inputData.startSceneId || this.scenes[0]?.sceneId; // default to the first scene
    }

    serialize({ includeTourData = this.isViewerDataIncluded } = {}) {
        const ser = {
            ...super.serialize(),
            _virtualTourRevDataVer: this._virtualTourRevDataVer,
        };

        if (includeTourData) {
            if (!this.isViewerDataIncluded) { throw new Error(`Cannot include tour data, data not included or downloaded. Company: ${this.project.company.companyName}, project: ${this.project.projectName}, revision: ${this.deliverableName}`); }

            ser.scenes = this.scenes.map(scene => scene.serialize());
            ser.navMaps = this.navMaps.map(map => map.serialize());
            ser.renderCategories = this.renderCategories.map(category => category.serialize());
            ser.startSceneId = this.startSceneId;
        }

        return cleanUndefinedKeysRecurse(ser);
    }

    getUpdatableData({ includeTourData = this.isViewerDataIncluded } = {}) {
        // we can only request to directly update these
        const updates = {
            ...super.getUpdatableData(),
            _virtualTourRevDataVer: this._virtualTourRevDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
        };

        if (includeTourData) {
            if (!this.isViewerDataIncluded) { throw new Error(`Cannot include tour data, data not included or downloaded.`); }

            updates.scenes = this.scenes.map(scene => scene.serialize());
            updates.navMaps = this.navMaps.map(map => map.serialize());
            updates.renderCategories = this.renderCategories.map(category => category.serialize());
            updates.startSceneId = this.startSceneId;
        }

        return cleanUndefinedKeysRecurse(updates);
    }

    get isViewerDataIncluded() { return this.scenes.length !== 0; }

    /**
     * @param {import('../scenes/Scene').SceneData} inputObj 
     * @returns {Scene}
     */
    // eslint-disable-next-line no-unused-vars
    _createScene(inputObj) { }

    getScene(sceneId) { return this.scenes.find(scene => scene.sceneId === sceneId); }
    _removeScene(sceneId, { includeHotspots, includeNavmaps } = {}) {
        if (includeHotspots) {
            this.scenes.forEach(scene => {
                const hs = scene.getHotspotToScene(sceneId);
                if (hs) scene.removeHotspot(hs);
            });
        }

        if (includeNavmaps) {
            this.navMaps.forEach(map => map.removeNavLocation(sceneId));
        }

        this.scenes = this.scenes.filter(scene => scene.sceneId !== sceneId);
    }

    _sortScenesByName() {
        this.scenes.sort((first, second) => {
            return first.title?.localeCompare(second.title) || 0; // might be a pointer right now, so may not have a title
        });
    }

    _createNavMap(inputObj) {
        const newMap = new NavMap(inputObj, { deliverable: this });

        if (this.getNavMap(newMap.mapId)) { this._removeNavMap(newMap.mapId); }

        this.navMaps.push(newMap);
        return newMap;
    }

    getNavMap(mapId) { return this.navMaps.find(map => map.mapId === mapId); }
    getNavMapByName(mapName) { return this.navMaps.find(map => map.mapName === mapName); }
    _removeNavMap(mapId) { this.navMaps = this.navMaps.filter(map => map.mapId !== mapId); }

    _createRenderCategory(inputObj) {
        const newRenderCategory = new RenderCategory(inputObj);
        newRenderCategory.deliverable = this;

        if (this.getRenderCategory(newRenderCategory.categoryId)) { this._removeRenderCategory(newRenderCategory.categoryId); }

        this.renderCategories.push(newRenderCategory);
        return newRenderCategory;
    }

    getRenderCategory(categoryId) { return this.renderCategories.find(category => category.categoryId === categoryId); }
    _removeRenderCategory(categoryId) { this.renderCategories = this.renderCategories.filter(category => category.categoryId !== categoryId); }

    /**
     * @param {VirtualTourRev} masterDeliverable 
     */
    _copyAuthoredDataFromPreviousDeliverable(masterDeliverable) {
        if (!masterDeliverable) {
            throw new Error(`No valid master deliverable selected! Can't copy data into current deliverable.`);
        } else if (masterDeliverable.projectId !== this.projectId) {
            throw new Error(`Master deliverable '${masterDeliverable.deliverableId}' is not from the same project (${masterDeliverable.projectId}) as our current deliverable (${this.projectId})! Can't copy data.`);
        } else if (masterDeliverable.type !== this.type) {
            throw new Error(`Master deliverable '${masterDeliverable.deliverableId}' does not have the same type (${masterDeliverable.type}) as our current deliverable (${this.type})! Can't copy data.`);
        }

        // Copy all of the render category data from the previous 
        masterDeliverable.renderCategories.forEach(masterCategory => {
            const matchingCategory = this.getRenderCategory(masterCategory.categoryId);
            matchingCategory?.copyData(masterCategory); // only care about category data if it already exists in our revision. Don't copy it otherwise since we won't have any renderings with that category.
        });

        // Copy over all of the navigation maps (must do this before copying scene data or else mapId won't exist)
        masterDeliverable.navMaps.forEach(masterMap => {
            const mapCopy = masterMap.serialize(); // make sure it's a different actual object so we don't change the previous rev by accident
            this._createNavMap(mapCopy);
        });

        // Copy all of the scene data
        masterDeliverable.scenes.forEach(masterScene => {
            const ourSameScene = this.getScene(masterScene.sceneId);
            if (ourSameScene && !ourSameScene.isPointer) { // if we have that scene in our list and it's not already a pointer, copy the data
                // If the scene we're trying to copy from is a pointer, then we need to make sure we have a copy of any nav map referenced from THAT pointed revision, not from the master revision
                if (masterScene.navMapId && masterScene.isPointer) {
                    const pointedMapCopy = masterScene.pointedSceneDeliverable.getNavMap(masterScene.navMapId);
                    if (pointedMapCopy) {
                        this._createNavMap(pointedMapCopy);
                    } else {
                        throw new Error(`Could not find nav map '${masterScene.navMapId}' in pointed revision '${masterScene.pointedSceneDeliverable.deliverableId}'`);
                    }
                }

                ourSameScene._copyDataFromOtherScene(masterScene);
            } else if (ourSameScene?.isPointer) {
                // We don't need to copy anything since this scene already exists as a pointer
                return;
            } else {
                // TODO need to be able to choose whether or not to add this scene in the future
                let shouldAdd = !(masterDeliverable.isLocalOnly && !masterScene.isPointer); // dont add if it's a REAL scene from a local deliverable, but add if it's a pointer. this can cause issues if there is no scene published, just pointers to a local one
                shouldAdd &&= masterDeliverable.creationDate < this.creationDate; // Don't add if the master deliverable is newer (since we can't point at a newer scene revision)
                if (shouldAdd) {
                    this._createScene(masterScene.toPointer());
                } else { // it's not part of our list - lets add it as a pointer
                    console.log(`Not adding scene '${masterScene.sceneId}' to our deliverable as a pointer; it either hasn't been published or is newer than our current deliverable.`);
                }
            }
        });

        this.startSceneId = masterDeliverable.startSceneId; // copy over the starting scene - since we just copied all the scenes from the master, the start scene should definitely be there, whether a pointer or a real scene

    }

    // Virtual function, override at child
    _setAllStartViewsAsGood() { }
}