// Most useful how-to information from https://github.com/aws-amplify/amplify-js/tree/master/packages/amazon-cognito-identity-js
// https://github.com/aws/amazon-cognito-auth-js/issues/3

import User from './User';
import { isIE } from '../lib/util';
import { sendApiRequest } from '../lib/opalConnector';
import * as cognitoAuth from '../lib/cognitoAuth';
import { acceptTCs } from './userExt';

export function getRedirectPageFromAuthState(authState) {
	cognitoAuth.validateCSRFStateParam(authState);
	return cognitoAuth.getAppStatefromStateParam(authState);
}

/**************************************************************/
/****************** ERROR HANDLING FUNCTIONS ******************/
/**************************************************************/

export async function handleAuthStateError(errorObj) {
	await cognitoAuth.handleAuthorizeEndpointError(errorObj);
}

// eslint-disable-next-line no-unused-vars
export async function handleApiAuthError({ code, message } = {}) {
	if (code === 'UserNotFoundException') { // happens if we delete the user while they have a valid id token. sign them out and send them back to home
		window.localStorage.setItem('badTokenSignOutKey', window.location.pathname + window.location.search);
		await signOutToHome();
	}
}


/**********************************************************************/
/****************** LOGGED IN USER & ROLE OPERATIONS ******************/
/**********************************************************************/

export function curUser() {
	const user = cognitoAuth.getCurrentUser();
	if (!user) return User._anonymousUser();
	else return user;
}
window.curUser = curUser; // TODO testing only

export async function logAuthData() {
	const signedIn = await isUserSignedIn();
	console.log('isUserSignedIn:', signedIn);
	if (signedIn) {
		console.log('isTokenExpired:', await cognitoAuth.isTokenExpired());
		console.log('tokenExpiration:', await cognitoAuth.timeToTokenExpiration());
		console.log('User email:', curUser().email);
	}
}

// // TODO have to get rid of this role downloading stuff
// async function updateLoggedInUser({ downloadUserRoles = true, awaitRoleDownload = true } = {}) {
// 	loggedInUser = await getAuthenticatedUser({ downloadUserRoles, awaitRoleDownload });
// 	return loggedInUser;
// }

export async function isUserSignedIn() {
	try {
		if (cognitoAuth.getCurrentUser()) {
			return true;
		} else {
			await cognitoAuth.getUserSession(); // if no user, try to setup session and then check again
			return !!cognitoAuth.getCurrentUser();
		}
	} catch (err) {
		if (!/please authenticate/i.test(err.message)) { // only log if we're just not logged in
			console.error('Error getting authed user during sign-in check', err);

			if (/Refresh Token has expired/i.test(err.message)) {
				window.localStorage.setItem('badTokenSignOutKey', window.location.pathname + window.location.search);
				await signOutToHome();
			}
		}

		return false;
	}
}

// let getRolesPromise;
// // TODO have to get rid of this role downloading stuff
// async function getAuthenticatedUser({ downloadUserRoles = true, awaitRoleDownload = true } = {}) {
// 	const user = await cognitoAuth.setupUserSession();
// 	if (!user) return;

// 	if (downloadUserRoles) {
// 		if (user.userId !== loggedInUser.userId || loggedInUser.roles.length === 0) {
// 			getRolesPromise = new Promise(async (resolve, reject) => {
// 				const { roles } = await getAllUserRoles(user.userId, { includeUserData: false });
// 				roles.forEach(role => user._addRole(role));
// 				resolve(true);
// 			});

// 			if (awaitRoleDownload) { await getRolesPromise; }
// 		}
// 	}

// 	return user;
// }

// export async function waitForFullUserRoleDownload() {
// 	if (!getRolesPromise && !loggedInUser.isAnonymous()) {
// 		console.warn('Roles were never downloaded! Downloading now...');
// 		await updateLoggedInUser({ downloadUserRoles: true, awaitRoleDownload: true });
// 	} else {
// 		await getRolesPromise;
// 	}
// }

/*******************************************************************/
/****************** SIGN IN / SIGN OUT OPERATIONS ******************/
/*******************************************************************/

export async function signUserIn(email, password) {
	const tempUser = new User({ email }); // run it through our email sanitizing setup
	await cognitoAuth.signUserIn(tempUser.email, password);
}

export async function signUserInWithAuthCode(authCode) {
	await cognitoAuth.setupUserSessionFromCode(authCode);
	const user = cognitoAuth.getCurrentUser();
	if (!user) {
		throw new Error('Could not sign in with auth code, no user created');
	}
}

export async function updateUserData() {
	await cognitoAuth.getUserData({ refreshFromSource: true });
	await cognitoAuth.forceTokenRefresh();
}

export async function gotoFederatedSignUpPage(provider, { abvrState, userAgreesToTCs } = {}) {
	if (!userAgreesToTCs) { throw new Error('Unable to sign up new user without agreement to Terms and Conditions'); }

	await cognitoAuth.navigateToFederatedSignInPage(provider, { abvrState }); // same page as sign in
}

export async function gotoFederatedLoginPage(provider, { abvrState } = {}) {
	await cognitoAuth.navigateToFederatedSignInPage(provider, { abvrState });
}

export async function signOutToHome() {
	try {
		await cognitoAuth.signUserOut(); // if we're logged in through an external provider, this will automatically navigate to the external logout page, and we'll end up back at the home page
	} catch (e) { // don't throw an error, make sure we catch so we can reload the page always
		console.error(e);
	}

	window.location.reload(); // need this to run through the auth checking at the beginning of the page again since signing out in this case doesn't go to the hosted cognito logout endpoint
}

// eslint-disable-next-line no-unused-vars
async function forceGoogleLogout() {
	// TODO This is currently a workaround because it logs the user out of all their accounts, forcing them to re-login later. We really want to use prompt=select_account as in https://stackoverflow.com/questions/58154256/aws-cognito-how-to-force-select-account-when-signing-in-with-google
	if (isIE()) {
		console.warn(`Logging out of Google won't work on IE11. Another browser is required, or manually navigate to 'https://accounts.google.com/logout'`);
	}

	return new Promise((resolve, reject) => {
		const iframe = document.createElement('iframe');
		iframe.setAttribute('src', 'https://accounts.google.com/logout');
		iframe.setAttribute('style', 'display:none;');
		iframe.style.width = 1 + 'px';
		iframe.style.height = 1 + 'px';
		document.body.appendChild(iframe);

		const timeout = setTimeout(reject, 5000);

		iframe.onload = (() => {
			clearTimeout(timeout);
			resolve(true);
		});
		iframe.onerror = (() => {
			clearTimeout(timeout);
			reject();
		});
	});
}

/******************************************************************/
/****************** SIGN UP & CONFIRM OPERATIONS ******************/
/******************************************************************/

/**
 * @param {User} user 
 * @param {string} password 
 */
export async function signUpNewUser(user, password, { abvrState, userAgreesToTCs } = {}) {
	const data = {
		userData: user,
		password,
		abvrState,
		userAgreesToTCs,
	};

	const { message } = await sendApiRequest({ url: '/users/sign-up', type: 'POST', data });
	// only message response. it should have sent an email to the user to confirm
	return message;
}

/**
 * @param {string} email 
 */
export async function resendVerificationCode(email, { abvrState } = {}) {
	const data = {
		email,
		abvrState,
	};

	const { message } = await sendApiRequest({ url: '/users/sign-up', type: 'PUT', data });
	// only message response. it should have sent an email to the user to confirm
	return message;
}

/**
 * @param {string} email 
 * @param {string} code 
 */
export async function confirmSignUp(email, code) {
	const tempUser = new User({ email }); // run it through our email sanitizing setup

	await cognitoAuth.verifyEmail(tempUser.email, code);
}

/**
 * @param {string} code 
 */
export async function verifyUpdatedEmailAddress(code) {
	await cognitoAuth.verifyEmailUpdate(code);
}

/**
 * @param {User} user 
 * @param {string} tempPassword 
 * @param {string} newPassword 
 */
export async function finishInvitedUserSetup(user, tempPassword, newPassword) {
	await cognitoAuth.finishInviteUser(user.email, tempPassword, newPassword, { firstName: user.firstName, lastName: user.lastName });
	await acceptTermsAndConditions(); // by signing up they've agreed, as posted on the sign-up page

	if (await isUserSignedIn()) {
		return;
	} else {
		throw new Error(`Unable to sign up. Please contact <a href="mailto:tech@atlasbayvr.com">tech@atlasbayvr.com</a>`);
	}
}

/****************************************************************/
/****************** FORGOT PASSWORD OPERATIONS ******************/
/****************************************************************/

export async function forgotPassword(email, { abvrState } = {}) {
	const data = {
		email,
		abvrState,
	};

	const { message } = await sendApiRequest({ url: '/users/forgot-password', type: 'PUT', data });
	// only message response. it should have sent an email to the user to confirm
	return message;
}

export async function forgotPasswordSubmit(email, forgotCode, newPassword) {
	const user = new User({ email }); // run it through our email sanitizing setup

	await cognitoAuth.verifyForgotPassword(user.email, forgotCode, newPassword);
}

/***********************************************************/
/****************** AUTH TOKEN OPERATIONS ******************/
/***********************************************************/

export async function getAuthToken() {
	return await cognitoAuth.getIdJwtToken();
}

async function acceptTermsAndConditions() {
	await acceptTCs(curUser().userId);
}