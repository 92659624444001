
import URI from 'urijs';
import { isFsBlocked } from './fullstory';
import { getIpGeoData, postAnatlyticsData } from './analyticsExt';
import { v4 as uuidv4 } from 'uuid';
import DataState from '../DataState';
import { curUser } from '../../data-manager/users/auth';

// TODO we need to change gtmData into a class here to make it more extensible

// TODO should add app info as on https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#apptracking
// TODO also might add timing info? some of it should be tracked already?
// TODO can we track exceptions? might be better to do that somewhere else for the backend, but the front end?
// TODO this whole thing should move from the sizzle lower layer into the ui layer since it relies on the current state

/********************************************************/
/****************** GLOBAL DEFINITIONS ******************/
/********************************************************/

let dataLayer = window['dataLayer'] || []; // make it an empty array if it doesn't exist currently (ex. if GTM is blocked)
let cid; // client ID used for manual analytics if GA is blocked (and if there's no userId)
let geoData; // save the geo data we get ourselves if the location is blocked
let state = 'uninitialized';
let gaIsBlocked = undefined;
const initCallbacks = [];


/*************************************************************/
/****************** INTIALIZATION FUNCTIONS ******************/
/*************************************************************/

export async function initAnalytics() { // don't normally await this function otherwise it will wait to run other script until we figure out if GA is blocked yet or not
	try {
		state = 'initializing';

		if (process.env.ABVR_MERCURY) {
			console.log('Not setting up analytics for mercury...');
			state = 'initialized';
			initCallbacks.forEach(callback => callback());
			return; // don't care about analytics for mercury
		}

		gaIsBlocked = await isGaBlocked();

		// Don't count on this before we send other events below
		dataLayer = window['dataLayer'] || []; // make it an empty array if it doesn't exist currently (ex. if GTM is blocked)
		// todo THIS RELIES ON CUR which doesnt exist anymore
		const gtmData = updateGtmDataLayer();

		if (gaIsBlocked) { // Google Anatlytics is not loaded
			geoData = await getIpGeoData();

			// Make sure we have an client ID for blocked analytics (either from local storage or create one)
			// window.localStorage.removeItem('cid'); // for debug testing to force a new CID
			cid = window.localStorage.getItem('cid');
			if (!cid) {
				cid = uuidv4();
				window.localStorage.setItem('cid', cid);
			}

			console.warn('Google anatlyics is blocked! Sending blocking event...');
			gtmData.gaData = {
				type: 'event',
				eventCategory: 'Blocking',
				eventAction: 'Google Analytics blocked',
				cid,
			};

			// Send the event to FullStory
			if (!await isFsBlocked()) {
				gtmData.app.fullstoryUrl = window.FS.getCurrentSessionURL();
				window.FS.event(gtmData.gaData.eventAction, gtmData);
			} else {
				console.warn('Fullstory is blocked!');
			}

			// Post blocking event to our analytics endpoint
			const collectionUrl = generateGoogleAnalyticsEventUrl(gtmData);
			await postAnatlyticsData({ collectionUrl: collectionUrl, geoData: geoData });

		}

		state = 'initialized';
		initCallbacks.forEach(callback => callback?.());
	} catch (error) {
		// if we have an error, don't stop running the rest of the page, just return;
		console.error(error);
		return;
	}
}

export function isAnalyticsInitialized() {
	return state === 'initialized';
}

export function isAnalyticsInitializing() {
	return state === 'initializing';
}

export async function waitForAnalyticsInit() {
	if (isAnalyticsInitialized()) return;
	if (isAnalyticsInitializing()) {
		await new Promise((resolve) => {
			initCallbacks.push(resolve);
		});
	} else {
		await initAnalytics();
	}
}



/***************************************************************/
/****************** ANALYTICS EVENT FUNCTIONS ******************/
/***************************************************************/

export async function sendVirtualPageView({ dataState } = {}) {
	if (process.env.ABVR_MERCURY) { return; } // don't do analytics with mercury

	await waitForAnalyticsInit();

	const gtmData = updateGtmDataLayer({ dataState }); // updates the page data in the datalayer first

	dataLayer.push({  // need to make sure this happens no matter what, otherwise window.ga won't be created
		event: 'virtualPageView',
		eventCategory: '',
		eventAction: '',
		eventLabel: '',
		eventValue: '',
	});

	if (gaIsBlocked) {
		gtmData.gaData = {
			type: 'pageview',
			cid: cid,
		};
		const collectionUrl = generateGoogleAnalyticsEventUrl(gtmData);
		console.log('sending page view');
		await postAnatlyticsData({ collectionUrl: collectionUrl, geoData: geoData });
	}
}

export async function sendSceneChangeEvent(changeMethod, { dataState } = {}) {
	sendCustomEvent('Change Scene', { dataState, eventAction: changeMethod, eventLabel: `${dataState.scene.area} - ${dataState.scene.title}` });
}

export async function sendRevisionChangeEvent(changeMethod, { dataState } = {}) {
	sendCustomEvent('Change Revision', { dataState, eventAction: changeMethod });
}

export async function sendRenderOptionChangeEvent({ dataState } = {}) {
	sendCustomEvent('Change Render Option', { dataState, eventAction: dataState.rendering.renderId });
}

export async function sendHistoryChangeEvent(action, { dataState } = {}) {
	sendCustomEvent('Browser History', { dataState, eventAction: action });
}

export async function sendUiEvent(eventAction, { dataState, eventLabel, eventValue } = {}) {
	sendCustomEvent('User Interface', { dataState, eventAction, eventLabel, eventValue });
}

export async function sendAdminEvent(eventAction, { dataState, eventLabel, eventValue } = {}) {
	sendCustomEvent('Admin', { dataState, eventAction, eventLabel, eventValue });
}

export async function sendCommentEvent(eventAction, { dataState, eventLabel, eventValue } = {}) {
	sendCustomEvent('Comments', { dataState, eventAction, eventLabel, eventValue });
}

export async function sendUserEvent(eventAction, { dataState, eventLabel, eventValue } = {}) {
	sendCustomEvent('Users', { dataState, eventAction, eventLabel, eventValue });
}

export async function sendAuthEvent(eventAction, { dataState, eventLabel, eventValue } = {}) {
	sendCustomEvent('Auth', { dataState, eventAction, eventLabel, eventValue });
}

// NOTE only eventAction (and eventCategory) will be visible in realtime analytics
async function sendCustomEvent(eventCategory, { dataState, eventAction, eventLabel, eventValue } = {}) {
	if (process.env.ABVR_MERCURY) { return; } // don't do analytics with mercury

	await waitForAnalyticsInit();

	// Send the event to GA
	const gtmData = updateGtmDataLayer({ dataState }); // updates the page data in the datalayer first

	const data = {
		event: 'abvrEvent',
		eventCategory,
	};

	gtmData.eventCategory = eventCategory;

	// Have to make sure we actually overwrite these values instead of not passing them. Also only want to check undefined in case we want to send 0 or false
	// TODO if these are sent to our endpoint, we end up with gtmdata.eventAction and gtmData.gaData.eventACtion. unsure why we had it set like that?
	if (eventAction !== undefined) { data.eventAction = gtmData.eventAction = eventAction; } else { data.eventAction = gtmData.eventAction = ''; }
	if (eventLabel !== undefined) { data.eventLabel = gtmData.eventLabel = eventLabel; } else { data.eventLabel = gtmData.eventLabel = ''; }
	if (eventValue !== undefined) { data.eventValue = gtmData.eventValue = eventValue; } else { data.eventValue = gtmData.eventValue = ''; }

	dataLayer.push(data); // need to make sure this happens no matter what, otherwise window.ga won't be created

	if (gaIsBlocked) {
		gtmData.gaData = {
			type: 'event',
			eventCategory,
			eventAction,
			eventLabel,
			eventValue,
			cid,
		};

		const collectionUrl = generateGoogleAnalyticsEventUrl(gtmData);
		await postAnatlyticsData({ collectionUrl: collectionUrl, geoData: geoData });
	}

	// Send the event to FullStory
	let fsEventText = eventAction || eventCategory;
	// if (eventLabel !== undefined) { fsEventText += ` - ${eventLabel}`; }
	// if (eventCategory === 'Change Scene' && gtmData.scene.nameString !== undefined) { fsEventText += ` - ${gtmData.scene.nameString}`; } // only care about the location if we're changing scenes
	// acutually we don't want to have it in the text otherwise there becomes a ton of different events and we don't want that.
	window.FS?.event(fsEventText, gtmData);

	return gtmData;
}



/******************************************************/
/****************** HELPER FUNCTIONS ******************/
/******************************************************/

async function isGaBlocked() {
	if (document.readyState === 'complete') { return !isGaLoadedAndValid(); } // if we're already loaded, return quickly

	return new Promise((resolve) => {
		const timeout = setTimeout(() => resolve(false), 5000); // wait max 5 seconds for the page to be loaded just to make sure they don't navigate away before we post a blocked event
		window.addEventListener('load', async () => {
			clearTimeout(timeout);
			resolve(!isGaLoadedAndValid());
		});
	});
}

// this should only be called after the page is loaded, otherwise it may give incorrect results
function isGaLoadedAndValid() {
	if (!window.google_tag_manager) { return false; }

	// Checking for window.ga at this point requires GA to be loaded by using the analytics.js tag on each page, otheriwse it gets loaded ansyncronously by GTM well after the pageload is complete. 
	// So if we check on page load, it'll say it doesn't exist and return false
	else if (!window.ga?.create) { return false; }

	return true;
}

/**
 * @param {Object} param0 
 * @param {DataState} param0.dataState 
 */
function updateGtmDataLayer({ dataState } = {}) {
	// Get the page location, but don't care about the index
	const splitPath = location.pathname.split('/');
	splitPath.pop(); // should always be empty or maybe index.html
	const urlStart = splitPath.pop();

	let renderOptionString;
	let virtualPageTitle;
	let virtualPageUrl;

	const gtmData = {
		app: {
			version: process.env.ABVR_VERSION,
			stage: process.env.ABVR_STAGE,
			fullstoryUrl: window.FS?.getCurrentSessionURL?.(),
		}
	};

	if (dataState?.company) {
		gtmData.company = {
			companyId: dataState.company.companyId,
			companyName: dataState.company.companyName,
		};

		if (dataState.project) {
			gtmData.project = {
				projectId: dataState.project.projectId,
				projectName: dataState.project.projectName,
			};

			virtualPageTitle = `${dataState.company.companyName} - ${dataState.project.projectName}`;
			virtualPageUrl = `${urlStart}/${dataState.company.companyName.toLowerCase()}/${dataState.project.projectName.toLowerCase()}`;

			if (dataState.deliverable) {
				// TODO this is totally wrong now, it's setup as tourRevision in GTM and has different params
				gtmData.deliverable = {
					metadataVersion: dataState.deliverable._panoRevDataVer, // TODO, no longer just the only metadata version
					deliverableId: dataState.deliverable.deliverableId,
					revisionName: dataState.deliverable.deliverableName,
					creationDate: dataState.deliverable.creationDate,
					dataLocation: dataState.deliverable.dataLocation,
					visibility: dataState.deliverable.visibility,
					startSceneId: dataState.deliverable.startSceneId,
				};

				if (dataState.scene) {
					gtmData.scene = {
						id: dataState.scene.sceneId,
						title: dataState.scene.title,
						area: dataState.scene.area,
						nameString: `${dataState.scene.area} - ${dataState.scene.title}`
					};

					virtualPageTitle = `${dataState.project.projectName} - ${dataState.scene.area} - ${dataState.scene.title}`;
					virtualPageUrl += `/${gtmData.scene.nameString.toLowerCase()}`;

					if (dataState.rendering) {
						// Create the right render option string
						const curRenderOptionChoices = [];// getCurRenderOptionChoicesForScene(dataState.scene); // TODO fix
						const optionChoices = curRenderOptionChoices.map((choice) => {
							const chosenCategoryObj = dataState.deliverable.getRenderCategory(choice.categoryId);
							const chosenOptionObj = chosenCategoryObj.getRenderOption(choice.optionId);
							return {
								categoryId: chosenCategoryObj.categoryId,
								categoryName: chosenCategoryObj.categoryName,
								optionId: chosenOptionObj.optionId,
								optionName: chosenOptionObj.optionName,
							};
						});

						renderOptionString = optionChoices.map(choice => choice.optionName);
						renderOptionString = renderOptionString.join(' - ');
						virtualPageTitle += renderOptionString ? ` - ${renderOptionString}` : '';
						virtualPageUrl += `/${renderOptionString.toLowerCase()}`;

						gtmData.rendering = {
							renderId: dataState.rendering.renderId,
							renderDate: dataState.rendering.renderDate,
							optionChoices: optionChoices.length ? optionChoices : undefined,
						};
					}
				}
			}
		}
	}

	if (!virtualPageTitle) { virtualPageTitle = document.title; }
	if (!virtualPageUrl) { virtualPageUrl = `${urlStart}/`; }

	if (!curUser().isAnonymous()) {
		gtmData.user = {
			userId: curUser().userId,
			email: curUser().email,
			name: curUser().fullName,
			username: curUser().userName,
		};
	}

	// copy our geographic data from here if we are blocked
	if (geoData) {
		gtmData.geoData = geoData;
	}

	gtmData.page = {
		title: virtualPageTitle,
		url: virtualPageUrl,
		curUrl: window.location.href,
	};

	dataLayer.push(gtmData);
	return gtmData;
}






const customDim = {
	companyName: 'cd1',
	projectName: 'cd2',
	revisionName: 'cd3',
	sceneAreaName: 'cd8',
	sceneName: 'cd5',
	renderId: 'cd6',
	userEmail: 'cd4',
	userId: 'cd9',
	appVersion: 'cd7',
	fullstoryUrl: 'cd10',
};

function generateGoogleAnalyticsEventUrl(gtmData) {
	const tempEscapeQuerySpace = URI.escapeQuerySpace;
	URI.escapeQuerySpace = false; // don't encode spaces as '+', use %20 so we match how analytics.js sends things to ga

	// https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters
	const url = new URI(`https://www.google-analytics.com/collect?v=1`);
	url.addQuery('tid', process.env.ABVR_GA_PROPERTY);

	// Event type and metadata (pageview, event, etc)
	addBaseGoogleAnalyticsData(url, gtmData.gaData);

	// Normal page data
	url.addQuery('dt', gtmData.page.title); // document title
	url.addQuery('dp', gtmData.page.url); // document path (what we want to show as the URL)
	url.addQuery('dl', gtmData.page.curUrl); // document location (actual whole URL with all query params etc)

	// Custom dimension data about where in the tour the event occurred
	url.addQuery(customDim.companyName, gtmData.company?.companyName); // company
	url.addQuery(customDim.projectName, gtmData.project?.projectName); // project
	url.addQuery(customDim.revisionName, gtmData.deliverable?.deliverableName); // deliverable
	url.addQuery(customDim.sceneAreaName, gtmData.scene?.area); // scene area
	url.addQuery(customDim.sceneName, gtmData.scene?.nameString); // scene name string
	url.addQuery(customDim.renderId, gtmData.rendering?.renderId); // rendering ID

	// User data
	url.addQuery('uid', gtmData.user?.userId);
	url.addQuery('cid', gtmData.user?.userId || gtmData.gaData.cid); // always create a CID otherwise it won't show up in non-userId views on GA. it should be created and stored by sizzle on the front-end so it's always the same since this function is stateless
	url.addQuery(customDim.userEmail, gtmData.user?.email); // email
	url.addQuery(customDim.userId, gtmData.user?.userId); // user ID - track this again so we can sort by it. Can't sort by 'uid' in normal views.

	// Important metadata
	url.addQuery(customDim.appVersion, gtmData.app?.version); // app version
	url.addQuery(customDim.fullstoryUrl, gtmData.app?.fullstoryUrl); // fullstory session URL (do not encode this otherwise it will be shown as such in reports)
	url.addQuery('uip', geoData.ip); // for a GA filter to work at excluding by IP addresses, we have to filter the anonyized IP address that this turns into (from 64.124.76.250 into 64.124.76.0). Otherwise hits sent from opal won't be tracked properly.
	url.addQuery('ua', navigator.userAgent);
	url.addQuery('ds', 'opal'); // data source
	url.addQuery('ul', navigator.language.toLowerCase());
	url.addQuery('de', document.characterSet);
	url.addQuery('sd', `${screen.colorDepth}-bit`);
	url.addQuery('sr', `${window.screen.width}x${window.screen.height}`);
	url.addQuery('vp', `${window.innerWidth}x${window.innerHeight}`);

	// TODO do we need traffic sources?
	// TODO need to define session control so we can get the same analytics data even if GA is blocked

	// We use a 'get' so lets make sure it gets all the way through and isn't cached along the way
	const cacheBustNum = Math.floor(Math.random() * 1000000000);
	url.addQuery('z', cacheBustNum);

	removeEmptyQueryKeys(url); // remove anything that was undefined when we set it

	URI.escapeQuerySpace = tempEscapeQuerySpace; // return to whatever it was previously
	return url.toString();
}

function addBaseGoogleAnalyticsData(uriObj, gaData) {
	uriObj.addQuery('t', gaData.type);
	switch (gaData.type) {
		case 'pageview':
			break;
		case 'event':
			uriObj.addQuery('ec', gaData.eventCategory);
			uriObj.addQuery('ea', gaData.eventAction);
			uriObj.addQuery('el', gaData.eventLabel);
			uriObj.addQuery('ev', gaData.eventValue);
			break;
		default:
			throw new Error(`BAD EVENT TYPE: ${gaData.type}`);
	}
}

function removeEmptyQueryKeys(uriObj) {
	uriObj.query(data => {
		for (let key in data) {
			if (!data[key]) { delete data[key]; }
		}
	});
}

