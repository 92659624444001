import { cleanUndefinedKeysRecurse, dataUrlMatcher, shortUuid } from '../../lib/util';
import Deliverable from '../deliverables/Deliverable';
import NavMapLocation from './NavMapLocation';
import NavMapTransform from './NavMapTransform';

/**
 * @typedef {Object} NavMapData
 * @property {number} _navMapDataVer
 * @property {string} type
 * @property {string} mapId
 * @property {string} mapName
 * @property {string} imagePath
 * @property {NavMapTransform} transform
 * @property {NavMapLocation[]} navLocations
 */

const latestDataVer = 1;

export default class NavMap {
	static _targetType = 'base';

	/** @type {Deliverable} */
	#deliverable;

	/**
	 * 
	 * @param {NavMapData} inputData 
	 * @param {*} inputData2 
	 */
	constructor(inputData, inputData2) {
		this._reInit(inputData, inputData2);
	}

	/**
	 * TODO fix the way this is setup, no need to have 2 inputs
	 * @param {NavMapData} inputData 
	 * @param {*} inputData2 
	 */
	_reInit(inputData, {
		/** @type {Deliverable} */
		deliverable,
	}) {
		this._navMapDataVer = inputData._navMapDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._navMapDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._navMapDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.mapId = inputData.mapId || shortUuid.new();
		this.mapName = inputData.mapName;
		this.imagePath = inputData.imagePath;
		this.transform = new NavMapTransform(inputData.transform);

		this.deliverable = deliverable;

		/** @type {NavMapLocation[]} */
		this.navLocations = [];
		inputData.navLocations?.forEach(location => this.createNavLocation(location));
	}

	/**
	 * @returns {NavMapData}
	 */
	serialize() {
		const ser = {
			_navMapDataVer: this._navMapDataVer,
			mapId: this.mapId,
			mapName: this.mapName,
			imagePath: this.imagePath,
			transform: this.transform.serialize(),
			navLocations: this.navLocations.map(navLoc => navLoc.serialize()),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_navMapDataVer: this._navMapDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			mapName: this.mapName,
			imagePath: this.imagePath,
			transform: this.transform.serialize(),
			navLocations: this.navLocations.map(navLoc => navLoc.serialize()),
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	set deliverable(deliverable) { this.#deliverable = deliverable; }
	get deliverable() { return this.#deliverable; }

	getImageRelativeUrl() {
		return this.imagePath;
	}

	getImageAbsoluteUrl() {
		if (!this.getImageRelativeUrl()) return;

		if (this.deliverable.isLocalOnly && !(/^tours\/.+/.test(this.getImageRelativeUrl()))) { // make sure it's not a real 'tours/...' location since it could be a local rev pointing at an existing map
			return this.getImageRelativeUrl();
		} else {
			if (dataUrlMatcher.test(this.imagePath)) {
				return this.imagePath;
			} else {
				return `${process.env.ABVR_COGNITO_SIGNIN}${this.getImageRelativeUrl()}`; // ABVR_COGNITO_SIGNIN already has a trailing slash
			}
		}
	}

	createNavLocation(inputObj) {
		const newLocation = new NavMapLocation(inputObj);
		this.navLocations.push(newLocation);

		newLocation.linkedScene = this.deliverable.getScene(newLocation.linkedSceneId);

		return newLocation;
	}

	getNavLocation(linkedSceneId) { return this.navLocations.find(loc => loc.linkedSceneId === linkedSceneId); }
	removeNavLocation(linkedSceneId) { this.navLocations = this.navLocations.filter(loc => loc.linkedSceneId !== linkedSceneId); }
	removeAllNavLocations() { this.navLocations.length = 0; }

	getScenesOnMap() { return this.navLocations.map(loc => loc.linkedScene).filter(scene => !!scene); }
	getSceneIdsOnMap() { return this.navLocations.map(loc => loc.linkedSceneId).filter(id => !!id); }
}