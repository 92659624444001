import { cleanUndefinedKeysRecurse } from '../../lib/util';
import Deliverable from '../deliverables/Deliverable';
import RenderOption from './RenderOption';

/**
 * @typedef {Object} RenderCategoryData
 * @property {number} _renderCategoryDataVer
 * @property {string} categoryName
 * @property {string} categoryId
 * @property {string} categoryDescription
 * @property {boolean} requiresNewLightbuildData
 * @property {RenderOption[]} optionList
 */

const latestDataVer = 1;

/**
 * Contains data to represent a category of rendering options that exists for a specific deliverable. A category (lighting scenario, finish scheme) is a collection of 
 * options, only one of which may be rendered in any single rendering. A specific rendering for a specific scene may implement either one or none of the options for each 
 * RenderCategory. This data is created from the JSON files provided with the UE4 renderings.
 */
export default class RenderCategory {
	/** @type {Deliverable} */
	#deliverable;

	/**
	 * @param {RenderCategoryData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {RenderCategoryData} inputData 
	 */
	_reInit(inputData) {
		this._renderCategoryDataVer = inputData._renderCategoryDataVer || latestDataVer;

		if (this._renderCategoryDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._renderCategoryDataVer}, should be ${latestDataVer}`); }

		this.categoryName = inputData.categoryName;
		this.categoryId = inputData.categoryId;
		this.categoryDescription = inputData.categoryDescription;
		this.requiresNewLightbuildData = inputData.requiresNewLightbuildData;

		/** @type {RenderOption[]} */
		this.optionList = []; inputData.optionList?.forEach(option => this._createRenderOption(option));
		this.optionList.sort((first, second) => first.optionName.localeCompare(second.optionName)); // sort so they're in a specific order for easy display
	}

	serialize() {
		const ser = {
			_renderCategoryDataVer: this._renderCategoryDataVer,
			categoryName: this.categoryName,
			categoryId: this.categoryId,
			categoryDescription: this.categoryDescription,
			requiresNewLightbuildData: this.requiresNewLightbuildData,
			optionList: this.optionList.map(option => option.serialize()),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const udpates = {
			_renderCategoryDataVer: this._renderCategoryDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			categoryName: this.categoryName,
			categoryDescription: this.categoryDescription,
			requiresNewLightbuildData: this.requiresNewLightbuildData,
			optionList: this.optionList.map(option => option.serialize()),
		};

		return cleanUndefinedKeysRecurse(udpates);
	}

	set deliverable(deliverable) { this.#deliverable = deliverable; }
	get deliverable() { return this.#deliverable; }

	/**
	 * Copies appropriate description data from one category (including options) into this category
	 * @param {RenderCategory} masterRenderCategory 
	 */
	copyData(masterRenderCategory) {
		if (masterRenderCategory.categoryId !== this.categoryId) throw new Error(`Cannot copy render category data from mismatched categoryId '${masterRenderCategory.categoryId}' into this category '${this.categoryId}'`);

		this.categoryName = masterRenderCategory.categoryName;
		this.categoryDescription = masterRenderCategory.categoryDescription;
		masterRenderCategory.optionList.forEach(masterOption => {
			const matchingOption = this.getRenderOption(masterOption.optionId);
			matchingOption?.copyData(masterOption);
		});
	}

	/**
	 * @param {Object} inputObj 
	 * @param {number} inputObj._renderOptionDataVer
	 * @param {string} inputObj.optionId
	 * @param {string} inputObj.optionName
	 * @param {string} inputObj.optionDescription
	 */
	_createRenderOption(inputObj) {
		const newOption = new RenderOption(inputObj);
		newOption.category = this;

		if (this.getRenderOption(newOption.optionId)) { this._removeRenderOption(newOption.optionId); }

		this.optionList.push(newOption);
		return newOption;
	}

	/**
	 * Finds the option in this category's internal option list matching the given ID.
	 * @param {string} optionId The ID of the RenderOption to find
	 * @returns {RenderOption}
	 */
	getRenderOption(optionId) { return this.optionList.find(option => option.optionId === optionId); }

	/**
	 * Removes the option from this category's internal option list matching the given ID.
	 * @param {string} optionId The ID of the RenderOption object to remove.
	 */
	_removeRenderOption(optionId) { this.optionList = this.optionList.filter(option => option.optionId !== optionId); }
}