import URI from 'urijs';
import { boolStringToBoolInObj, cleanUndefinedKeysRecurse, ensureOrCreateArray } from './util';
import Deliverable from '../display-data/deliverables/Deliverable';
import Scene from '../display-data/scenes/Scene';

export default class CommentsFilter {
	constructor({
		deliverableType,
		targetDeliverable,
		targetScene,
		states = ['open', 'moreInfo', 'qa', 'needApproval', 'closed'],
		startDate,
		endDate,
		authorId,
		replyTo,
		commentType,
		includeDeleted = false,
		search,
		includeAccessibleOnly = false,
	} = {}) {
		this.deliverableType = deliverableType;
		/** @type {Deliverable} */
		this.targetDeliverable = targetDeliverable;
		/** @type {Scene} */
		this.targetScene = targetScene;
		this.states = ensureOrCreateArray(states);
		this.startDate = startDate;
		this.endDate = endDate;
		this.authorId = authorId;
		this.replyTo = replyTo;
		this.commentType = commentType;
		this.includeDeleted = includeDeleted;
		this.search = search;
		this.includeAccessibleOnly = includeAccessibleOnly;
	}

	toUrl() {
		const urlObj = {
			deliverableType: this.deliverableType,
			states: this.states,
			startDate: this.startDate,
			endDate: this.endDate,
			authorId: this.authorId,
			replyTo: this.replyTo,
			commentType: this.commentType,
			includeDeleted: this.includeDeleted,
			search: this.search,
			includeAccessibleOnly: this.includeAccessibleOnly,
		};

		cleanUndefinedKeysRecurse(urlObj);

		return new URI().search(urlObj).query();
	}

	static fromUrl(url = window.location.href) {
		const params = new URI(url).search(true);
		boolStringToBoolInObj(params);

		return new CommentsFilter(params);
	}
}