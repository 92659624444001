import { cleanUndefinedKeysRecurse, modValIntoRange } from '../../lib/util';
import Vector from '../../lib/Vector';
import SceneLocation from './SceneLocation';
import { sceneLocationTypes } from './sceneLocationTypes';

/**
 * @typedef {import('./SceneLocation').SceneLocationData & ChildClassData} SceneFlatLocationData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _sceneFlatLocationDataVer
 * @property {number} x=0
 * @property {number} y=0
 * @ignore
 */

const latestDataVer = 1;

export default class SceneFlatLocation extends SceneLocation {
	static _targetType = sceneLocationTypes.flat;

	/**
	 * @param {SceneFlatLocationData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {SceneFlatLocationData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._sceneFlatLocationDataVer = inputData._sceneFlatLocationDataVer || latestDataVer;

		if (this._sceneFlatLocationDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._sceneFlatLocationDataVer}, should be ${latestDataVer}`); }

		this.x = parseFloat(inputData.x) || 0;
		this.y = parseFloat(inputData.y) || 0;
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_sceneFlatLocationDataVer: this._sceneFlatLocationDataVer,
			x: +this.x.toFixed(5),
			y: +this.y.toFixed(5),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_sceneFlatLocationDataVer: this._sceneFlatLocationDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			x: this.x,
			y: this.y,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	/**
	 * @param {Vector} vector 
	 */
	static fromVector(vector) {
		return SceneFlatLocation.#newFromInputs({
			x: vector.x,
			y: vector.y,
		});
	}

	/**
	 * Returns the x,y coords of the location
	 */
	getLocationParams() { return { x: this.x, y: this.y }; }

	/**
	 * A helper function to allow easy creation of a new object of this class (abstracting away the data versioning and typing needed for the factory function)
	 */
	static #newFromInputs({ x, y }) {
		return new SceneFlatLocation({
			x,
			y,
		});
	}

	round({ decimals }) {
		return {
			x: +this.x.toFixed(decimals),
			y: +this.y.toFixed(decimals),
		};
	}

	add(location) {
		return SceneFlatLocation.#newFromInputs({
			x: this.x + location.x,
			y: this.y + location.y,
		});
	}

	subtract(location) {
		return SceneFlatLocation.#newFromInputs({
			x: this.x - location.x,
			y: this.y - location.y,
		});
	}

	modIntoRange({ min = 0, max = 1 } = {}) {
		return SceneFlatLocation.#newFromInputs({
			x: modValIntoRange(this.x, min, max),
			y: modValIntoRange(this.y, min, max),
		});
	}

	isDefault() { return super.isDefault() && (this.x === 0 && this.y === 0); }

	equals(otherLocation) { return super.equals(otherLocation) && (this.x === otherLocation.x && this.y === otherLocation.y); }
}