import { cleanUndefinedKeysRecurse } from '../../lib/util';
import FlatRev from './FlatRev';
import { deliverableTypes } from './deliverableTypes';

/**
 * @typedef {import('./FlatRev').FlatRevData & ChildClassData} Floorplan3DRevData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _floorplan3DRevDataVer
 * @ignore
 */

const latestDataVer = 1;

export default class Floorplan3DRev extends FlatRev {
    static _targetType = deliverableTypes.floorplan3DRev;

    /**
     * @param {Floorplan3DRevData} inputData 
     */
    constructor(inputData) {
        super(inputData);
    }

    /**
     * @param {Floorplan3DRevData} inputData 
     */
    _reInit(inputData) {
        super._reInit(inputData);
        this._floorplan3DRevDataVer = inputData._floorplan3DRevDataVer || latestDataVer;

        if (this._floorplan3DRevDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._floorplan3DRevDataVer}, should be ${latestDataVer}`); }
    }

    serialize({ includeTourData = this.isViewerDataIncluded } = {}) {
        const ser = {
            ...super.serialize({ includeTourData }),
            _floorplan3DRevDataVer: this._floorplan3DRevDataVer,
        };

        return cleanUndefinedKeysRecurse(ser);
    }

    getUpdatableData({ includeTourData = this.isViewerDataIncluded } = {}) {
        // we can only request to directly update these
        const updates = {
            ...super.getUpdatableData({ includeTourData }),
            _floorplan3DRevDataVer: this._floorplan3DRevDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
        };

        return cleanUndefinedKeysRecurse(updates);
    }
}