import DeletionData from '../lib/DeletionData';
import { cleanUndefinedKeysRecurse } from '../lib/util';
import { userRoleTypes } from './userTypes';

/**
 * @typedef {Object} UserRoleData
 * @property {number} _userRoleDataVer
 * @property {userRoleTypes} type
 * @property {string} userId
 * @property {string} userEmail
 * @property {string} companyId
 * @property {string} projectId
 * @property {string} authRole
 * @property {string} orgRole
 * @property {Date} creationDate
 * @property {DeletionData} deletionData
 */

const latestDataVer = 1;

export default class UserRole {
	static _targetType = 'base';

	/**
	 * @param {UserRoleData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {UserRoleData} inputData 
	 */
	_reInit(inputData) {
		this._userRoleDataVer = inputData._userRoleDataVer || latestDataVer;
		/** @type {userRoleTypes} */
		this.type = inputData.type || this.constructor._targetType;

		if (this._userRoleDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._userRoleDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.userId = inputData.userId;
		this.userEmail = inputData.userEmail;
		this.companyId = inputData.companyId;
		this.projectId = inputData.projectId;
		this.authRole = inputData.authRole;
		this.orgRole = inputData.orgRole;
		this.creationDate = inputData.creationDate ? new Date(inputData.creationDate) : new Date();  // make sure creationDate isn't undefined since new Date(undefined) which we use in constructor returns Invalid Date
		this.deletionData = new DeletionData(inputData.deletionData);
	}

	serialize() {
		const ser = {
			_userRoleDataVer: this._userRoleDataVer,
			type: this.type,
			userId: this.userId,
			userEmail: this.userEmail,
			companyId: this.companyId,
			projectId: this.projectId,
			authRole: this.authRole,
			orgRole: this.orgRole,
			creationDate: this.creationDate.toISOString(),
			deletionData: this.deletionData.serialize(),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_userRoleDataVer: this._userRoleDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			userEmail: this.userEmail,
			authRole: this.authRole,
			orgRole: this.orgRole,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	isAdminRole() { return this.level <= UserRole.accessLevels['admin']; }
	isAbvrAdminRole() { return this.isRoleForAbvr() && (this.authRole === 'admin' || this.authRole === 'abvr'); }
	isPublicOnly() { return this.level >= UserRole.accessLevels['public']; }
	isRoleForCompany(companyId) { return this.companyId === companyId && this.type === userRoleTypes.company; }
	isRoleForAbvr() { return this.isRoleForCompany('abvr'); }
	isRoleForProject(companyId, projectId) { return this.companyId === companyId && this.projectId === projectId && this.type === userRoleTypes.project; }

	hasValidLevel() { return UserRole.isValidAccessLevel(this.authRole); }

	get level() {
		if (this.isAbvrAdminRole()) return UserRole.accessLevels['abvr'];

		return UserRole.accessLevels[this.authRole] || UserRole.accessLevels['public'];
	}

	hasAccessGreaterOrEqualTo(accessLevel) {
		if (this.isAbvrAdminRole()) return true;
		if (!UserRole.isValidAccessLevel(accessLevel)) {
			console.error(`Trying to check access against invalid level: ${accessLevel}`);
			return false;
		}

		return UserRole.accessLevels[this.authRole] <= UserRole.accessLevels[accessLevel];
	}

	static accessLevels = {
		abvr: 1, // don't make this 0 since we may test for !role.level even outside of this class, may cause issues...
		admin: 10,
		design: 20,
		basic: 30,
		external: 40,
		public: 50,
	};

	static isValidAccessLevel(accessLevel) {
		return UserRole.accessLevels[accessLevel] !== undefined;
	}
}