import Deliverable from './Deliverable';
import PanoRev from './PanoRev';
import PublicTour from './PublicTour';
import Floorplan3DRev from './Floorplan3DRev';
import { deliverableTypes } from './deliverableTypes';
import RenderRev from './RenderRev';

/** @module */

/**
 * @returns {Deliverable}
 */
export default function _deliverableFactory(inputObj = {}) {
    switch (inputObj.type) {
        case deliverableTypes.publicTour:
            return new PublicTour(inputObj);
        case deliverableTypes.panoRev:
            return new PanoRev(inputObj);
        case deliverableTypes.renderRev:
            return new RenderRev(inputObj);
        case deliverableTypes.floorplan3DRev:
            return new Floorplan3DRev(inputObj);
        default:
            throw new Error(`Cannot create Deliverable object with type '${inputObj.type}'`);
    }
}