import { cleanUndefinedKeysRecurse } from '../../lib/util';
import RenderCategory from './RenderCategory';

/**
 * @typedef {Object} RenderOptionData
 * @property {number} _renderOptionDataVer
 * @property {string} optionId
 * @property {string} optionName
 * @property {string} optionDescription
 */

const latestDataVer = 1;

/**
 * Contains data to represent a specific option within a rendering category. Example: category 'Finish Scheme' will contain options 'Light Finish' and 'Dark Finish'. 
 * This data is created from the JSON files provided with the UE4 renderings. Should only be created by the RenderCategory class and shouldn't exist by itself.
 */
export default class RenderOption {
	/** @type {RenderCategory} */
	#category;

	/**
	 * @param {RenderOptionData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {RenderOptionData} inputData 
	 */
	_reInit(inputData) {
		this._renderOptionDataVer = inputData._renderOptionDataVer || latestDataVer;

		if (this._renderOptionDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._renderOptionDataVer}', should be '${latestDataVer}'`); }

		this.optionName = inputData.optionName;
		this.optionId = inputData.optionId;
		this.optionDescription = inputData.optionDescription;
	}

	serialize() {
		const ser = {
			_renderOptionDataVer: this._renderOptionDataVer,
			optionName: this.optionName,
			optionId: this.optionId,
			optionDescription: this.optionDescription,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const udpates = {
			_renderOptionDataVer: this._renderOptionDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			optionName: this.optionName,
			optionDescription: this.optionDescription,
		};

		return cleanUndefinedKeysRecurse(udpates);
	}

	set category(category) { this.#category = category; }
	get category() { return this.#category; }

	/**
	 * @param {RenderOption} masterOption 
	 */
	copyData(masterOption) {
		if (masterOption.optionId !== this.optionId) throw new Error(`Cannot copy render option data from mismatched optionId '${masterOption.optionId}' into this option '${this.optionId}'`);

		this.optionName = masterOption.optionName;
		this.optionDescription = masterOption.optionDescription;
	}
}