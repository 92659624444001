import { sendApiRequest } from '../lib/opalConnector';

export async function getUri(uri, { isAbsolute = true, logError = true, auth = false } = {}) {
    const request = {
        logError,
        auth,
    };

    if (isAbsolute) {
        request.absoluteUrl = uri;
    } else {
        request.url = uri;
    }

    const rawData = await sendApiRequest(request);
    return rawData;
}

export async function headUri(uri, { isAbsolute = true, logError = true } = {}) {
    const request = {
        logError,
        type: 'HEAD',
    };

    if (isAbsolute) {
        request.absoluteUrl = uri;
    } else {
        request.url = uri;
    }

    const rawData = await sendApiRequest(request);
    return rawData;
}

export async function openNewWindowToUri(uri) {
    window.open(uri, '_blank');
}