import Vector from '../../lib/Vector';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { sceneLocationTypes } from './sceneLocationTypes';

/**
 * @typedef {Object} SceneLocationData
 * @property {number} _sceneLocationDataVer
 * @property {sceneLocationTypes} type
 */

const latestDataVer = 1;

export default class SceneLocation {
	static _targetType = 'base';

	/**
	 * @param {SceneLocationData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {SceneLocationData} inputData 
	 */
	_reInit(inputData) {
		this._sceneLocationDataVer = inputData._sceneLocationDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._sceneLocationDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._sceneLocationDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }
	}

	serialize() {
		const ser = {
			_sceneLocationDataVer: this._sceneLocationDataVer,
			type: this.type,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_sceneLocationDataVer: this._sceneLocationDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	/**
	 * Virtual function, override at child
	 * @param {Vector} vector 
	 */
	// eslint-disable-next-line no-unused-vars
	static fromVector(vector) { }

	/**
	 * Virtual function, override at child
	 */
	getLocationParams() { }

	/**
	 * Virtual function, override at child
	 */
	// eslint-disable-next-line no-unused-vars
	round({ decimals }) { }

	/**
	 * Virtual function, override at child
	 */
	// eslint-disable-next-line no-unused-vars
	add(location) { }

	/**
	 * Virtual function, override at child
	 */
	// eslint-disable-next-line no-unused-vars
	subtract(location) { }

	/**
	 * Virtual function, override at child
	 */
	// eslint-disable-next-line no-unused-vars
	modIntoRange({ min, max }) { }

	/**
	 * Virtual function, override at child
	 */
	isDefault() { return true; }

	/**
	 * Virtual function, override at child
	 */
	equals(otherLocation) { return this.type === otherLocation.type; }
}