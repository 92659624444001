import User from '../../data-manager/users/User';

/**
 * @param {User} user 
 */
export function setFullstoryIdentity(user) {
	window.FS?.identify(user.userId, {
		displayName: user.fullName,
		email: user.email,
	});
}

export async function isFsBlocked() {
	if (document.readyState === 'complete') { return !window.FS?.getCurrentSessionURL; } // if we're already loaded, return quickly

	return new Promise((resolve) => {
		const timeout = setTimeout(() => resolve(false), 100);
		window.addEventListener('load', () => {
			clearTimeout(timeout);
			resolve(!window.FS?.getCurrentSessionURL);
		});
	});
}

export async function setupFullstoryConsole(enabled) {
	if (await isFsBlocked()) {
		console.warn('Cannot setup fullstory console, FS is blocked...');
	} else {
		addCallbackToFsReady(() => {
			enabled ? window.FS.enableConsole() : window.FS.disableConsole();
		});
	}
}

export async function shutDownFullstoryRecording() {
	if (await isFsBlocked()) {
		console.warn('Cannot stop fullstory recording, FS is blocked...');
	} else {
		addCallbackToFsReady(() => {
			window.FS.shutdown();
		});
	}
}

function addCallbackToFsReady(newCallback) {
	window['_fs_ready'] = (function () {
		const cached = window['_fs_ready'];
		return function () {
			cached?.apply(this, arguments);
			newCallback();
		};
	})();
}
