/** @module */

/**
 * @enum {string}
 * @includevals
 */
export const hotspotTypes = {
    /**
     * Type for hotspot data in panoramic tours
     */
    pano: 'pano',
    /**
     * Type for hotspot data in flat tours
     */
    flat: 'flat',
};