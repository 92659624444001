import Hotspot from './Hotspot';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { hotspotTypes } from './hotspotTypes';
import ScenePanoLocation from '../scene-locations/ScenePanoLocation';

/**
 * @typedef {import('./Hotspot').HotspotData & ChildClassData} HotspotPanoData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _hotspotPanoDataVer
 * @ignore
 */

const latestDataVer = 1;

/**
 * @extends Hotspot
 */
export default class HotspotPano extends Hotspot {
	static _targetType = hotspotTypes.pano;

	/**
	 * @param {HotspotPanoData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {HotspotPanoData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._hotspotPanoDataVer = inputData._hotspotPanoDataVer || latestDataVer;
		
		if (this._hotspotPanoDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._hotspotPanoDataVer}, should be ${latestDataVer}`); }
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_hotspotPanoDataVer: this._hotspotPanoDataVer,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_hotspotPanoDataVer: this._hotspotPanoDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	/**
	 * @param {ScenePanoLocation} location 
	 */
	setCoordsFromLocation(location) {
		// force creation of a new object for safety
		this.location = new ScenePanoLocation(location);
	}
}