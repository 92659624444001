import Scene from './Scene';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import ScenePanoView from '../scene-views/ScenePanoView';
import { sceneTypes } from './sceneTypes';
import SceneLevelCubeGeo from '../scene-levels/SceneLevelCubeGeo';
import RenderingPano from '../renderings/RenderingPano';
import HotspotPano from '../hotspots/HotspotPano';

/**
 * @typedef {import('./Scene').SceneData & ChildClassData} ScenePanoData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _scenePanoDataVer
 * @ignore
 */

const latestDataVer = 1;

export default class ScenePano extends Scene {
	static _targetType = sceneTypes.pano;

	/**
	 * @param {ScenePanoData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {ScenePanoData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._scenePanoDataVer = inputData._scenePanoDataVer || latestDataVer;

		if (this._scenePanoDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._scenePanoDataVer}, should be ${latestDataVer}`); }
	}

	serialize() {
		if (this.isPointer) {
			return this.toPointer();
		} else {
			const ser = {
				...super.serialize(),
				_scenePanoDataVer: this._scenePanoDataVer,
			};

			return cleanUndefinedKeysRecurse(ser);
		}
	}

	toPointer() {
		return {
			...super.toPointer(),
			_scenePanoDataVer: this._scenePanoDataVer,
		};
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_scenePanoDataVer: this._scenePanoDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	setStartView({ pitch, yaw, fov } = {}) {
		this.startView = new ScenePanoView({
			pitch,
			yaw,
			fov,
		});
		return this.startView;
	}

	/**
	 * @param {import('../scene-levels/SceneLevelCubeGeo').SceneLevelCubeGeoData} inputObj 
	 */
	_createLevel(inputObj) {
		const newLevel = new SceneLevelCubeGeo(inputObj);
		this.levels.push(newLevel);
		return newLevel;
	}

	_createHotspot(inputObj) {
		const newHotspot = new HotspotPano(inputObj);
		newHotspot.scene = this;

		this.hotspots.push(newHotspot);
		return newHotspot;
	}

	_createRendering(inputObj) {
		const newRendering = new RenderingPano(inputObj);
		newRendering.scene = this;

		this.renderings.push(newRendering);
		return newRendering;
	}
}