import { cleanUndefinedKeysRecurse } from '../../lib/util';
import Vector from '../../lib/Vector';

/**
 * @typedef {Object} NavMapTransformData
 * @property {number} _navMapTransformDataVer
 * @property {string} type
 * @property {Vector} translation
 * @property {number} rotation=0
 * @property {Vector} size
 */

const latestDataVer = 1;

export default class NavMapTransform {
	static _targetType = 'base';

	/**
	 * @param {NavMapTransformData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {NavMapTransformData} inputData 
	 */
	_reInit(inputData = {}) {
		this._navMapTransformDataVer = inputData._navMapTransformDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._navMapTransformDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._navMapTransformDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.translation = new Vector(inputData.translation || { x: 0, y: 0 });
		this.rotation = parseFloat(inputData.rotation) || 0; // make sure this is a decimal (may have gotten saved as a string)
		this.size = new Vector(inputData.size || { x: 100, y: 100 });
	}

	serialize() {
		const ser = {
			_navMapTransformDataVer: this._navMapTransformDataVer,
			rotation: this.rotation,
			size: this.size.serialize(),
			translation: this.translation.serialize(),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_navMapTransformDataVer: this._navMapTransformDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			rotation: this.rotation,
			size: this.size.serialize(),
			translation: this.translation.serialize(),
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	isDefault() {
		return (this.rotation === 0 &&
			this.translation.isDefault({ x: 0, y: 0 }) &&
			this.size.isDefault({ x: 100, y: 100 }));
	}

	/**
	 * Takes in a real world or UE4 vector location and returns where that location is with respect to 0-1 in the x/y locations on the map.
	 * Values outside of 0-1 range in the returned vector mean the location does not appear on the map.
	 * @param {Vector} vector 
	 */
	getRelativeLocationFromVector(vector) {
		const absolute = vector.subtract(this.translation);
		const rotated = absolute.rotateXY(-this.rotation);
		const scaled = rotated.divideBy(this.size);
		scaled.z = 0;
		return scaled;
	}

	/**
	 * Takes in a 0-1 x/y vector location representing a point on the map and returns a real-world or UE4 vector location. Z will always be 0
	 * @param {Vector} relativeLocation 
	 */
	getVectorFromRelativeLocation(relativeLocation) {
		const unscaled = relativeLocation.multiply(this.size);
		const unrotated = unscaled.rotateXY(this.rotation);
		const absolute = unrotated.add(this.translation);
		absolute.z = 0;
		return absolute;
	}
}