import Vector from '../../lib/Vector';
import { cleanUndefinedKeysRecurse, modValIntoRange } from '../../lib/util';
import SceneView from './SceneView';
import { sceneViewTypes } from './sceneViewTypes';

/**
 * @typedef {import('./SceneView').SceneViewData & ChildClassData} SceneFlatViewData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _sceneFlatViewDataVer
 * @property {number} x=0
 * @property {number} y=0
 * @property {number} zoom=1
 * @property {number} mediaAspectRatio=1
 * @ignore
 */

const latestDataVer = 1;

/**
 * @extends SceneView
 */
export default class SceneFlatView extends SceneView {
	static _targetType = sceneViewTypes.flat;

	/**
	 * @param {SceneFlatViewData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {SceneFlatViewData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._sceneFlatViewDataVer = inputData._sceneFlatViewDataVer || latestDataVer;

		if (this._sceneFlatViewDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._sceneFlatViewDataVer}, should be ${latestDataVer}`); }

		this.x = parseFloat(inputData.x) || 0;
		this.y = parseFloat(inputData.y) || 0;
		this.zoom = parseFloat(inputData.zoom) || 1;
		this.mediaAspectRatio = parseFloat(inputData.mediaAspectRatio) || 1;
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_sceneFlatViewDataVer: this._sceneFlatViewDataVer,
			x: +this.x.toFixed(5),
			y: +this.y.toFixed(5),
			zoom: +this.zoom.toFixed(5),
			mediaAspectRatio: this.mediaAspectRatio,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_sceneFlatViewDataVer: this._sceneFlatViewDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			x: this.x,
			y: this.y,
			zoom: this.zoom,
			mediaAspectRatio: this.mediaAspectRatio,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	/**
	 * A helper function to allow easy creation of a new object of this class (abstracting away the data versioning and typing needed for the factory function)
	 * @returns {SceneFlatView}
	 */
	static #newFromInputs({ x, y, zoom, mediaAspectRatio }) {
		return new SceneFlatView({
			x,
			y,
			zoom,
			mediaAspectRatio
		});
	}

	/**
	 * @param {Vector} vector 
	 */
	static fromVector(vector) {
		return this.#newFromInputs({ x: vector.x, y: vector.y });
	}

	static fromXY({ x, y } = {}) {
		return this.#newFromInputs({ x, y, });
	}

	toXY() {
		return {
			x: this.x,
			y: this.y,
		};
	}

	toViewerParams() {
		return {
			x: this.x,
			y: this.y,
			zoom: this.zoom,
			// don't change the media aspect ratio
		};
	}

	round({ decimals }) {
		return SceneFlatView.#newFromInputs({
			x: +this.x.toFixed(decimals),
			y: +this.y.toFixed(decimals),
			zoom: +this.zoom.toFixed(decimals),
			mediaAspectRatio: +this.zoom.toFixed(decimals),
		});
	}

	add(view) {
		return SceneFlatView.#newFromInputs({
			x: this.x + view.x,
			y: this.y + view.y,
			zoom: this.zoom + (view.zoom || 0),
			mediaAspectRatio: this.mediaAspectRatio + (view.mediaAspectRatio || 0),
		});
	}

	subtract(view) {
		return SceneFlatView.#newFromInputs({
			x: this.x - view.x,
			y: this.y - view.y,
			zoom: this.zoom - (view.zoom || 0),
			mediaAspectRatio: this.mediaAspectRatio - (view.mediaAspectRatio || 0),
		});
	}

	modIntoRange({ min = 0, max = 1 } = {}) {
		return SceneFlatView.#newFromInputs({
			x: modValIntoRange(this.x, min, max),
			y: modValIntoRange(this.y, min, max),
			zoom: this.zoom, // keep zoom 
			mediaAspectRatio: this.mediaAspectRatio, // keep MAS
		});
	}

	isDefault() { return super.isDefault() && (this.x === 0 && this.y === 0 && this.zoom === 1 && this.mediaAspectRatio === 1); }
}