import Vector from '../../lib/Vector';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import SceneLocation from '../scene-locations/SceneLocation';
import SceneView from '../scene-views/SceneView';
import Scene from '../scenes/Scene';
import { hotspotTypes } from './hotspotTypes';

/**
 * @typedef {Object} HotspotData
 * @property {number} _hotspotDataVer
 * @property {hotspotTypes} type
 * @property {number} rotation=0
 * @property {string} target
 * @property {(string|SceneView)} lookat='current'
 * @property {SceneLocation} location
 * @property {Vector} [worldLocation]
 */

const latestDataVer = 1;

export default class Hotspot {
	static _targetType = 'base';

	/** @type {Scene} */
	#tourScene;

	/**
	 * @param {HotspotData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {HotspotData} inputData 
	 */
	_reInit(inputData) {
		this._hotspotDataVer = inputData._hotspotDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._hotspotDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._hotspotDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.rotation = parseFloat(inputData.rotation) || 0;
		this.target = inputData.target; // sceneId this hotspot will move to
		this.lookat = inputData.lookat || 'current';
		if (!inputData.location) throw new Error(`Trying to create hotspot without location data`);
		this.setCoordsFromLocation(inputData.location);
		this.worldLocation = inputData.worldLocation && new Vector(inputData.worldLocation);

		cleanUndefinedKeysRecurse(this);
	}

	serialize() {
		const ser = {
			_hotspotDataVer: this._hotspotDataVer,
			type: this.type,
			rotation: this.rotation,
			target: this.target,
			lookat: this.lookat,
			location: this.location.serialize(),
			worldLocation: this.worldLocation?.serialize(),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_hotspotDataVer: this._hotspotDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			rotation: this.rotation,
			target: this.target,
			lookat: this.lookat,
			location: this.location.serialize(),
			worldLocation: this.worldLocation?.serialize(),
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	set scene(tourScene) { this.#tourScene = tourScene; }
	get scene() { return this.#tourScene; }
	get targetScene() {
		return this.#tourScene.deliverable.getScene(this.target);
	}

	/**
	 * TODO no longer necessary since we have the location saved directly
	 * @returns {SceneLocation}
	 */
	getCoordsAsLocation() { return this.location; }

	/**
	 * @param {SceneLocation} location 
	 */
	// eslint-disable-next-line no-unused-vars
	setCoordsFromLocation(location) { }
}