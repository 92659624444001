
import $ from 'jquery';
import URI from 'urijs';
import { getAuthToken, handleApiAuthError, signOutToHome } from '../users/auth';
import { removeLeadingForwardSlashes } from './util';

let baseApiPath = window.location.hostname === 'localhost' ? process.env.ABVR_API_PATH_LOCAL : process.env.ABVR_API_PATH_ONLINE; // default to local if we're running local (serverless offline needs to be running), online if running online. can force by uncommenting below
// baseApiPath = process.env.ABVR_API_PATH_ONLINE;
// baseApiPath = process.env.ABVR_API_PATH_LOCAL;

export async function sendApiRequest({
	url,
	type = 'GET',
	contentType,
	data,
	headersObj,
	responseHeadersObj = {},
	absoluteUrl,
	auth = true,
	queryParams = {},
	dataType,
	responseType,
	processData = true,
	logError = true,
}) {
	if (type === 'GET' || type === 'DELETE') {
		// Content type must be specified here. We don't want to include a content type otherwise it'll force a preflight request
	} else {
		contentType ||= 'application/json';
	}

	const urlObj = new URI(absoluteUrl || `${baseApiPath}/${removeLeadingForwardSlashes(url)}`);
	urlObj.setQuery(queryParams);

	if (contentType === 'application/x-www-form-urlencoded') {
		data = new URI().setQuery(data).query();
	} else {
		data = JSON.stringify(data);
	}

	const dataSize = new Blob([data]).size;
	if (dataSize > 6291456 - 5 * 2 ** 10) { // Should be 6MB, but lets leave a 5KB buffer
		throw new Error(`Cannot send API request over 6MB in size. Current request size: ${dataSize}`);
	}

	// https://api.jquery.com/jQuery.ajax/#jQuery-ajax-settings
	const reqData = {
		url: urlObj.toString(),
		method: type,
		contentType, // data type we're sending to the server
		data: data,
		headers: headersObj,
		dataType, // data type we're expecting to get back from the server 
		xhrFields: {
			responseType, //https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
		},
		processData, // if false, will pass data directly and not transform it into a query string

	};
	auth && await addAuthHeaders(reqData);

	let { rawData, resHeadersObj } = await sendAjaxReq(reqData, { logError });
	Object.keys(resHeadersObj).forEach(key => {
		responseHeadersObj[key] = resHeadersObj[key];
	});

	return rawData;
}

export async function postFormData({
	url,
	formData,
	headersObj,
	absoluteUrl,
	auth = true,
	logError = true,
}) {
	const reqData = {
		url: absoluteUrl || `${baseApiPath}/${removeLeadingForwardSlashes(url)}`,
		type: 'POST',
		contentType: false,
		processData: false,
		data: formData,
		headers: headersObj,
	};
	auth && await addAuthHeaders(reqData);

	const { rawData } = await sendAjaxReq(reqData, { logError });
	return rawData;
}

async function sendAjaxReq(ajaxReq, { logError = true } = {}) {
	try {
		const ajaxPromise = $.ajax(ajaxReq);
		const rawData = await ajaxPromise;

		// if (ajaxPromise.getResponseHeader('content-type') === 'application/zip') { } // get headers like this

		const resHeadersObj = {};
		const allResHeaders = ajaxPromise.getAllResponseHeaders().split(/:.*\r\n/ig); // headers come in a big string with linebreaks. find just they header names, before the :
		allResHeaders.forEach(header => {
			resHeadersObj[header] = ajaxPromise.getResponseHeader(header);
		});

		return { rawData, resHeadersObj };
	} catch (error) {
		logError && console.error('Root error:', error);
		const formattedErr = new Error(error.responseJSON?.error?.message || error.responseJSON?.message || error.responseText || error.statusText);

		if (error.responseJSON) {
			for (const key in error.responseJSON) {
				formattedErr[key] = error.responseJSON[key];
			}

			// We get these kinds of errors when coming from our backend {error: {message: '', code: ''}, event: {}, message: '', taskName: ''}
			if (error.responseJSON.error) {
				for (const key in error.responseJSON.error) {
					formattedErr[key] = error.responseJSON.error[key];
				}
			}
		}

		formattedErr.status = error.status;

		if (formattedErr.code) {
			await handleApiAuthError(formattedErr);
		}

		throw formattedErr;
	}
}

async function addAuthHeaders(ajaxSettingsObj = {}) {
	// TODO There's a small chance that even if we have a valid token, it wont be valid when processed at the api endpoint. Maybe add a check to see when it expires and force a refresh?
	try {
		const authToken = await getAuthToken();
		if (!authToken) {
			// means we're not logged in. If it throws instead, that means the refresh token is no longer valid so handle that below.
			return;
		}

		if (ajaxSettingsObj.headers) {
			ajaxSettingsObj.headers.Authorization = authToken;
		} else {
			ajaxSettingsObj.headers = {
				Authorization: authToken,
			};
		}
	} catch (e) {
		console.error('No auth token, cannot add it to request. Forcing a logout...', e.message || e);

		window.localStorage.setItem('badTokenSignOutKey', window.location.pathname + window.location.search);
		await signOutToHome();
	}
}