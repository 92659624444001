/** @module */

/**
 * @enum {string}
 * @includevals
 */
export const deliverableTypes = {
    publicTour: 'publicTour',
    panoRev: 'panoRev',
    renderRev: 'renderRev',
    floorplan3DRev: 'floorplan3DRev',
};