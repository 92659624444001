import { cleanUndefinedKeysRecurse, shortUuid } from '../../lib/util';
import Project from '../projects/Project';
import User from '../../users/User';
import DeletionData from '../../lib/DeletionData';
import Vector from '../../lib/Vector';
import SceneLocation from '../scene-locations/SceneLocation';
import PanoRev from '../deliverables/PanoRev';
import { sceneLocationTypes } from '../scene-locations/sceneLocationTypes';
import { commentTypes } from './commentTypes';
import ScenePanoLocation from '../scene-locations/ScenePanoLocation';
import SceneFlatLocation from '../scene-locations/SceneFlatLocation';

/**
 * @typedef {Object} CommentData
 * @property {number} _commentDataVer
 * @property {commentTypes} type
 * @property {string} deliverableType
 * @property {string} uuid
 * @property {string} companyId
 * @property {string} projectId
 * @property {string} sceneId
 * @property {string} renderId
 * @property {string} authorId
 * @property {string} authorName
 * @property {string} commentType
 * @property {Date} cDate
 * @property {string} cRev
 * @property {Date} cRevDate
 * @property {string} [replyTo]
 * @property {string} text
 * @property {string} state
 * @property {string} [rRev]
 * @property {Date} [rRevDate]
 * @property {Date} [rDate]
 * @property {string} [rUserId]
 * @property {string} [rUserName]
 * @property {string} isLocalOnly=false
 * @property {DeletionData} deletionData
 * @property {SceneLocation} [location]
 * @property {Vector} [worldLocation]
 */

const latestDataVer = 2;

export default class Comment {
	static _targetType = commentTypes.comment;

	/** @type {Project} */
	#project;
	#isLocalOnly;

	/**
	 * @param {CommentData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {CommentData} inputData 
	 */
	_reInit(inputData) {
		this._commentDataVer = inputData._commentDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._commentDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._commentDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.deliverableType = inputData.deliverableType;
		this.uuid = inputData.uuid || `local-${shortUuid.new()}`;
		this.companyId = inputData.companyId;
		this.projectId = inputData.projectId;
		this.cRev = inputData.cRev;
		this.cRevDate = inputData.cRevDate ? new Date(inputData.cRevDate) : undefined;
		this.sceneId = inputData.sceneId;
		this.renderId = inputData.renderId;
		this.authorId = inputData.authorId;
		this.authorName = inputData.authorName;
		this.cDate = inputData.cDate ? new Date(inputData.cDate) : new Date(); // make sure creationDate isn't undefined since new Date(undefined) which we use in constructor returns Invalid Date
		this.commentType = inputData.commentType;
		this.replyTo = inputData.replyTo;
		this.text = inputData.text;
		this.state = inputData.state || 'open';
		this.rRev = inputData.rRev;
		this.rRevDate = inputData.rRevDate ? new Date(inputData.rRevDate) : undefined;
		this.rDate = inputData.rDate ? new Date(inputData.rDate) : undefined;
		this.rUserId = inputData.rUserId;
		this.rUserName = inputData.rUserName;
		this.deletionData = new DeletionData(inputData.deletionData);
		if (inputData.commentType !== 'reply') { // we will only have a location if it's not a reply
			this.setCoordsFromLocation(inputData.location);
			this.worldLocation = inputData.worldLocation && new Vector(inputData.worldLocation);
		}

		this.isLocalOnly = inputData.isLocalOnly || false;

		cleanUndefinedKeysRecurse(this);
	}

	serialize() {
		const ser = {
			_commentDataVer: this._commentDataVer,
			type: this.type,
			deliverableType: this.deliverableType,
			uuid: this.uuid,
			companyId: this.companyId,
			projectId: this.projectId,
			sceneId: this.sceneId,
			renderId: this.renderId,
			authorId: this.authorId,
			authorName: this.authorName,
			commentType: this.commentType,
			cDate: this.cDate.toISOString(),
			cRev: this.cRev,
			cRevDate: this.cRevDate?.toISOString(),
			replyTo: this.replyTo,
			text: this.text,
			state: this.state,
			rRev: this.rRev,
			rRevDate: this.rRevDate?.toISOString(),
			rDate: this.rDate?.toISOString(),
			rUserId: this.rUserId,
			rUserName: this.rUserName,
			deletionData: this.deletionData.serialize(),
			location: this.location?.serialize(),
			worldLocation: this.worldLocation?.serialize(),

			// ignoring this.isLocalOnly
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_commentDataVer: this._commentDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			cRev: this.cRev,
			replyTo: this.replyTo,
			text: this.text,
			location: this.location?.serialize(),
			worldLocation: this.worldLocation?.serialize(),
			state: this.state,
			rRev: this.rRev,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	set project(project) { this.#project = project; }
	get project() { return this.#project; }

	set isLocalOnly(isLocal) { this.#isLocalOnly = isLocal; }
	get isLocalOnly() { return this.#isLocalOnly; }

	isReply() { return this.commentType === 'reply'; }

	isOpen() { return this.state === 'open'; }
	isResolved() { return this.state === 'closed'; }
	isInQa() { return this.state === 'qa'; }
	isNeedingMoreInfo() { return this.state === 'moreInfo'; }
	isWaitingApproval() { return this.state === 'needApproval'; }

	getStateDisplayText() {
		if (this.isOpen()) return 'Open';
		if (this.isInQa()) return 'In QA';
		if (this.isNeedingMoreInfo()) return 'Needs Info';
		if (this.isWaitingApproval()) return 'Needs Approval';
		if (this.isResolved()) return 'Resolved';

		console.error('Getting unknown state of comment:', this.state, this);
		return 'UNKNOWN';
	}

	setStateOpen() { this.state = 'open'; }

	/**
	 * @param {Object} param0 
	 * @param {PanoRev} param0.revision
	 * @param {User} param0.user
	 */
	setStateResolved({ revision, user }) {
		this.state = 'closed';
		this.rRev = revision.deliverableId;

		// This stuff is set only in the backend and will be overwritten after the data layer updates it, but we add them here so the UI can use them immediately
		this.rDate = new Date();
		this.rRevDate = revision.creationDate;
		this.rUserId = user.userId;
		this.rUserName = user.userName;
	}

	/**
	 * @param {Object} param0 
	 * @param {PanoRev} param0.revision
	 * @param {User} param0.user
	 */
	setStateQA({ revision, user }) {
		this.state = 'qa';
		this.rRev = revision.deliverableId;

		// This stuff is set only in the backend and will be overwritten after the data layer updates it, but we add them here so the UI can use them immediately
		this.rDate = new Date();
		this.rRevDate = revision.creationDate;
		this.rUserId = user.userId;
		this.rUserName = user.userName;
	}

	/**
	 * @param {Object} param0 
	 * @param {PanoRev} param0.revision
	 * @param {User} param0.user
	 */
	setStateMoreInfo({ revision, user }) {
		this.state = 'moreInfo';
		this.rRev = revision.deliverableId;

		// This stuff is set only in the backend and will be overwritten after the data layer updates it, but we add them here so the UI can use them immediately
		this.rDate = new Date();
		this.rRevDate = revision.creationDate;
		this.rUserId = user.userId;
		this.rUserName = user.userName;
	}

	/**
	 * @param {Object} param0 
	 * @param {PanoRev} param0.revision
	 * @param {User} param0.user
	 */
	setStateNeedApproval({ revision, user }) {
		this.state = 'needApproval';
		this.rRev = revision.deliverableId;

		// This stuff is set only in the backend and will be overwritten after the data layer updates it, but we add them here so the UI can use them immediately
		this.rDate = new Date();
		this.rRevDate = revision.creationDate;
		this.rUserId = user.userId;
		this.rUserName = user.userName;
	}

	/**
	 * TODO no longer necessary since we have the location saved directly
	 * @returns {SceneLocation}
	 */
	getCoordsAsLocation() { return this.location; }

	
	/**
	 * @param {SceneLocation} location 
	 */
	setCoordsFromLocation(location) {
		// force creation of a new object for safety
		switch (location.type) {
			case sceneLocationTypes.pano:
				this.location = new ScenePanoLocation(location);
				break;
			case sceneLocationTypes.flat:
				this.location = new SceneFlatLocation(location);
				break;
			default:
				throw new Error(`Cannot create SceneLocation object with type '${location.type}'`);
		}
	}
}