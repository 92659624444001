import Scene from '../scenes/Scene';
import Vector from '../../lib/Vector';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import NavMapTransform from './NavMapTransform';

/**
 * @typedef {Object} NavMapLocationData
 * @property {number} _navMapLocationDataVer
 * @property {string} type
 * @property {string} linkedSceneId
 * @property {Vector} translationOffset
 * @property {number} rotationOffset=0
 * @property {NavMapTransform} transform
 * @property {NavMapLocation[]} navLocations
 */

const latestDataVer = 1;

export default class NavMapLocation {
	static _targetType = 'base';

	/** @type {Scene} */
	#linkedScene;

	/**
	 * @param {NavMapLocationData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {NavMapLocationData} inputData 
	 */
	_reInit(inputData) {
		this._navMapLocationDataVer = inputData._navMapLocationDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._navMapLocationDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._navMapLocationDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }
		
		this.linkedSceneId = inputData.linkedSceneId;
		this.translationOffset = new Vector(inputData.translationOffset || { x: 0, y: 0 });
		this.rotationOffset = inputData.rotationOffset || 0;
	}

	serialize() {
		const ser = {
			_navMapLocationDataVer: this._navMapLocationDataVer,
			linkedSceneId: this.linkedSceneId,
			translationOffset: this.translationOffset.serialize(),
			rotationOffset: this.rotationOffset,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_navMapLocationDataVer: this._navMapLocationDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			linkedSceneId: this.linkedSceneId,
			translationOffset: this.translationOffset.serialize(),
			rotationOffset: this.rotationOffset,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	set linkedScene(tourScene) { this.#linkedScene = tourScene; }
	get linkedScene() { return this.#linkedScene; }

	/**
	 * Gets the real world location (x,y,z) represented by this map location
	 */
	getRealWorldLocation() {
		if (!this.linkedScene) { console.error('Cant get location without linked scene'); return; }

		const translation = this.linkedScene.getRealWorldLocation().add(this.translationOffset);
		return translation;
	}

	resetLocationOffsets() {
		this.translationOffset = new Vector();
		this.rotationOffset = 0;
	}
}