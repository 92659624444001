import SceneLocation from './SceneLocation';
import { cleanUndefinedKeysRecurse, deg, modValIntoRange, rad } from '../../lib/util';
import Vector from '../../lib/Vector';
import { sceneLocationTypes } from './sceneLocationTypes';

/**
 * @typedef {import('./SceneLocation').SceneLocationData & ChildClassData} ScenePanoLocationData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _scenePanoLocationDataVer
 * @property {number} pitch=0
 * @property {number} yaw=0
 * @ignore
 */

const latestDataVer = 1;

export default class ScenePanoLocation extends SceneLocation {
	static _targetType = sceneLocationTypes.pano;

	/**
	 * @param {ScenePanoLocationData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {ScenePanoLocationData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._scenePanoLocationDataVer = inputData._scenePanoLocationDataVer || latestDataVer;

		if (this._scenePanoLocationDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._scenePanoLocationDataVer}, should be ${latestDataVer}`); }

		this.pitch = parseFloat(inputData.pitch) || 0;
		this.yaw = parseFloat(inputData.yaw) || 0;
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_scenePanoLocationDataVer: this._scenePanoLocationDataVer,
			pitch: +this.pitch.toFixed(5),
			yaw: +this.yaw.toFixed(5),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_scenePanoLocationDataVer: this._scenePanoLocationDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			pitch: this.pitch,
			yaw: this.yaw,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	/**
	 * Returns the pitch,yaw coords of the location
	 */
	getLocationParams() { return { pitch: this.pitch, yaw: this.yaw }; }
	
	/**
	 * A helper function to allow easy creation of a new object of this class (abstracting away the data versioning and typing needed for the factory function)
	 */
	static #newFromInputs({ pitch, yaw }) {
		return new ScenePanoLocation({
			pitch,
			yaw,
		});
	}

	static fromRad({ pitch, yaw, } = {}) {
		return this.#newFromInputs({
			pitch: deg(pitch),
			yaw: deg(yaw),
		});
	}

	/**
	 * @param {Vector} vector 
	 */
	static fromVector(vector) {
		return this.#newFromInputs({ pitch: vector.y, yaw: vector.z });
	}

	toRad() {
		return {
			pitch: rad(this.pitch),
			yaw: rad(this.yaw),
		};
	}

	round({ decimals }) {
		return {
			pitch: +this.pitch.toFixed(decimals),
			yaw: +this.yaw.toFixed(decimals),
		};
	}

	add(location) {
		return ScenePanoLocation.#newFromInputs({ 
			pitch: this.pitch + location.pitch,
			yaw: this.yaw + location.yaw,
		});
	}

	subtract(location) {
		return ScenePanoLocation.#newFromInputs({ 
			pitch: this.pitch - location.pitch,
			yaw: this.yaw - location.yaw,
		});
	}

	modIntoRange({ min = -180, max = 180 } = {}) {
		return ScenePanoLocation.#newFromInputs({ 
			pitch: modValIntoRange(this.pitch, min, max),
			yaw: modValIntoRange(this.yaw, min, max),
		});
	}

	isDefault() { return super.isDefault() && (this.pitch === 0 && this.yaw === 0); }

	equals(otherLocation) { return super.equals(otherLocation) && (this.pitch === otherLocation.pitch && this.yaw === otherLocation.yaw); }
}