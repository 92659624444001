import { sendApiRequest } from '../lib/opalConnector';

/** @module */

export async function createDeliverable(companyId, projectId, inputData) {
	const { deliverable } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/deliverables`, type: 'POST', data: inputData });
	return deliverable;
}

export async function getAllDeliverables(companyId, projectId, { includeViewerData = false, includeCompanyData = false, includeProjectData = false, includeDeleted = false, type } = {}) {
	let { deliverables, company, project, lastEvaluatedKey: exclusiveStartKey } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/deliverables`, queryParams: { includeViewerData, includeCompanyData, includeProjectData, includeDeleted, type } });

	while (exclusiveStartKey) {
		const paginatedResponse = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/deliverables`, queryParams: { includeViewerData, includeDeleted, type, exclusiveStartKey } });
		deliverables = deliverables.concat(paginatedResponse.deliverables);
		exclusiveStartKey = paginatedResponse.lastEvaluatedKey;
	}

	return {
		/** @type {Array} */
		deliverables,
		/** @type {Object} */
		company,
		/** @type {Object} */
		project
	};
}

export async function getDeliverable(companyId, projectId, deliverableId, { includeViewerData = false, includeCompanyData = false, includeProjectData = false, includeDeleted = false } = {}) {
	const { deliverable, company, project } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/deliverables/${deliverableId}`, queryParams: { includeViewerData, includeCompanyData, includeProjectData, includeDeleted } });
	return {
		/** @type {Object} */
		deliverable,
		/** @type {Object} */
		company,
		/** @type {Object} */
		project
	};
}

export async function updateDeliverable(companyId, projectId, deliverableId, updatedData, { isCurrentlyDeleted } = {}) {
	// Since we may need to update a tour rev before un-deleteing it, we need to tell it if it's currently deleted otherwise it comes back with a 404.
	const { deliverable } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/deliverables/${deliverableId}`, type: 'PUT', data: updatedData, queryParams: { undelete: isCurrentlyDeleted } });
	/** @type {Object} */
	return deliverable;
}

export async function deleteDeliverable(companyId, projectId, deliverableId, { hardDelete = false } = {}) {
	const { deliverable } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/deliverables/${deliverableId}`, type: 'DELETE', queryParams: { hardDelete } });
	/** @type {Object} */
	return deliverable;
}

export async function undeleteDeliverable(companyId, projectId, deliverableId) {
	const { deliverable } = await sendApiRequest({ url: `/companies/${companyId}/projects/${projectId}/deliverables/${deliverableId}`, type: 'PUT', queryParams: { undelete: true } });
	return deliverable;
}