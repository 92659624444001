import { cleanUndefinedKeysRecurse } from './util';

export default class DeletionData {
	constructor({
		isDeleted = false,
		reason = 'default',
		userId,
		time,
	} = {}) {
		this.isDeleted = isDeleted;
		this.reason = reason;
		this.userId = userId;
		this.time = time ? new Date(time) : undefined;
	}

	serialize() {
		const ser = {
			isDeleted: this.isDeleted,
			reason: this.reason,
			userId: this.userId,
			time: this.time?.toISOString(), // will save undefined if it was never set
		};

		return cleanUndefinedKeysRecurse(ser);
	}
}