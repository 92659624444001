import { cleanUndefinedKeysRecurse, dataUrlMatcher } from '../../lib/util';
import DeletionData from '../../lib/DeletionData';
import Project from '../projects/Project';

/**
 * @typedef {Object} CompanyData
 * @property {number} _companyDataVer
 * @property {string} type
 * @property {string} companyName
 * @property {string} companyId
 * @property {string} dataLocation
 * @property {string} logoFile
 * @property {Project[]} [projects]
 * @property {DeletionData} [deletionData]
 */

const latestDataVer = 1;

export default class Company {
	static _targetType = 'base';

	/** @type {Project[]} */
	#projects = [];

	/**
	 * @param {CompanyData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {CompanyData} inputData 
	 */
	_reInit(inputData) {
		this._companyDataVer = inputData._companyDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._companyDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._companyDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.companyName = inputData.companyName;
		this.companyId = inputData.companyId;
		this.dataLocation = inputData.dataLocation;
		this.logoFile = inputData.logoFile;
		this.deletionData = new DeletionData(inputData.deletionData);

		inputData.projects?.forEach(proj => this._addProject(proj));
	}

	serialize() {
		const ser = {
			_companyDataVer: this._companyDataVer,
			companyName: this.companyName,
			companyId: this.companyId,
			dataLocation: this.dataLocation,
			logoFile: this.logoFile,
			projects: this.projects.map(project => project.serialize()),
			deletionData: this.deletionData.serialize(),
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_companyDataVer: this._companyDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			companyName: this.companyName,
			logoFile: this.logoFile,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	get projects() { return this.#projects; }

	/**
	 * @param {import('../projects/Project').ProjectData} inputObj 
	 * @returns {Project}
	 */
	_addProject(inputObj) {
		if (inputObj.companyId !== this.companyId) throw new Error(`Cannot add project from different company: ${inputObj.companyId} vs ${this.companyId}`);

		const newProject = new Project(inputObj);
		const existingProject = this.getProject(newProject.projectId);

		if (existingProject) {
			existingProject._reInit(inputObj);
			existingProject.company = this;
			return existingProject;
		} else {
			newProject.company = this;
			this.projects.push(newProject);
			return newProject;
		}
	}

	getProject(projectId) { return this.projects.find(proj => proj.projectId === projectId); }

	_removeProject(projectId) {
		const projectIndex = this.projects.findIndex(proj => proj.projectId === projectId);
		if (projectIndex !== -1) { this.projects.splice(projectIndex, 1); }
	}

	getLogoUrl() {
		if (dataUrlMatcher.test(this.logoFile)) { // we may have a dataurl if we haven't uploaded the file yet
			return this.logoFile;
		} else {
			return `${this.absoluteDataUrl}/user-content/${this.logoFile}`;
		}
	}
	removeLogoFile() { this.logoFile = null; } // need to set this to empty otherwise it won't be included in the updates we should make


	get relativeDataUrl() {
		return this.dataLocation.replaceAll('\\', '/'); // ensure forward slashes
	}

	get absoluteDataUrl() {
		return `${process.env.ABVR_COGNITO_SIGNIN}${this.relativeDataUrl}`; // ABVR_COGNITO_SIGNIN already has a trailing slash
	}

	isDeleted() { return this.deletionData.isDeleted; }
}