import { cleanUndefinedKeysRecurse, dataUrlMatcher } from '../../lib/util';
import Comment from '../comments/Comment';
import DeletionData from '../../lib/DeletionData';
import CommentsFilter from '../../lib/CommentsFilter';
import Company from '../companies/Company';
import _deliverableFactory from '../deliverables/deliverableFactory';
import Deliverable from '../deliverables/Deliverable';
import { commentTypes } from '../comments/commentTypes';
import VirtualTourRev from '../deliverables/VirtualTourRev';
import Scene from '../scenes/Scene';
import { deliverableTypes } from '../deliverables/deliverableTypes';
import PanoRev from '../deliverables/PanoRev';
import FlatRev from '../deliverables/FlatRev';
import Floorplan3DRev from '../deliverables/Floorplan3DRev';
import RenderRev from '../deliverables/RenderRev';

/**
 * @typedef {Object} ProjectData
 * @property {number} _projectDataVer
 * @property {string} type
 * @property {string} companyId
 * @property {string} projectName
 * @property {string} projectId
 * @property {string} dataLocation
 * @property {string} logoFile
 * @property {Deliverable[]} [deliverables]
 * @property {Comment[]} [comments]
 * @property {DeletionData} [deletionData]
 * @property {boolean} [isArchived=false]
 */

const latestDataVer = 1;

export default class Project {
	static _targetType = 'base';

	/** @type {Company} */
	#company;
	/** @type {Comment[]} */
	#comments = [];
	/** @type {Deliverable[]} */
	#deliverables = [];

	/**
	 * @param {ProjectData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {ProjectData} inputData 
	 */
	_reInit(inputData) {
		this._projectDataVer = inputData._projectDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._projectDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._projectDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.companyId = inputData.companyId;
		this.projectName = inputData.projectName;
		this.projectId = inputData.projectId;
		this.dataLocation = inputData.dataLocation;
		this.logoFile = inputData.logoFile;
		inputData.deliverables?.forEach(deliverable => this._addDeliverable(deliverable));
		inputData.comments?.forEach(comment => this._addComment(comment));
		this.deletionData = new DeletionData(inputData.deletionData);
		this.isArchived = inputData.isArchived;
	}

	serialize() {
		const ser = {
			_projectDataVer: this._projectDataVer,
			companyId: this.companyId,
			projectName: this.projectName,
			projectId: this.projectId,
			dataLocation: this.dataLocation,
			logoFile: this.logoFile,
			deliverables: this.deliverables.map(deliverable => deliverable.serialize()),
			comments: this.comments.map(comment => comment.serialize()),
			deletionData: this.deletionData.serialize(),
			isArchived: this.isArchived,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_projectDataVer: this._projectDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			projectName: this.projectName,
			isArchived: this.isArchived,
			logoFile: this.logoFile,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	get deliverables() { return this.#deliverables; }
	getDeliverablesByType(type) { return this.#deliverables.filter(del => del.type === type); }

	/** @returns {VirtualTourRev[]} */
	get virtualTourRevs() { return this.panoRevs.concat(this.flatRevs); }
	/** @returns {FlatRev[]} */
	get flatRevs() { return this.renderRevs.concat(this.floorplan3DRevs); }
	/** @returns {PanoRev[]} */
	get panoRevs() { return this.getDeliverablesByType(deliverableTypes.panoRev); }

	/** @returns {RenderRev[]} */
	get renderRevs() { return this.getDeliverablesByType(deliverableTypes.renderRev); }
	get publicTours() { return this.getDeliverablesByType(deliverableTypes.publicTour); }
	/** @returns {Floorplan3DRev[]} */
	get floorplan3DRevs() { return this.getDeliverablesByType(deliverableTypes.floorplan3DRev); }

	get comments() { return this.#comments; }

	set company(company) { this.#company = company; }
	get company() { return this.#company; }

	_addDeliverable(inputObj) {
		if (inputObj.companyId !== this.companyId) throw new Error(`Cannot add deliverable from different company: ${inputObj.companyId} vs ${this.companyId}`);
		if (inputObj.projectId !== this.projectId) throw new Error(`Cannot add deliverable from different project: ${inputObj.projectId} vs ${this.projectId}`);

		const newDeliverable = _deliverableFactory(inputObj);
		const existingDeliverable = this.getDeliverable(newDeliverable.deliverableId);

		if (existingDeliverable) {
			existingDeliverable._reInit(newDeliverable.serialize());
			existingDeliverable.project = this;
		} else {
			newDeliverable.project = this;
			this.#deliverables.push(newDeliverable);
		}

		this.#deliverables.sort((first, second) => { // sort the deliverables newest to oldest
			return second.creationDate - first.creationDate;
		});

		return existingDeliverable || newDeliverable;
	}
	getDeliverable(deliverableId) { return this.#deliverables.find(del => del.deliverableId === deliverableId); }
	_removeDeliverable(deliverableId) {
		const delIndex = this.#deliverables.findIndex(del => del.deliverableId === deliverableId);
		if (delIndex !== -1) { this.deliverables.splice(delIndex, 1); }
	}

	/**
	 * @param {Date} date 
	 */
	getFirstDeliverableAfter(date, { sceneId, type } = {}) {
		let targetDels = this.deliverables;
		if (sceneId) { targetDels = this.getDeliverablesForScene(sceneId); }
		if (type) { targetDels = targetDels.filter(del => del.type === type); }

		const dates = targetDels.map(del => del.creationDate);
		dates.sort((a, b) => a - b); // sort oldest to newest
		const firstDateAfter = dates.find(delDate => delDate >= date); // find next oldest date
		return this.deliverables.find(del => del.creationDate === firstDateAfter);
	}
	/**
	 * @param {Date} date 
	 */
	getLastDeliverableBefore(date) {
		const dates = this.deliverables.map(del => del.creationDate);
		dates.sort((a, b) => b - a); // sort newest to oldest
		const firstDateAfter = dates.find(delDate => delDate <= date); // find next youngest date
		return this.deliverables.find(del => del.creationDate === firstDateAfter);
	}
	getDeliverablesForScene(sceneId) { return this.deliverables.filter(deliverable => deliverable.getScene(sceneId)?.isPointer === false); }
	getLatestDeliverable({ sceneId } = {}) {
		if (sceneId) {
			return this.deliverables.find(deliverable => deliverable.getScene(sceneId)?.isPointer === false); // find the first revision (in sorted list) where the pointer is false
		} else {
			return this.deliverables[0]; // always sorted
		}
	}

	_addComment(inputObj) {
		if (inputObj.companyId !== this.companyId) throw new Error(`Cannot add comment from different company: ${inputObj.companyId} vs ${this.companyId}`);
		if (inputObj.projectId !== this.projectId) throw new Error(`Cannot add comment from different project: ${inputObj.projectId} vs ${this.projectId}`);

		// Previous versions didn't have an imageType set, so if it's undefined we will assume it must be a pano
		inputObj.sceneType = inputObj.sceneType || commentTypes.pano; // TODO this value is now set multiple places (backend, the class, here), need to keep things accurate
		const newComment = new Comment(inputObj);
		newComment.project = this;

		const existingComment = this.getComment(newComment.uuid);
		if (existingComment) this._removeComment(existingComment.uuid);

		this.comments.push(newComment);
		return newComment;
	}
	getComment(uuid) { return this.comments.find(com => com.uuid === uuid); }
	_removeComment(uuid) {
		const comIndex = this.comments.findIndex(com => com.uuid === uuid);
		if (comIndex !== -1) { this.comments.splice(comIndex, 1); }
	}


	/**
	 * @param {Object} param0 
	 * @param {CommentsFilter} param0.filter
	 * @param {boolean} param0.showError
	 */
	getComments({ filter, showError = false } = {}) {
		// TODO this logic isn't finished yet, will have issues in some fringe cases likely
		let showComList = Array.from(this.comments);

		if (filter.deliverableType) { showComList = showComList.filter(com => com.deliverableType === filter.deliverableType); }
		if (filter.targetDeliverable && filter.commentType != 'reply') { // don't want to filter by revision if we're looking for replies, since the replies could be after the target rev
			showComList = showComList.filter(com => {
				if (com.cRevDate > filter.targetDeliverable.creationDate) { showError && console.log('didnt exist yet'); return false; } // comment created after this rev

				/** @type {Scene} */
				const actualCommentScene = filter.targetDeliverable.getScene(com.sceneId);
				if (!actualCommentScene) { showError && console.log('scene doesnt exist in del'); return false; } // scene doesn't exist in this del, even as a pointer

				let testDelCreationDate = filter.targetDeliverable.creationDate;
				if (actualCommentScene.isPointer) testDelCreationDate = actualCommentScene.pointedSceneDeliverable.creationDate;

				if (com.isResolved() && com.rRevDate < testDelCreationDate) { showError && console.log('already resolved'); return false; } // comment resolved before this rev (unresolved with no rRevDate compared to Date will always be false, so those will stay)

				return true;
			});
		}
		if (filter.targetScene) { showComList = showComList.filter(com => com.sceneId === filter.targetScene.sceneId); }
		if (filter.states) {
			showComList = showComList.filter(com => {
				if (com.isReply()) {
					const parentComment = this.getComment(com.replyTo);
					if (!parentComment) return false; // orphaned reply, historically replies were not deleted when parents were

					return filter.states.includes(parentComment.state);
				} else {
					return filter.states.includes(com.state);
				}
			});
		}
		// TODO implement
		// if (filter.startDate) { showComList = showComList.filter(com => com.cDate > filter.startDate); }
		// if (filter.endDate) { showComList = showComList.filter(com => com.cDate < filter.endDate); }
		if (filter.authorId) { showComList = showComList.filter(com => com.authorId === filter.authorId); }
		if (filter.replyTo) { showComList = showComList.filter(com => com.replyTo === filter.replyTo); }
		if (filter.commentType) { showComList = showComList.filter(com => com.commentType === filter.commentType); }
		if (!filter.includeDeleted) { showComList = showComList.filter(com => !com.deletionData.isDeleted); }
		if (filter.includeAccessibleOnly) {
			// Only accessible if we can get to the right scene in it's deliverable or any deliverable after that date
			showComList = showComList.filter(com =>
				!!this.getDeliverable(com.cRev)?.getScene(com.sceneId) ||
				!!this.getFirstDeliverableAfter(com.cRevDate, { sceneId: com.sceneId })
			);
		}

		return showComList;
	}

	/**
	 * Finds all TourScenePointer objects in the project (or in a select tour revision if passed) and fills its metadata from the most recent 
	 * revision of the scene prior to the revision holding the pointer.
	 * @param {VirtualTourRev} tourRevObj Optional. The tour revision to fill scene pointers for
	 */
	_fillScenePointers(tourRevObj = undefined) {
		/** @type {VirtualTourRev[]} */
		const virtualTours = tourRevObj ? [tourRevObj] : this.virtualTourRevs; // either use the provided object or all our revs

		virtualTours.forEach(virtualTourObj => {
			virtualTourObj.scenes.forEach(curScene => {
				if (!curScene.isPointer) return;

				// Find the most recent version of this scene
				/** @type {VirtualTourRev} */
				const newestDeliverableWithScene = this.deliverables.find(otherRev => {
					if (otherRev.creationDate >= virtualTourObj.creationDate) return false; // has to be before, and not this rev
					const otherScene = otherRev.getScene(curScene.sceneId);
					if (!otherScene || otherScene.isPointer) return false; // scene must be in the other rev, and not a pointer

					return true;
				});

				if (!newestDeliverableWithScene) {
					console.error(`Can't fill scene pointer '${curScene.sceneId}' from previous revisions. No previous revisions exist for this scene.`);
					virtualTourObj._removeScene(curScene.sceneId); // TODO currently we're removing it, but if we're offline we might not want to do that
					return;
				}
				const newestSceneVersion = newestDeliverableWithScene.getScene(curScene.sceneId);

				const replacedScene = virtualTourObj._createScene(newestSceneVersion);
				replacedScene.isPointer = true; // make sure we keep track that this is a pointer even though we're saving it as a full SceneData object
				replacedScene.pointedSceneDeliverable = newestDeliverableWithScene;
			});

			virtualTourObj._sortScenesByName();
		});

		// AFTER we've filled the scenes, we need to fill any nav map locations that linked to a scene pointer before it was filled (we created a new object)
		virtualTours.forEach(virtualTour => {
			virtualTour.navMaps.forEach(navMap => {
				navMap.navLocations.forEach(loc => {
					if (!loc.linkedScene || loc.linkedScene.isPointer) {
						loc.linkedScene = virtualTour.getScene(loc.linkedSceneId);
					}
				});
			});
		});

		// TODO testing to catch rotations
		// let doBreak;
		// this.deliverables.forEach(rev => {
		// 	rev.scenes.forEach(scene => {
		// 		if (scene.isPointer) return;
		// 		if (doBreak) return;

		// 		const hasCameraRot = Math.abs(scene.cameraTransform.rotation.z) > 1;
		// 		const hasManualRot = Math.abs(scene.manualOffsetTransform.rotation.z) > 1;
		// 		if (hasCameraRot && hasManualRot) {
		// 			console.log(`CAMERA & MANUAL XFORM - ${scene.deliverable.deliverableName} - ${scene.sceneId}`, scene);
		// 		} else if (hasCameraRot) {
		// 			console.log(`CAMERA XFORM - ${scene.deliverable.deliverableName} - ${scene.sceneId}`, scene);
		// 		} else if (hasManualRot) {
		// 			console.log(`MANUAL XFORM - ${scene.deliverable.deliverableName} - ${scene.sceneId}`, scene);
		// 		}

		// 		if (hasCameraRot || hasManualRot){
		// 			// doBreak = true;
		// 		}
		// 	});
		// });
	}

	getLogoUrl() {
		if (dataUrlMatcher.test(this.logoFile)) { // we may have a dataurl if we haven't uploaded the file yet
			return this.logoFile;
		} else {
			return `${this.absoluteDataUrl}/user-content/${this.logoFile}`;
		}
	}
	removeLogoFile() { this.logoFile = null; } // need to set this to empty otherwise it won't be included in the updates we should make


	get relativeDataUrl() {
		return this.dataLocation.replaceAll('\\', '/'); // ensure forward slashes
	}

	get absoluteDataUrl() {
		return `${process.env.ABVR_COGNITO_SIGNIN}${this.relativeDataUrl}`; // ABVR_COGNITO_SIGNIN already has a trailing slash
	}

	isDeleted() { return this.deletionData.isDeleted; }
}

window.Project = Project;