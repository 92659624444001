import { cleanUndefinedKeysRecurse } from '../../lib/util';
import SceneFlat from '../scenes/SceneFlat';
import VirtualTourRev from './VirtualTourRev';

/**
 * @typedef {import('./VirtualTourRev').VirtualTourRevData & ChildClassData} FlatRevData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _flatRevDataVer
 * @property {string} extDownloadUrl
 * @ignore
 */

const latestDataVer = 1;

export default class FlatRev extends VirtualTourRev {
    static _targetType = 'flatRev';

    /**
     * @param {FlatRevData} inputData 
     */
    constructor(inputData) {
        super(inputData);
    }

    /**
     * @param {FlatRevData} inputData 
     */
    _reInit(inputData) {
        super._reInit(inputData);
        this._flatRevDataVer = inputData._flatRevDataVer || latestDataVer;

        if (this._flatRevDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._flatRevDataVer}, should be ${latestDataVer}`); }

        this.extDownloadUrl = inputData.extDownloadUrl;
    }

    serialize({ includeTourData = this.isViewerDataIncluded } = {}) {
        const ser = {
            ...super.serialize({ includeTourData }),
            _flatRevDataVer: this._flatRevDataVer,
            extDownloadUrl: this.extDownloadUrl,
        };

        return cleanUndefinedKeysRecurse(ser);
    }

    getUpdatableData({ includeTourData = this.isViewerDataIncluded } = {}) {
        // we can only request to directly update these
        const updates = {
            ...super.getUpdatableData({ includeTourData }),
            _flatRevDataVer: this._flatRevDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
            extDownloadUrl: this.extDownloadUrl,
        };

        return cleanUndefinedKeysRecurse(updates);
    }

    _createScene(inputObj) {
        const newScene = new SceneFlat(inputObj);

        // TODO this stuff is duplicated in the other revision class
        newScene.deliverable = this;

        if (this.getScene(newScene.sceneId)) { this._removeScene(newScene.sceneId); } // remove the original scene wholesale. This is the lowest level full object we have

        this.scenes.push(newScene);
        this.scenes.sort((first, second) => first.sceneId.localeCompare(second.sceneId));
        return newScene;
    }

    // Test function so we can easily publish a tour
    _setAllStartViewsAsGood() {
        this.scenes.forEach(scene => {
            const startView = scene.startView;
            if (startView.isDefault()) {
                startView.x += 0.001; // minor change to make sure it's no longer default
                scene.setStartView(startView);
            }
        });
    }
}