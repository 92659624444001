import { cleanUndefinedKeysRecurse } from '../../lib/util';
import ScenePano from '../scenes/ScenePano';
import { deliverableTypes } from './deliverableTypes';
import VirtualTourRev from './VirtualTourRev';

/**
 * @typedef {import('./VirtualTourRev').VirtualTourRevData & ChildClassData} PanoRevData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _panoRevDataVer
 * @ignore
 */

const latestDataVer = 1;

export default class PanoRev extends VirtualTourRev {
    static _targetType = deliverableTypes.panoRev;

    /**
     * @param {PanoRevData} inputData 
     */
    constructor(inputData) {
        super(inputData);
    }

    /**
     * @param {PanoRevData} inputData 
     */
    _reInit(inputData) {
        super._reInit(inputData);
        this._panoRevDataVer = inputData._panoRevDataVer || latestDataVer;

        if (this._panoRevDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._panoRevDataVer}, should be ${latestDataVer}`); }
    }

    serialize({ includeTourData = this.isViewerDataIncluded } = {}) {
        const ser = {
            ...super.serialize({ includeTourData }),
            _panoRevDataVer: this._panoRevDataVer,
        };

        return cleanUndefinedKeysRecurse(ser);
    }

    getUpdatableData({ includeTourData = this.isViewerDataIncluded } = {}) {
        // we can only request to directly update these
        const updates = {
            ...super.getUpdatableData({ includeTourData }),
            _panoRevDataVer: this._panoRevDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
        };

        return cleanUndefinedKeysRecurse(updates);
    }

    _createScene(inputObj) {
        const newScene = new ScenePano(inputObj);

        // TODO this stuff is duplicated in the other revision class
        newScene.deliverable = this;

        if (this.getScene(newScene.sceneId)) { this._removeScene(newScene.sceneId); } // remove the original scene wholesale. This is the lowest level full object we have

        this.scenes.push(newScene);
        this._sortScenesByName();
        return newScene;
    }

    // Test function so we can easily publish a tour
    _setAllStartViewsAsGood() {
        this.scenes.forEach(scene => {
            const startView = scene.startView;
            if (startView.isDefault()) {
                startView.pitch += 0.01; // minor change to make sure it's no longer default
                scene.setStartView(startView);
            }
        });
    }
}