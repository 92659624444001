import { cleanUndefinedKeysRecurse, makeUnique } from '../../lib/util';
import CameraTransform from '../../lib/CameraTransform';
import SceneLevel from '../scene-levels/SceneLevel';
import Rendering from '../renderings/Rendering';
import RenderOption from '../renderings/RenderOption';
import Hotspot from '../hotspots/Hotspot';
import RenderCategory from '../renderings/RenderCategory';
import SceneView from '../scene-views/SceneView';
import { sceneTypes } from './sceneTypes';
import Deliverable from '../deliverables/Deliverable';

/**
 * @typedef {Object} SceneData
 * @property {number} _sceneDataVer
 * @property {sceneTypes} type
 * @property {string} sceneId
 * @property {string} title
 * @property {string} area
 * @property {SceneLevel[]} levels
 * @property {Hotspot[]} hotspots
 * @property {Rendering[]} renderings
 * @property {SceneView} startView
 * @property {CameraTransform} cameraTransform
 * @property {CameraTransform} manualOffsetTransform
 * @property {string} [navMapId]
 * @property {boolean} [isPointer=false]
 */

const latestDataVer = 2;

/**
 * Contains data to represent a single scene in a tour revision. All data to properly render the scene should be held in this class. Many scenes to one tour revision, 
 * and there may be many tour revisions that include the same scene ID, but not the same scene object. A scene may have multiple renderings associated with it to be 
 * shown for different viewing options (ex. finish schemes, lighting scenarios). A scene may have multiple features associated with it (ex. hotspots, comments, drawings).
 */
export default class Scene {
	static _targetType = 'base';
	/** 
	 * The actual deliverable this scene object is included in
	 * @type {Deliverable} 
	 */
	#deliverable;
	/** 
	 * If this scene is actually a pointer to the same scene in a previous revision, this will hold that previous revision 
	 * where the actual scene object can be found (undefined otherwise)
	 * @type {Deliverable} 
	 */
	#pointedSceneDeliverable;

	/**
	 * @param {SceneData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {SceneData} inputData 
	 */
	_reInit(inputData) {
		this._sceneDataVer = inputData._sceneDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._sceneDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._sceneDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

		this.sceneId = inputData.sceneId;
		this.title = inputData.title;
		this.area = inputData.area;
		// TODO this doesn't really reinit anything since we start with a blank array. We really need to keep references to any obj and remove ones that aren't in hte new input data
		/** @type {SceneLevel[]} */
		this.levels = []; inputData.levels?.forEach(level => this._createLevel(level));
		/** @type {Hotspot[]} */
		this.hotspots = []; inputData.hotspots?.forEach(hotspot => this._createHotspot(hotspot));
		/** @type {Rendering[]} */
		this.renderings = []; inputData.renderings?.forEach(rendering => this._createRendering(rendering));

		this.isPointer = inputData.isPointer || false;
		// These won't be included in pointer scene data, they'll be filled in later
		if (!this.isPointer) {
			/** @type {SceneView} */
			this.startView; // intellisense
			this.setStartView(inputData.startView);
			this.setCameraTransform(inputData.cameraTransform);
			this.setManualOffsetTransform(inputData.manualOffsetTransform);
		}

		this.navMapId = inputData.navMapId;

		this.renderings.sort((first, second) => first.renderId.localeCompare(second.renderId)); // sort so they're in a specific order, layers will be created properly in this case
	}

	serialize() {
		if (this.isPointer) {
			return this.toPointer();
		} else {
			const ser = {
				_sceneDataVer: this._sceneDataVer,
				sceneId: this.sceneId,
				type: this.type,
				title: this.title,
				area: this.area,
				levels: this.levels.map(level => level.serialize()),
				hotspots: this.hotspots.map(hotspot => hotspot.serialize()),
				renderings: this.renderings.map(rendering => rendering.serialize()),
				startView: this.startView.serialize(),
				cameraTransform: this.cameraTransform.serialize(),
				manualOffsetTransform: this.manualOffsetTransform.serialize(),
				navMapId: this.navMapId,
			};

			return cleanUndefinedKeysRecurse(ser);
		}
	}

	toPointer() {
		return {
			_sceneDataVer: this._sceneDataVer,
			sceneId: this.sceneId,
			type: this.type,
			isPointer: true,
		};
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_sceneDataVer: this._sceneDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			title: this.title,
			area: this.area,
			hotspots: this.hotspots.map(hotspot => hotspot.serialize()),
			startView: this.startView.serialize(),
			manualOffsetTransform: this.manualOffsetTransform.serialize(),
			navMapId: this.navMapId,
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	/**
	 * 
	 * @returns {Hotspot[]}
	 */
	getInvalidHotspots() {
		if (this.isPointer) return [];

		return this.hotspots.reduce((invalidHotspots, curHotspot) => {
			if (!this.deliverable.getScene(curHotspot.target)) {
				console.error(`Invalid hotspot in scene '${this.sceneId}', target is '${curHotspot.target}'`);
				invalidHotspots.push(curHotspot);
			}

			return invalidHotspots;
		}, []);
	}

	/**
	 * Copies appropriate data from the provided scene into this scene. Scene IDs must match.
	 * @param {Scene} masterScene 
	 */
	_copyDataFromOtherScene(masterScene) {
		if (masterScene.sceneId !== this.sceneId) throw new Error(`Cannot copy scene data from mismatched sceneId '${masterScene.sceneId}' into this scene '${this.sceneId}'`);

		// Now that we're using sceneIds that don't come from the area-title of a camera, don't copy the area or title of the camera from previous revs. That way we can update the names in UE4 and those will stick around in the next revision.
		// this.title = masterScene.title;
		// this.area = masterScene.area;

		// Only want to update metadata that doesn't affect the renderings since those may be different (levels, face size, camera xform, etc)
		// TODO easiest thing right now is to wipe all the features, maybe come back later if this isn't what we want
		this.hotspots.length = 0; masterScene.hotspots.forEach(hotspot => this._createHotspot(hotspot));
		this.setStartView(masterScene.startView);
		this.setManualOffsetTransform(masterScene.manualOffsetTransform);
		this.setNavMapId(masterScene.navMapId);
	}

	/**
	 * @param {import('../scene-levels/SceneLevel').SceneLevelData} inputObj 
	 */
	// eslint-disable-next-line no-unused-vars
	_createLevel(inputObj) { }

	/**
	 * @param {import('../hotspots/Hotspot').HotspotData} inputObj 
	 */
	// eslint-disable-next-line no-unused-vars
	_createHotspot(inputObj) { }

	/**
	 * 
	 * @param {string} sceneId 
	 * @returns {Hotspot|undefined}
	 */
	getHotspotToScene(sceneId) { return this.hotspots.find(hs => hs.target === sceneId); }

	/**
	 * @param {Hotspot} hotspot 
	 */
	removeHotspot(hotspot) { this.hotspots = this.hotspots.filter(arrayHs => arrayHs !== hotspot); }

	/**
	 * @param {import('../renderings/Rendering').RenderingData} inputObj 
	 */
	// eslint-disable-next-line no-unused-vars
	_createRendering(inputObj) { }

	getRendering(renderId) { return this.renderings.find(rendering => rendering.renderId === renderId); }

	getRenderingFromOptions(renderOptions) {
		const renderedCategories = this.getRenderedCategories();
		return this.renderings.find(rendering => {
			const truthArray = Object.keys(renderOptions).map(categoryId => {
				if (!renderedCategories.find(category => category.categoryId === categoryId)) return true; // if this scene didn't implement this render category, then this is a good rendering

				const renderedOption = rendering.getRenderedOptionForCategory(categoryId);
				return renderedOption === renderOptions[categoryId];
			});
			return truthArray.every(bool => bool);
		});
	}

	/**
	 * @param {Rendering} rendering 
	 */
	removeRendering(rendering) { this.renderings = this.renderings.filter(arrayRendering => arrayRendering !== rendering); }

	getRenderedCategories() {
		let categoryIds = this.renderings.flatMap(rendering => Object.keys(rendering.capturedOptions));
		categoryIds = makeUnique(categoryIds);
		const realDeliverable = this.pointedSceneDeliverable || this.deliverable;

		const categories = categoryIds.map(categoryId => realDeliverable.getRenderCategory(categoryId));

		// Sort categoires by name
		categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

		const output = categories.map(category => {
			const newCategory = new RenderCategory(category); // so we don't overwrite the optionList
			newCategory.optionList.length = 0;

			const options = this.getRenderedOptions(category.categoryId);

			// Sort options by name
			options.sort((a, b) => a.optionName.localeCompare(b.optionName));

			newCategory.optionList.push(...options);
			return newCategory;
		});
		return output;
	}

	/**
	 * @param {string} categoryId 
	 * @returns {RenderOption[]}
	 */
	getRenderedOptions(categoryId) {
		const options = this.renderings.map(rendering => {
			const renderedOptionId = rendering.getRenderedOptionForCategory(categoryId);
			const realDeliverable = this.pointedSceneDeliverable || this.deliverable;
			const category = realDeliverable.getRenderCategory(categoryId);
			return category.getRenderOption(renderedOptionId);
		});

		return makeUnique(options).filter((el) => el || el === 0); // this filter makes sure there are no blank options (not undefined, just holes in the array like ['test','test2',,'test3',,,'test4'])
	}

	setNavMapId(navMapId) {
		if (navMapId) {
			const map = this.deliverable.getNavMap(navMapId);
			if (!map) throw new Error(`Cannot set scene '${this.title}' to map ID '${navMapId}' - no map with that ID exists in this deliverable`);

			this.navMapId = map.mapId;
		} else {
			this.navMapId = undefined;
		}
	}

	/**
	 * Virtual function, override at child
	 * @param {import('../scene-views/SceneView').SceneViewData} inputObj 
	 * @returns {SceneView}
	 */
	// eslint-disable-next-line no-unused-vars
	setStartView(inputObj) { }

	hasValidStartView() {
		return !this.startView.isDefault();
	}

	/**
	 * @returns {CameraTransform}
	 */
	setCameraTransform(inputObj) {
		this.cameraTransform = new CameraTransform(inputObj);
		return this.cameraTransform;
	}

	/**
	 * @returns {CameraTransform}
	 */
	setManualOffsetTransform(inputObj) {
		this.manualOffsetTransform = new CameraTransform(inputObj);
		return this.manualOffsetTransform;
	}

	/**
	 * Gets the vector representing the manual offset that should be applied to this scene. This should be used when physical scene geometry gets rotated in engine
	 * (ex. units moved and rotated in engine) and needs to match how it was previously rendered (so hotspots show properly etc).
	 */
	getRealWorldRotationOffset() { return this.manualOffsetTransform.rotation; }

	/**
	 * Gets the vector representing the manual offset that should be applied to this scene. This should be used when physical scene geometry gets translated in engine
	 * (ex. units moved and rotated in engine) and needs to match how it was previously rendered (so hotspots show properly etc).
	 */
	getRealWorldLocationOffset() { return this.manualOffsetTransform.translation; }

	/**
	 * Gets the effective location of where this scene was rendered in 3D space. Includes where the camera was rendered from, as well as any manual offset that was set.
	 */
	getRealWorldLocation() { return this.cameraTransform.translation.add(this.manualOffsetTransform.translation); }

	set deliverable(deliverable) { this.#deliverable = deliverable; }
	get deliverable() { return this.#deliverable; }

	// used with scene pointers to keep track of the revision the scene we're pointing to came from
	set pointedSceneDeliverable(originalDel) { this.#pointedSceneDeliverable = originalDel; }
	get pointedSceneDeliverable() { return this.#pointedSceneDeliverable; }

	get absoluteDataUrl() {
		if (this.isPointer) {
			return this.pointedSceneDeliverable.absoluteDataUrl;
		} else {
			return this.deliverable.absoluteDataUrl;
		}
	}
}