import URI from 'urijs';
import { sendApiRequest } from './opalConnector';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { asyncTimeout, boolStringToBool, cleanUndefinedKeysRecurse, ensureOrCreateArray, shortUuid } from './util';
import User from '../users/User';
import { getRedirectPageFromAuthState } from '../users/auth';

const userPool = new CognitoUserPool({
	UserPoolId: process.env.ABVR_USER_POOL_ID,
	ClientId: process.env.ABVR_USER_POOL_CLIENT_ID,
});

let cognitoUser = userPool.getCurrentUser();
// If a user was previously logged in, this getCurrentUser call will setup a new user that has a proper username, but no session or credentials, so cognitoUser will exist
// If no user was logged in, this will be null

window.cognitoUser = () => { return cognitoUser; }; // TODO testing only
window.userPool = userPool; // TODO testing only

/**
 * Tries to setup the auth session for a user from an existing session object attached to the user, then local storage, then using a refresh token if one exists
 */
export async function getUserSession() {
	if (!cognitoUser) {
		return;
	} else if (!(cognitoUser.getSignInUserSession() && cognitoUser.getSignInUserSession().isValid())) { // if no session, probably means there was a logged in user previously so lets get session from storage
		return await setupSignInSession();
	} else {
		return cognitoUser.getSignInUserSession();
	}
}

export function getCurrentUser() {
	return createUserFromCognitoUser(cognitoUser);
}

/**
 * @returns {Promise<CognitoUserSession>}
 */
async function setupSignInSession() {
	return await new Promise((resolve, reject) => {
		cognitoUser.getSession(async (err, session) => {
			if (err) {
				reject(err);
			} else {
				resolve(session);
			}
		});
	});
}

/**
 * @param {CognitoUser} cognitoUser 
 * @returns {User}
 */
function createUserFromCognitoUser(cognitoUser) {
	if (!cognitoUser || !cognitoUser.getSignInUserSession()?.getIdToken()?.payload) {
		return;
	}

	const attr = cognitoUser.getSignInUserSession().getIdToken().payload;
	const nativeAttr = getAttributesFromCognitoNames(attr);

	const user = new User(nativeAttr);
	return user;
}

/**
 * This gets use data either from storage or from a remote http call if it doesn't exist locally.
 * @param {Object} param0
 * @param {boolean} param0.refreshFromSource Forces a refresh of data from the source even if it exists locally
 * @returns {Object} Returns an object with cognito named attributes as keys and their associated values
 */
export async function getUserData({ refreshFromSource = false } = {}) {
	await new Promise((resolve, reject) => {
		cognitoUser.getUserData((err, result) => {
			if (err) {
				if (err.message === 'User is not authenticated') { // happened during our testing when we try to set the session ourselves and then is hard to recover from
					cognitoUser.signOut();
				}
				reject(err);
			} else {
				resolve(result);
			}
		}, { bypassCache: refreshFromSource });
	});
}

export async function forceTokenRefresh() {
	await new Promise((resolve, reject) => {
		cognitoUser.refreshSession(cognitoUser.getSignInUserSession().getRefreshToken(), (err, result) => {
			if (err) {
				reject(err);
			} else {
				resolve(result);
			}
		});
	});
}

/**
 * @param {string} email Should be sanitized email
 * @param {string} password 
 */
export async function signUserIn(email, password) {
	await privateAuthenticateUser(email, password);
	return createUserFromCognitoUser(cognitoUser);
}

export async function finishInviteUser(email, tempPassword, newPassword, { firstName, lastName }) {
	try {
		await privateAuthenticateUser(email, tempPassword);
	} catch (err) {
		if (err.code === 'newPasswordRequired') { // this is the happy path since this is the first time we're signing in after the account was invited to sign up
			const newAttributes = {
				// TODO if we have to add any params later, this is where they go when we sign users up
				given_name: firstName,
				family_name: lastName,
			};

			await new Promise((resolve, reject) => {
				const clientMetadata = { calledFrom: 'auth.finishInvitedUserSetup', };

				cognitoUser.completeNewPasswordChallenge(newPassword, newAttributes, {
					onSuccess: (result) => { resolve(result); },
					onFailure: (err) => { console.error(err); reject(err); },
					mfaRequired: async (codeDeliveryDetails) => { reject({ code: 'mfaRequired', cognitoUser, codeDeliveryDetails }); },
					customChallenge: async (challengeParams) => { reject({ code: 'customChallenge', cognitoUser, challengeParams }); },
				}, clientMetadata);
			});
		} else {
			throw err;
		}
	}
}

async function privateAuthenticateUser(email, password) {
	cognitoUser = new CognitoUser({
		Username: email,
		Pool: userPool,
	});

	const authDetails = new AuthenticationDetails({
		Username: email,
		Password: password,
		ClientMetadata: { calledFrom: 'sizzle-auth.signUserIn' }
	});

	await new Promise((resolve, reject) => {
		try {
			cognitoUser.authenticateUser(authDetails, {
				onSuccess: (result) => { resolve(result); },
				onFailure: (err) => { console.error(err); cognitoUser = undefined; reject(err); },
				newPasswordRequired: async (userAttributes, requiredAttributes) => { reject({ code: 'newPasswordRequired', cognitoUser, userAttributes, requiredAttributes }); },
				mfaRequired: async (codeDeliveryDetails) => { reject({ code: 'mfaRequired', cognitoUser, codeDeliveryDetails }); },
				customChallenge: async (challengeParams) => { reject({ code: 'customChallenge', cognitoUser, challengeParams }); },
			});
		} catch (err) {
			cognitoUser = undefined;
			reject(err);
		}
	});
}

export async function signUserOut() {
	let err;
	try {
		await privateSignOut();
	} catch (e) {
		err = e;
	}

	// If we signed in using an auth code and we don't navigate to the sign-out endpoint, next time we try to login it'll just go straight through instead of going to something like googles account chooser
	const shouldUseLogoutEndpoint = userPool.storage.getItem('signedInThroughAuthCode');
	if (shouldUseLogoutEndpoint) {
		await navigateToFederatedSignOutPage();
	}

	if (err) throw err; // make sure we always do the navigate to federated sign out page before we throw this error. Otherwise we can be in a loop where we can't sign out (user doesn't exist) and on refresh it's the same issu.
}

async function privateSignOut() {
	await new Promise(async (resolve, reject) => {
		if (cognitoUser) {
			cognitoUser.signOut((err) => {
				if (err) {
					if (/Please authenticate/i.test(err.message)) {
						// we're already logged out, just resolve as we're already done
						resolve();
					} else {
						console.error(err);
						reject(err);
					}
				} else {
					cognitoUser = undefined;
					resolve();
				}
			});
		} else {
			await navigateToFederatedSignOutPage();
		}
	});
}

export async function verifyEmail(email, code) {
	cognitoUser = new CognitoUser({
		Username: email,
		Pool: userPool,
	});

	return await new Promise((resolve, reject) => {
		const clientMetadata = { calledFrom: 'sizzle-auth.confirmSignUp' };
		cognitoUser.confirmRegistration(code, false, (err, result) => {
			if (err) {
				console.error(err);
				cognitoUser = undefined;
				reject(err);
			} else {
				resolve(result);
			}
		}, clientMetadata);
	});
}

export async function verifyEmailUpdate(code) {
	if (!cognitoUser || !(await getUserSession())?.isValid()) { throw new Error('Cannot verify updated email address without being logged in'); }

	return await new Promise((resolve, reject) => {
		cognitoUser.verifyAttribute('email', code, {
			onSuccess(result) { resolve(result); },
			onFailure(err) {
				console.error(err);
				reject(err);
			},
		});
	});
}

export async function verifyForgotPassword(email, forgotCode, newPassword) {
	cognitoUser = new CognitoUser({
		Username: email,
		Pool: userPool,
	});

	const clientMetadata = { calledFrom: 'auth.forgotPasswordSubmit' };
	return await new Promise((resolve, reject) => {
		cognitoUser.confirmPassword(forgotCode, newPassword, {
			onSuccess: (result) => { resolve(result); },
			onFailure: (err) => { console.error(err); cognitoUser = undefined; reject(err); },
		}, clientMetadata);
	});
}




/******************************************************************/
/****************** EXTERNAL USER OAUTH FUNCTION ******************/
/******************************************************************/

export async function navigateToFederatedSignInPage(provider, { abvrState } = {}) {
	const url = await generateFederatedSignInUrl(provider, { abvrState });
	window.location.replace(url);
	await asyncTimeout(60000); // really long timeout so anything that might get called up the stack that would cancel this navigation gets ignored (like window.location.reload)
}

async function navigateToFederatedSignOutPage() {
	const url = generateFederatedLogoutUrl();
	// const resp = await sendApiRequest({ absoluteUrl: url, auth: false, contentType: null }); // sending a get request like this will make a simple request and provide a 302, but it looks like it doesn't actually log us out so we can't use it
	window.location.replace(url);
	await asyncTimeout(60000); // really long timeout so anything that might get called up the stack that would cancel this navigation gets ignored (like window.location.reload)
}

async function generateFederatedSignInUrl(provider, { abvrState } = {}) {
	// https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html

	const code_verifier = generateCodeVerifier();
	const nonce = shortUuid.new();
	const state = shortUuid.new();
	userPool.storage.setItem('code_verifier', code_verifier);
	userPool.storage.setItem('nonce', nonce);
	userPool.storage.setItem('state', state);

	const url = new URI(`https://${process.env.ABVR_COGNITO_AUTH_DOMAIN}/oauth2/authorize`);
	url.setQuery({
		response_type: 'code',
		client_id: userPool.getClientId(),
		redirect_uri: getRedirectSignIn(),
		state: createOAuthState(state, abvrState),
		identity_provider: provider,
		scope: ['openid', 'email', 'phone', 'profile', 'aws.cognito.signin.user.admin'].join(' '),
		code_challenge: await generateCodeChallengeFromVerifier(code_verifier),
		code_challenge_method: 'S256',
		nonce,
	});

	return url;
}

function generateFederatedLogoutUrl() {
	// https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html

	const state = shortUuid.new();
	userPool.storage.setItem('state', state);

	const url = new URI(`https://${process.env.ABVR_COGNITO_AUTH_DOMAIN}/logout`);
	url.setQuery({
		client_id: userPool.getClientId(),
		logout_uri: getRedirectSignOut(),
		state: createOAuthState(state, ''),
	});

	return url;
}

function createOAuthState(csrfState, appState) {
	return `${csrfState}-${appState}`;
}

function separateOAuthState(authState) {
	if (!authState) return {};

	const split = authState.split('-');
	const csrfState = split.shift(); // gets first el
	const appState = split.join('-'); // rejoin anything that used '+' in the app state
	return { csrfState, appState };
}

export function validateCSRFStateParam(authState) {
	const expectedState = userPool.storage.getItem('state');

	if (separateOAuthState(authState).csrfState !== expectedState) {
		signUserOut(); // make sure to abort, otherwise refresh will have a logged in user. dont await since it'll navigate before we throw
		throw new Error(`Unique state passed redirected URI does not match saved state from initial auth! Initial: '${expectedState}', received: '${separateOAuthState(authState).csrfState}'`);
	}
}

export function getAppStatefromStateParam(authState) {
	let { appState } = separateOAuthState(authState);
	appState = appState.replaceAll('\\/', '/'); // when coming back from cognito, the forward slashes seem to get escaped (backslash forwardslash), which messes with our app state. Deal with that here.
	return appState;
}

export async function setupUserSessionFromCode(code) {
	const session = await getSessionFromCode(code);

	const idPayload = session.getIdToken().decodePayload();
	cognitoUser = new CognitoUser({ Username: idPayload['cognito:username'], Pool: userPool });
	cognitoUser.setSignInUserSession(session);

	userPool.storage.setItem('signedInThroughAuthCode', true);
}

async function getSessionFromCode(code) {
	const { id_token, access_token, refresh_token } = await getTokensFromCode(code);

	const session = new CognitoUserSession({
		IdToken: new CognitoIdToken({ IdToken: id_token }),
		AccessToken: new CognitoAccessToken({ AccessToken: access_token }),
		RefreshToken: new CognitoRefreshToken({ RefreshToken: refresh_token }),
	});

	const expectedNonce = userPool.storage.getItem('nonce');

	if (session.getIdToken().payload.nonce !== expectedNonce) {
		if (cognitoUser) signUserOut(); // make sure to abort, otherwise refresh will have a logged in user. only if user exists, might not since we just got the code. dont await since it'll navigate before we throw
		throw new Error(`Nonce in ID token response does not match saved nonce from initial auth! Initial: '${expectedNonce}', received: '${session.getIdToken().payload.nonce}'`);
	}

	return session;
}

async function getTokensFromCode(code) {
	// https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html

	const code_verifier = userPool.storage.getItem('code_verifier');

	const query = {
		client_id: userPool.getClientId(),
		redirect_uri: getRedirectSignIn(),
		grant_type: 'authorization_code',
		code,
		code_verifier,
	};

	try {
		const tokens = await sendApiRequest({ absoluteUrl: `https://${process.env.ABVR_COGNITO_AUTH_DOMAIN}/oauth2/token`, type: 'POST', data: query, contentType: 'application/x-www-form-urlencoded', auth: false });
		return tokens;
	} catch (error) {
		console.error(`Error getting tokens from endpoint: ${error}`);
		handleTokenEndpointError(error);
	}
}

export async function handleAuthorizeEndpointError({ error, error_description }) {
	// https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
	switch (error) {
		case 'invalid_request':
			if (/users must be invited to use this application/i.test(error_description)) { // this happens when we try to sign up an external user for the first time since we force create and existing cognito user first, since right now we're disallowing external signups
				console.log(`Disallowing new external user signup.`);
				throw new Error(error_description);
			} else if (/migrated external user to native account/i.test(error_description)) { // this happens when we try to sign up an external user for the first time since we force create and existing cognito user first. So we need to force it through the auth flow again. https://stackoverflow.com/questions/47815161/cognito-auth-flow-fails-with-already-found-an-entry-for-username-facebook-10155
				console.log(`New external user migrated to native user. Please re-attempt federated sign-in now that a native user exists. Cognito error: ${error_description}`);

				// Unfortunately, if there are multiple accounts signed in with the provider, it will force them to choose again... If just a single, then it'll go right through.
				const provider = error_description.match(/provider "(.*)"/)[1]; // full message is something like 'Please retry federated login for provider "Google"..' so we just need to get the 'Google'

				const appState = getRedirectPageFromAuthState(new URI().search(true).state);
				await navigateToFederatedSignInPage(provider, { abvrState: appState });
				break;
			}
		case 'unauthorized_client':
		case 'invalid_scope':
		case 'server_error':
		default:
			throw new Error(`${error}, ${error_description}`);
	}
}

function handleTokenEndpointError({ error, error_description }) {
	// https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
	switch (error) {
		case 'invalid_request':
		case 'invalid_client':
		case 'invalid_grant':
		case 'unauthorized_client':
		case 'unsupported_grant_type':
		default:
			throw new Error(`${error}, ${error_description}`);
	}
}

function getRedirectSignIn() {
	if (process.env.ABVR_MERCURY) { return process.env.ABVR_COGNITO_SIGNIN_MERCURY; }
	else if (window.location.href.includes('localhost')) { return process.env.ABVR_COGNITO_SIGNIN_LOCAL; }
	else if (window.location.href.includes(process.env.ABVR_COGNITO_SIGNOUT_LOCAL_IP.split('/')[2])) { return process.env.ABVR_COGNITO_SIGNIN_LOCAL_IP; }
	else { return process.env.ABVR_COGNITO_SIGNIN; }
}

function getRedirectSignOut() {
	if (process.env.ABVR_MERCURY) { return process.env.ABVR_COGNITO_SIGNOUT_MERCURY; }
	else if (window.location.href.includes('localhost')) { return process.env.ABVR_COGNITO_SIGNOUT_LOCAL; }
	else if (window.location.href.includes(process.env.ABVR_COGNITO_SIGNOUT_LOCAL_IP.split('/')[2])) { return process.env.ABVR_COGNITO_SIGNOUT_LOCAL_IP; }
	else { return process.env.ABVR_COGNITO_SIGNOUT; }
}



/**********************************************************************************/
/****************** CODE VERIFIER AND CHALLENGE HELPER FUNCTIONS ******************/
/**********************************************************************************/

function generateCodeVerifier() {
	var array = new Uint32Array(56 / 2);
	window.crypto.getRandomValues(array);
	return Array.from(array, dec2hex).join("");
}

async function generateCodeChallengeFromVerifier(code_verifier) {
	var hashed = await sha256(code_verifier);
	var base64encoded = base64urlencode(hashed);
	return base64encoded;
}

function dec2hex(dec) {
	return ('0' + dec.toString(16)).slice(-2);
}

async function sha256(plain) {
	const encoder = new TextEncoder();
	const data = encoder.encode(plain);
	return await window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
	var bytes = new Uint8Array(a);
	const string = Array.from(bytes).map(byte => String.fromCharCode(byte)).join('');

	return window.btoa(string)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '');
}


/********************************************************************************/
/****************** COGNITO TO NATIVE USER TRANSLATION HELPERS ******************/
/********************************************************************************/

function getAttributesFromCognitoNames(cognitoNamedAttributes) {
	const validAttributes = {
		userName: cognitoNamedAttributes['cognito:username'],
		userId: cognitoNamedAttributes.preferred_username,
		sub: cognitoNamedAttributes.sub,
		email: cognitoNamedAttributes.email,
		firstName: cognitoNamedAttributes.given_name,
		lastName: cognitoNamedAttributes.family_name,
		emailVerified: boolStringToBool(cognitoNamedAttributes.email_verified),
		status: cognitoNamedAttributes['cognito:user_status'],
		identities: getIdentitiesFromVal(cognitoNamedAttributes.identities),
		lastAuthenticatedAt: dateFromIntStr(cognitoNamedAttributes.updated_at),
	};

	cleanUndefinedKeysRecurse(validAttributes); // dont keep undefined or null keys, keep blank

	return validAttributes;
}

function dateFromIntStr(timeStr) {
	return timeStr && new Date(parseInt(timeStr));
}

function getIdentitiesFromVal(identitiesAttrVal) {
	if (identitiesAttrVal) {
		const identities = ensureOrCreateArray(parseIdentities(identitiesAttrVal)); // AWS API Gateway only returns a single object instead of an array, and everything else (including the mock api gateway in serverless offline) provides an array

		identities.forEach(identity => { // turn these into the proper values since that's what comes out when we actually link the user
			if (identity.primary) identity.primary = boolStringToBool(identity.primary);
			if (identity.dateCreated) identity.dateCreated = parseInt(identity.dateCreated);
		});

		return identities;
	}
}

function parseIdentities(identities) {
	if (typeof identities === 'string') return JSON.parse(identities); // need to explicitly check for string since Serverless Offline already parses this value for incoming claims WHILE EVERYTHING ELSE DOESN'T
	else return identities;
}

/******************************************************/
/****************** TOKEN OPERATIONS ******************/
/******************************************************/

export async function getIdJwtToken() {
	// TODO if session fails, we might need to throw an error to handle it upstream
	return (await getUserSession())?.getIdToken()?.getJwtToken(); // setupUserSession() will automatically refresh tokens if necessary
}

export async function isTokenExpired() {
	const expired = Date.now() > await getTokenExpiration();
	expired && console.log('token is expired!');
	return expired;
}

export async function timeToTokenExpiration({ rawSeconds = false } = {}) {
	const time = await getTokenExpiration() - Date.now();

	if (rawSeconds) { return time; }
	if (time < 0) { return 'EXPIRED'; }

	let dateString = new Date(time).toUTCString();
	dateString = dateString.split(' ')[4]; // expected output: Wed, 14 Jun 2017 07:00:00 GMT -> returns 07:00:00
	return dateString;
}

async function getTokenExpiration() {
	const session = await setupSignInSession();
	if (!session) { return; }

	const sec = new Date(0).setUTCSeconds(session.getAccessToken().getExpiration());
	return new Date(sec);
}