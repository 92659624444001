import Scene from './Scene';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import SceneFlatView from '../scene-views/SceneFlatView';
import { sceneTypes } from './sceneTypes';
import SceneLevelFlatGeo from '../scene-levels/SceneLevelFlatGeo';
import RenderingFlat from '../renderings/RenderingFlat';
import HotspotFlat from '../hotspots/HotspotFlat';

/**
 * @typedef {import('./Scene').SceneData & ChildClassData} SceneFlatData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _sceneFlatDataVer
 * @ignore
 */

const latestDataVer = 1;

export default class SceneFlat extends Scene {
	static _targetType = sceneTypes.flat;

	/**
	 * @param {SceneFlatData} inputData 
	 */
	constructor(inputData) {
		super(inputData);

		/** @type {SceneLevelFlatGeo[]} */
		this.levels;
	}

	/**
	 * @param {SceneFlatData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._sceneFlatDataVer = inputData._sceneFlatDataVer || latestDataVer;

		if (this._sceneFlatDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._sceneFlatDataVer}, should be ${latestDataVer}`); }
	}

	serialize() {
		if (this.isPointer) {
			return this.toPointer();
		} else {
			const ser = {
				...super.serialize(),
				_sceneFlatDataVer: this._sceneFlatDataVer,
			};

			return cleanUndefinedKeysRecurse(ser);
		}
	}

	toPointer() {
		return {
			...super.toPointer(),
			_sceneFlatDataVer: this._sceneFlatDataVer,
		};
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_sceneFlatDataVer: this._sceneFlatDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	setStartView({ x, y, zoom, mediaAspectRatio } = {}) {
		this.startView = new SceneFlatView({
			x,
			y,
			zoom,
			mediaAspectRatio,
		});
		return this.startView;
	}

	_createLevel(inputObj) {
		const newLevel = new SceneLevelFlatGeo(inputObj);
		this.levels.push(newLevel);
		return newLevel;
	}

	_createHotspot(inputObj) {
		const newHotspot = new HotspotFlat(inputObj);
		newHotspot.scene = this;

		this.hotspots.push(newHotspot);
		return newHotspot;
	}

	_createRendering(inputObj) {
		const newRendering = new RenderingFlat(inputObj);
		newRendering.scene = this;

		this.renderings.push(newRendering);
		return newRendering;
	}
}
