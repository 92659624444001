import SceneLevel from './SceneLevel';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { sceneLevelTypes } from './sceneLevelTypes';

/**
 * @typedef {import('./SceneLevel').SceneLevelData & ChildClassData} SceneLevelCubeGeoData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _sceneLevelCubeGeoDataVer
 * @property {number} size
 * @ignore
 */

const latestDataVer = 1;

export default class SceneLevelCubeGeo extends SceneLevel {
	static _targetType = sceneLevelTypes.cube;

	/**
	 * @param {SceneLevelCubeGeoData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {SceneLevelCubeGeoData} inputData 
	 */
	_reInit(inputData) {
		if (!Number.isInteger(inputData.size / inputData.tileSize)) throw new Error(`Scene level requires size to be a multiple of tile size. Size: '${inputData.size}', tilesize: '${inputData.tileSize}'`); // https://www.marzipano.net/reference/CubeGeometry.html
		super._reInit(inputData);
		this._sceneLevelCubeGeoDataVer = inputData._sceneLevelCubeGeoDataVer || latestDataVer;

		if (this._sceneLevelCubeGeoDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._sceneLevelCubeGeoDataVer}, should be ${latestDataVer}`); }

		this.size = parseFloat(inputData.size) || 0;
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_sceneLevelCubeGeoDataVer: this._sceneLevelCubeGeoDataVer,
			size: this.size,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_sceneLevelCubeGeoDataVer: this._sceneLevelCubeGeoDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}
}