import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { renderingTypes } from '../renderings/renderingTypes';
import Rendering from './Rendering';

/**
 * @typedef {import('./Rendering').RenderingData & ChildClassData} RenderingFlatData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _renderingFlatDataVer
 * @property {number} fov
 * @ignore
 */

const latestDataVer = 1;

export default class RenderingFlat extends Rendering {
	static _targetType = renderingTypes.flat;

	/**
	 * @param {RenderingFlatData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {RenderingFlatData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
        this._renderingFlatDataVer = inputData._renderingFlatDataVer || latestDataVer;

		if (this._renderingFlatDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._renderingFlatDataVer}, should be ${latestDataVer}`); }

		this.fov = parseFloat(inputData.fov);
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_renderingFlatDataVer: this._renderingFlatDataVer,
			fov: this.fov,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_renderingFlatDataVer: this._renderingFlatDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
			fov: this.fov,
		};

		return cleanUndefinedKeysRecurse(updates);
	}
}