
export default class DataNotifier {
	#eventHandlers = {
		dataCompanyChange: [],
		dataProjectChange: [],
		dataDeliverableChange: [],
		dataCommentChange: [],
		dataUsersChange: [],
	};

	constructor() { }

	addEventHandler(eventId, handler) {
		if (!this.#eventHandlers[eventId]) { console.error(`Event ID '${eventId}' does not exist`); return; }
		if (!this.#eventHandlers[eventId].includes(handler)) this.#eventHandlers[eventId].push(handler);
	}

	removeEventHandler(eventId, handler) {
		if (!this.#eventHandlers[eventId]) { console.error(`Event ID '${eventId}' does not exist`); return; }
		this.#eventHandlers[eventId] = this.#eventHandlers[eventId].filter((savedHandler) => savedHandler !== handler);
	}

	getEventHandlers(eventId) {
		if (!this.#eventHandlers[eventId]) { console.error(`Event ID '${eventId}' does not exist`); return; }
		return this.#eventHandlers[eventId];
	}

	dispatchEvent({ eventId, data }) {
		const handlers = this.getEventHandlers(eventId);
		handlers.forEach(async handler => handler?.(data)); // use async just so it won't hang anything, if it doesnt exist just continue
	}
}