import SceneLevel from './SceneLevel';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { sceneLevelTypes } from './sceneLevelTypes';

/**
 * @typedef {import('./SceneLevel').SceneLevelData & ChildClassData} SceneLevelFlatGeoData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _sceneLevelFlatGeoDataVer
 * @property {number} width
 * @property {number} height
 * @ignore
 */

const latestDataVer = 1;

export default class SceneLevelFlatGeo extends SceneLevel {
	static _targetType = sceneLevelTypes.flat;

	/**
	 * @param {SceneLevelFlatGeoData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {SceneLevelFlatGeoData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._sceneLevelFlatGeoDataVer = inputData._sceneLevelFlatGeoDataVer || latestDataVer;

		if (this._sceneLevelFlatGeoDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._sceneLevelFlatGeoDataVer}, should be ${latestDataVer}`); }

		this.width = parseFloat(inputData.width) || 0;
		this.height = parseFloat(inputData.height) || 0;

		if (!this.width || !this.height) { throw new Error(`Cannot create scene level geometry without width and/or height`); }
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_sceneLevelFlatGeoDataVer: this._sceneLevelFlatGeoDataVer,
			width: this.width,
			height: this.height,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_sceneLevelFlatGeoDataVer: this._sceneLevelFlatGeoDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}
}