import DeletionData from '../../lib/DeletionData';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import Project from '../projects/Project';
import { deliverableTypes } from './deliverableTypes';
// eslint-disable-next-line import/no-unresolved
import { getStaticLocation } from '#preload';

/**
 * @typedef {Object} DeliverableData
 * @property {number} _deliverableDataVer
 * @property {deliverableTypes} type
 * @property {string} companyId
 * @property {string} projectId
 * @property {string} deliverableId
 * @property {string} deliverableName
 * @property {Date} creationDate
 * @property {string} dataLocation
 * @property {string} visibility
 * @property {string} author
 * @property {string} authorEmail
 * @property {DeletionData} deletionData
 * @property {boolean} [isLocalOnly]
 */

const latestDataVer = 1;

export default class Deliverable {
    static _targetType = 'base';

    /** @type {Project} */
    #project;
    #isLocalOnly;

    /**
     * @param {DeliverableData} inputData 
     */
    constructor(inputData) {
        this._reInit(inputData);
    }

    /**
     * @param {DeliverableData} inputData 
     */
    _reInit(inputData) {
        this._deliverableDataVer = inputData._deliverableDataVer || latestDataVer;
        /** @type {deliverableTypes} */
        this.type = inputData.type || this.constructor._targetType;

        if (this._deliverableDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._deliverableDataVer}', should be '${latestDataVer}'`); }
        if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }

        this.companyId = inputData.companyId;
        this.projectId = inputData.projectId;
        this.deliverableId = inputData.deliverableId;
        this.deliverableName = inputData.deliverableName;
        this.creationDate = inputData.creationDate ? new Date(inputData.creationDate) : new Date();  // make sure creationDate isn't undefined since new Date(undefined) which we use in constructor returns Invalid Date
        this.dataLocation = inputData.dataLocation;
        this.visibility = inputData.visibility;
        this.author = inputData.author;
        this.authorEmail = inputData.authorEmail;
        this.deletionData = new DeletionData(inputData.deletionData);

        this.isLocalOnly = inputData.isLocalOnly || false;
    }

    serialize() {
        const ser = {
            _deliverableDataVer: this._deliverableDataVer,
            type: this.type,
            companyId: this.companyId,
            projectId: this.projectId,
            deliverableId: this.deliverableId,
            deliverableName: this.deliverableName,
            creationDate: this.creationDate.toISOString(),
            dataLocation: this.dataLocation,
            visibility: this.visibility,
            author: this.author,
            authorEmail: this.authorEmail,
            deletionData: this.deletionData.serialize(),

            isLocalOnly: this.isLocalOnly, // save this when we're serializing since we could be saving locally. if it's undefined it'll be removed
        };

        return cleanUndefinedKeysRecurse(ser);
    }

    getUpdatableData() {
        // we can only request to directly update these
        const updates = {
            _deliverableDataVer: this._deliverableDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
            deliverableName: this.deliverableName,
            visibility: this.visibility,
        };

        return cleanUndefinedKeysRecurse(updates);
    }

    set project(project) { this.#project = project; }
    /** @type {Project} */
    get project() { return this.#project; }

    set isLocalOnly(isLocal) { this.#isLocalOnly = isLocal; }
    get isLocalOnly() { return this.#isLocalOnly; }

    get relativeDataUrl() {
        return this.dataLocation.replaceAll('\\', '/'); // ensure forward slashes
    }

    get absoluteDataUrl() {
        if (this.isLocalOnly) { // if it's local, can't be online yet so serve from a local location
            if (process.env.ABVR_MERCURY && process.env.ABVR_PACKAGED) { // for a packaged mercury app, the static location will be where the images are held until publishing
                return `${getStaticLocation()}/${this.relativeDataUrl}`;
            } else {
                return this.relativeDataUrl; // for the non-packaged app (dev server) just use the relative data url since it's served by the dev server
            }
        } else {
            return `${process.env.ABVR_COGNITO_SIGNIN}${this.relativeDataUrl}`; // ABVR_COGNITO_SIGNIN already has a trailing slash
        }
    }

    isDeleted() { return this.deletionData.isDeleted; }
}