import siz from '../data-manager/DataManager';
import Company from '../data-manager/display-data/companies/Company';
import Project from '../data-manager/display-data/projects/Project';
import Scene from '../data-manager/display-data/scenes/Scene';
import Rendering from '../data-manager/display-data/renderings/Rendering';
import { curUser } from '../data-manager/users/auth';
import User from '../data-manager/users/User';
import PanoRev from '../data-manager/display-data/deliverables/PanoRev';

export default class DataState {
	constructor() {
		// Currently loaded & displayed data
		/** @type {Company} */
		this.company;
		/** @type {Project} */
		this.project;
		/** @type {PanoRev} */
		this.deliverable;
		/** @type {Scene} */
		this.scene;
		/** @type {Rendering} */
		this.rendering;
		this.renderOptions = {};
	}

	get user() { // TODO may want to change this from getter since we're allocating an object here
		const loggedInUser = new CurrentUser(curUser(), this);

		const dlUser = siz.getUserById(loggedInUser.userId);
		if (dlUser) {
			dlUser.roles.forEach(role => loggedInUser._addRole(role));
		}

		return loggedInUser;
	}

	clear() {
		this.company = undefined;
		this.project = undefined;
		this.deliverable = undefined;
		this.scene = undefined;
		this.rendering = undefined;
		this.handleDataUpdated();
	}

	setCurCompany(companyId) {
		this.company = siz.getCompany(companyId);
		this.project = undefined;
		this.deliverable = undefined;
		this.scene = undefined;
		this.rendering = undefined;
		this.handleDataUpdated();
		return this.company;
	}

	setCurProject(projectId) {
		this.project = this.company.getProject(projectId);
		this.deliverable = undefined;
		this.scene = undefined;
		this.rendering = undefined;
		this.handleDataUpdated();
		return this.project;
	}

	setCurDeliverable(deliverableId) {
		this.deliverable = this.project.getDeliverable(deliverableId);
		this.scene = undefined;
		this.rendering = undefined;
		this.handleDataUpdated();
		return this.deliverable;
	}

	setCurScene(sceneId) {
		this.scene = this.deliverable.getScene(sceneId);
		this.rendering = undefined;
		this.handleDataUpdated();
		return this.scene;
	}

	setCurRendering(renderId) {
		this.rendering = this.scene.getRendering(renderId);
		this.mergeRenderingOptions(this.rendering.capturedOptions);
		this.handleDataUpdated();
		return this.rendering;
	}

	mergeRenderingOptions(renderOptionObj) {
		if (typeof renderOptionObj !== 'object') return;
		for (const categoryId in renderOptionObj) {
			this.renderOptions[categoryId] = renderOptionObj[categoryId];
		}
		return this.renderOptions;
	}

	handleDataUpdated() { } // virtual for now

	static copyFrom(data) {
		const output = new DataState();
		output.setCurCompany(data.company?.companyId);
		if (output.company) {
			output.setCurProject(data.project?.projectId);
			if (output.project) {
				output.setCurDeliverable(data.deliverable?.deliverableId);
				if (output.deliverable) {
					output.setCurScene(data.scene?.sceneId);
					if (output.scene) {
						output.setCurRendering(data.rendering?.renderId);
						if (output.rendering) {
							output.mergeRenderingOptions(data.renderOptions);
						}
					}
				}
			}
		}
		return output;
	}
}

class CurrentUser extends User {
	/** @type {DataState} */
	#dataState;

	constructor(inputObj, curDataState) {
		super(inputObj);
		this.#dataState = curDataState;
	}

	isAdminUser({
		companyId = this.#dataState.company?.companyId,
		projectId = this.#dataState.project?.projectId
	} = {}) {
		return this.hasAdminAccess(companyId, projectId);
	}

	isPublicUser({
		companyId = this.#dataState.company?.companyId,
		projectId = this.#dataState.project?.projectId
	} = {}) {
		return this.hasPublicAccessOnly(companyId, projectId);
	}

	canMakeWorkflowChanges({
		companyId = this.#dataState.company?.companyId,
		projectId = this.#dataState.project?.projectId
	} = {}) {
		const role = this.getEffectiveRole(companyId, projectId);
		return role.hasAccessGreaterOrEqualTo('basic');
	}

	canMakeComments({
		companyId = this.#dataState.company?.companyId,
		projectId = this.#dataState.project?.projectId
	} = {}) {
		const role = this.getEffectiveRole(companyId, projectId);
		return role.hasAccessGreaterOrEqualTo('basic');
	}
}