
import UserRole from './UserRole';
import { boolStringToBool, ensureOrCreateArray } from '../lib/util';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { userRoleTypes } from './userTypes';
import UserCompanyRole from './UserRoleCompany';
import UserProjectRole from './UserRoleProject';

export default class User {
	/** @type {UserRole[]} */
	#roles = [];

	// Data that needs to be handled differently when set()
	#email = '';

	constructor(inputData) {
		this._reInit(inputData);
	}

	_reInit({
		userName = '',
		userId = '',
		sub = '',
		email = '',
		firstName = '',
		lastName = '',
		emailVerified = undefined,
		identities = undefined,
		lastAuthenticatedAt = undefined,
		roles = [],
	} = {}) {
		this.userName = userName;
		this.userId = userId;
		this.sub = sub;
		this.email = email;
		this.firstName = firstName;
		this.lastName = lastName;
		this.emailVerified = emailVerified;
		this.identities = identities; // for links to external providers only
		this.createdFrom = undefined;
		this.lastAuthenticatedAt = lastAuthenticatedAt;
		roles.forEach(role => this._addRole(role));
	}

	get roles() { return this.#roles; }

	get log() { return this.toJSON(); } // easy way to log our data instead of having to use toJSON()

	get email() { return this.#email; }
	set email(newEmail) { this.#email = newEmail?.trim().toLowerCase(); } // still need special handling in frontend for Auth calls that use this string
	get fullName() {
		return `${this.firstName} ${this.lastName}`.trim(); // still need to trim in case first or last is blank
	}

	// TODO move this to auth?
	/**
	 * @param {CognitoUser} cognitoUser 
	 */
	static _createFromCognitoUser(cognitoUser) {
		const newUser = new this({ // could be child class (like CurrentUser), so instantiate that class instead of just the User class
			userName: cognitoUser.getUsername(),
			userId: cognitoUser.attributes.preferred_username,
			sub: cognitoUser.attributes.sub,
			email: cognitoUser.attributes.email,
			firstName: cognitoUser.attributes.given_name,
			lastName: cognitoUser.attributes.family_name,
			emailVerified: cognitoUser.attributes.email_verified,
			identities: getIdentitiesFromVal(cognitoUser.attributes.identities),
			lastAuthenticatedAt: dateFromIntStr(cognitoUser.attributes.updated_at),
		});

		return newUser;
	}

	_addRole(inputObj) {
		if (!inputObj) return;

		if (inputObj.userId !== this.userId) throw new Error(`Cannot add role for different user: ${inputObj.userId} vs ${this.userId}`);

		inputObj.userEmail = this.email; // always set the new email since the role email may be out of date

		const newRole = inputObj.type === userRoleTypes.company ? new UserCompanyRole(inputObj) : new UserProjectRole(inputObj);

		const existingRole = newRole.type === userRoleTypes.company ? this.getCompanyRole(newRole.companyId) : this.getProjectRole(newRole.projectId);
		if (existingRole) { this._removeRole(existingRole); }

		this.roles.push(newRole);
		return newRole;
	}

	_removeRole(userRole) {
		const roleIndex = this.roles.findIndex(role => role === userRole);
		if (roleIndex !== -1) { this.roles.splice(roleIndex, 1); }
	}

	getAbvrRole() { return this.getCompanyRole('abvr'); }
	getCompanyRole(companyId) { return this.roles.find(role => role.companyId === companyId && role.type === userRoleTypes.company); }
	getProjectRole(projectId) { return this.roles.find(role => role.projectId === projectId && role.type === userRoleTypes.project); }
	getEffectiveRole(companyId, projectId = undefined) {
		const roleData = {
			userId: this.userId,
			userEmail: this.email,
			companyId,
			projectId,
			authRole: 'public',
			orgRole: 'None',
		};

		const publicRole = projectId ? new UserProjectRole(roleData) : new UserCompanyRole(roleData);

		const abvrRole = this.getAbvrRole();
		const companyRole = this.getCompanyRole(companyId);
		const projectRole = this.getProjectRole(projectId);

		// Figure out what our main role is
		if (abvrRole) { // if we have an admin role at ABVR, we don't need to have a role specifically in the project
			return abvrRole; // if we have any role at abvr, we should have access
		} else if (companyRole && !projectRole) { // if there's a company role and no project role, return that
			return companyRole;
		} else if (companyRole && companyRole.level < projectRole.level) { // if there's a company role that is better than the role on the project, then return that.
			return companyRole;
		} else if (projectRole) {
			return projectRole;
		}

		return publicRole;
	}


	isAbvrUser() { return !!this.getAbvrRole(); }
	isAnonymous() { return this.userId === 'anonymous'; }

	static _anonymousUser() {
		return new User({ userId: 'anonymous' });
	}

	hasAdminAccess(companyId, projectId) {
		if (!companyId) { throw new Error(`Cannot check admin status; companyId not provided`); }

		if (this.isAbvrUser()) return true;
		return this.getEffectiveRole(companyId, projectId).authRole === 'admin';
	}
	hasPublicAccessOnly(companyId, projectId) { return this.getEffectiveRole(companyId, projectId).authRole === 'public'; }

	hasExternalIdentity() { return this.identities?.length > 0; }
	get externalProviderName() { return this.identities?.[0].providerName; }
	get externalProviderId() { return this.identities?.[0].userId; }

	getLastAuthAtString() { return this.lastAuthenticatedAt?.valueOf().toString(); }


	// Need to include all the getters manually
	toJSON() {
		return {
			...this,
			email: this.email,
			fullName: this.fullName,
		};
	}
}

function dateFromIntStr(timeStr) {
	return timeStr && new Date(parseInt(timeStr));
}

function getIdentitiesFromVal(identitiesAttrVal) {
	if (identitiesAttrVal) {
		const identities = ensureOrCreateArray(parseIdentities(identitiesAttrVal)); // AWS API Gateway only returns a single object instead of an array, and everything else (including the mock api gateway in serverless offline) provides an array

		identities.forEach(identity => { // turn these into the proper values since that's what comes out when we actually link the user
			if (identity.primary) identity.primary = boolStringToBool(identity.primary);
			if (identity.dateCreated) identity.dateCreated = parseInt(identity.dateCreated);
		});

		return identities;
	}
}

function parseIdentities(identities) {
	if (typeof identities === 'string') return JSON.parse(identities); // need to explicitly check for string since Serverless Offline already parses this value for incoming claims WHILE EVERYTHING ELSE DOESN'T
	else return identities;
}