import Hotspot from './Hotspot';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { hotspotTypes } from './hotspotTypes';
import SceneFlatLocation from '../scene-locations/SceneFlatLocation';

/**
 * @typedef {import('./Hotspot').HotspotData & ChildClassData} HotspotFlatData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _hotspotFlatDataVer
 * @ignore
 */

const latestDataVer = 1;

/**
 * @extends Hotspot
 */
export default class HotspotFlat extends Hotspot {
	static _targetType = hotspotTypes.flat;

	/**
	 * @param {HotspotFlatData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {HotspotFlatData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
		this._hotspotFlatDataVer = inputData._hotspotFlatDataVer || latestDataVer;

		if (this._hotspotFlatDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._hotspotFlatDataVer}, should be ${latestDataVer}`); }
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_hotspotFlatDataVer: this._hotspotFlatDataVer,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_hotspotFlatDataVer: this._hotspotFlatDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	/**
	 * @param {SceneFlatLocation} location 
	 */
	setCoordsFromLocation(location) {
		// force creation of a new object for safety
		this.location = new SceneFlatLocation(location);
	}
}