import { sendApiRequest } from '../lib/opalConnector';

export async function getAllUserRoles(userId, { includeUserData = false } = {}) {
	const { roles, user } = await sendApiRequest({ url: `/roles/${userId}`, queryParams: { includeUserData } });
	return { roles, user };
}

export async function getUserRole(userId, companyId, { projectId, effectiveRole = false, includeUserData = false } = {}) {
	const returned = await sendApiRequest({ url: `/roles/${userId}`, queryParams: { companyId, projectId, effectiveRole, includeUserData } });
	return {
		role: effectiveRole ? returned.role : returned.roles?.[0],
		user: returned.user,
	};
}

/**
 * @returns {Promise<import('./UserRole').UserRoleData>}
 */
export async function updateUserRole({ userId, companyId, projectId }, updatedData) {
	const { role } = await sendApiRequest({ url: `/roles/${userId}`, type: 'PUT', data: updatedData, queryParams: { companyId, projectId } });
	return role;
}

/**
 * @returns {Promise<import('./UserRole').UserRoleData>}
 */
export async function deleteUserRole({ userId, companyId, projectId }) {
	const { roles } = await sendApiRequest({ url: `/roles/${userId}`, type: 'DELETE', queryParams: { companyId, projectId } });
	return roles?.[0];
}

export async function inviteUser({ email, companyId, projectId, accessLevel, orgRole, }, { shouldSendEmail = true } = {}) {
	const data = {
		newUserEmail: email,
		projectId,
		companyId,
		accessLevel,
		orgRole,
	};

	const { newUser } = await sendApiRequest({ url: '/users/invite', type: 'POST', queryParams: { shouldSendEmail }, data });
	return newUser;
}

export async function acceptTCs(userId) {
	const { message } = await sendApiRequest({ url: `/users/terms/${userId}`, type: 'POST', data: { userAgreesToTCs: true } });
	return message;
}