import { v5 as uuidv5 } from 'uuid';
import { default as short } from 'short-uuid';

window.Marzipano = window.Marzipano || {};
window.Hammer = window.Hammer || {};

/******************************************************/
/****************** HELPER FUNCTIONS ******************/
/******************************************************/

export function isIE() {
	return !!window.document.documentMode; // https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie
}

export function cleanUndefinedKeysRecurse(obj, { keysToIgnore = [] } = {}) {
	keysToIgnore = ensureOrCreateArray(keysToIgnore);
	Object.keys(obj).forEach(key => {
		if (obj[key] && typeof obj[key] === 'object') { cleanUndefinedKeysRecurse(obj[key]); }
		else if (obj[key] === undefined && !keysToIgnore.includes(key)) delete obj[key];
	});
	return obj;
}

export function cleanUndefinedAndNullKeysRecurse(obj, { keysToIgnore = [] } = {}) {
	keysToIgnore = ensureOrCreateArray(keysToIgnore);
	Object.keys(obj).forEach(key => {
		if (obj[key] && typeof obj[key] === 'object') { cleanUndefinedAndNullKeysRecurse(obj[key]); }
		else if (obj[key] === undefined && !keysToIgnore.includes(key)) delete obj[key];
		else if (obj[key] === null && !keysToIgnore.includes(key)) delete obj[key];
	});
	return obj;
}

export function cleanUndefinedNullAndBlankKeysRecurse(obj, { keysToIgnore = [] } = {}) {
	keysToIgnore = ensureOrCreateArray(keysToIgnore);
	Object.keys(obj).forEach(key => {
		if (obj[key] && typeof obj[key] === 'object') { cleanUndefinedNullAndBlankKeysRecurse(obj[key]); }
		else if (obj[key] === undefined && !keysToIgnore.includes(key)) delete obj[key];
		else if (obj[key] === null && !keysToIgnore.includes(key)) delete obj[key];
		else if (obj[key] === '' && !keysToIgnore.includes(key)) delete obj[key];
	});
	return obj;
}

export function makeSimpleObjCopy(obj) {
	if (!obj) return {};
	return JSON.parse(JSON.stringify(obj));
}

export function copyObjectKeysToObject(sourceObj, destObj) {
	for (const key in sourceObj) {
		destObj[key] = sourceObj[key];
	}
}

export function shallowCompare(obj1, obj2) {
	return Object.keys(obj1).length === Object.keys(obj2).length &&
		Object.keys(obj1).every(key => obj1[key] === obj2[key]);
}

export function stringifyCompare(obj1, obj2) {
	return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function prettyPrintObject(obj) {
	return JSON.stringify(obj, null, 2);
}

export function isDevelopment() {
	return process.env.ABVR_STAGE !== 'production';
}

// Remove all leading forward slashes from a string and return it (used if a string that got added is blank)
export function removeLeadingForwardSlashes(path) {
	while (path[0] === '/') { path = path.substring(1); }
	return path;
}

// Remove all trailing forward slashes from a string and return it (used if a string that got added is blank)
export function removeTrailingForwardSlashes(path) {
	while (path[path.length - 1] === '/') { path = path.substring(0, path.length - 1); }
	return path;
}

/**
 * Replaces any character not in the following set with a '-' character: a-zA-Z0-9-_
 * @param {string} str 
 */
export function sanitizeStr(str, { replacementChar = '_', allowUppercase = true } = {}) {
	if (!str && str !== 0) { return str; } // if undefined, NULL, etc
	if (typeof str !== 'string') { str = String(str); }
	if (!allowUppercase) str = str.toLowerCase();
	str = str.trim().replace(/[^a-zA-Z0-9-_]/g, replacementChar);
	return str;
}

// Ensure the input is an array and pass either the array back or a new array
export function ensureOrCreateArray(variableToTest) {
	if (variableToTest === undefined || variableToTest === null) return [];

	if (!Array.isArray(variableToTest)) { variableToTest = [variableToTest]; }
	return variableToTest;
}

/**
 * Checks an object for the existance of of an array of attributes. Returns an object with attributes .valid and .message.
 * @param {object} object Object to check
 * @param {string[]} attributesToCheck Array of attributes to check existence
 */
export function checkHasAttributes(object, attributesToCheck = []) {
	const returnVal = {
		valid: true,
		missing: [],
		string: undefined,
	};

	if (!Array.isArray(attributesToCheck)) { // if we only have one item, make it an array
		attributesToCheck = [attributesToCheck];
	}

	attributesToCheck.forEach((att) => {
		if (!object[att] && object[att] !== 0) { // if it's falsy and not = 0, not a valid value
			returnVal.valid = false;
			returnVal.missing.push(att);
		}
	});

	returnVal.string = `'${returnVal.missing.join(`', '`)}'`;
	return returnVal;
}

// Generate an array with unique values from an existing array that may include a single value multiple times
export function makeUnique(array) {
	const set = new Set(array);
	return Array.from(set);
}

/**
 * This function copies all keys from the input object to the output object, ignoring keys in the attList. If no output object is passed,
 * a blank object will be filled instead.
 * @param {string[]} keysToIgnore List of attributes that should not be copied from the input object to the output object
 * @param {Object} inputObj Object to copy attributes from
 * @param {Object} outputObj Object to copy attributes to
 */
export function copyNonIgnoredKeys(keysToIgnore, inputObj, outputObj = {}) {
	Object.keys(inputObj).forEach((key) => {
		if (!keysToIgnore.includes(key)) {
			outputObj[key] = inputObj[key];
		}
	});

	return outputObj;
}

export function generateRandomString(numchars) {
	let str = '';

	function isValid(str) { return str.length == numchars; }

	while (!isValid(str)) {
		str = Math.random().toString(36).replace(/[^a-zA-Z0-9]+/g, '').substring(1, numchars + 1);
	}

	return str;
}

/**
 * Takes all keys in the provided object and tries to parse them into floats, ignoring some known string values we need to keep.
 * @param {Object} obj 
 */
export function coerceStrAttributesToNum(obj) {
	for (let k in obj) {
		if (k.search(/^date|Date/) === -1
			&& k !== 'version'
			&& k !== 'deliverableName'
			&& k !== 'deliverableId'
		) {
			const check = parseFloat(obj[k]);
			if (check || check === 0) { // if this value is a valid number (not NaN which is falsy), then store it as such
				obj[k] = parseFloat(obj[k]);
			}
		}
	}
}

export function boolStringToBool(str) {
	return str === 'false' ? false : Boolean(str);
}

export function boolStringToBoolInObj(obj, { keysToIgnore = [] } = {}) {
	keysToIgnore = ensureOrCreateArray(keysToIgnore);
	Object.keys(obj).forEach(key => {
		if (obj[key] && typeof obj[key] === 'object') { boolStringToBoolInObj(obj[key]); }
		else if (obj[key] === 'true' && !keysToIgnore.includes(key)) obj[key] = boolStringToBool(obj[key]);
		else if (obj[key] === 'false' && !keysToIgnore.includes(key)) obj[key] = boolStringToBool(obj[key]);
	});
	return obj;
}

export function countDecimals(value) {
	if (Math.floor(value) !== value) {
		return value.toString().split('.')[1].length || 0;
	}
	return 0;
}

/**
 * Converts from degrees to radians
 * @param {number} angleInDegrees 
 */
export function rad(angleInDegrees) {
	if (angleInDegrees === undefined || angleInDegrees === null) return undefined;
	return angleInDegrees * (Math.PI / 180);
}

/**
 * Converts from radians to degrees
 * @param {number} angleInRadians 
 */
export function deg(angleInRadians) {
	if (angleInRadians === undefined || angleInRadians === null) return undefined;
	return angleInRadians * (180 / Math.PI);
}

export function modValIntoRange(value, min, max) {
	const range = max - min;

	while (value > max) { value -= range; }
	while (value < min) { value += range; }

	return value;
}

// Need to be very careful when using global matching, since .match and .replace will be good, but .test will toggle results: https://stackoverflow.com/questions/894696/why-is-the-javascript-regexp-test-method-behaving-as-a-toggle
export const emailMatcher = new RegExp(/\S+@\S+\.\S+/);
export const urlMatcher = new RegExp(/(\b((blob:)?https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/i);
export const globalUrlMatcher = new RegExp(urlMatcher, 'ig');
export const dataUrlMatcher = new RegExp(/^(data:)([\w/+-]*)(;charset=[\w-]+|;base64)/i);
export const globalDataUrlMatcher = new RegExp(dataUrlMatcher, 'ig');
export const blobUrlMatcher = new RegExp(/(\bblob:(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/i);
export const globalBlobUrlMatcher = new RegExp(blobUrlMatcher, 'ig');
export const imageNameMatcher = new RegExp(/\.(jpeg|jpg|gif|png)$/i);
export const uuidV4Matcher = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
export const uuidV5Matcher = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
export const uuidMatcher = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i); // coming from MS (and our UE4 setup), it won't match v4 output always
export const shortUuidMatcher = new RegExp(/^[123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ]{22}$/); // flickrBase58 for use with our short UUIDs

export const abvrUuid = uuidv5('atlasbayvr.com', uuidv5.DNS);
export const shortUuid = short();
window.shortUuid = shortUuid;

export async function asyncTimeout(ms) {
	return new Promise(resolve => {
		setTimeout(() => { resolve(); }, ms);
	});
}