import Vector from '../../lib/Vector';
import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { sceneViewTypes } from './sceneViewTypes';

/**
 * @typedef {Object} SceneViewData
 * @property {number} _sceneViewDataVer
 * @property {sceneViewTypes} type
 */

const latestDataVer = 1;

/**
 * A scene view. See {@link import('./SceneFlatView').SceneFlatView} for flat view implementation and {@link import('./ScenePanoView').ScenePanoView} for pano view implementation.
 * @see SceneFlatView
 * @see ScenePanoView
 */
export default class SceneView {
	static _targetType = 'base';

	/**
	 * @param {SceneViewData} inputData 
	 */
	constructor(inputData) {
		this._reInit(inputData);
	}

	/**
	 * @param {SceneViewData} inputData 
	 */
	_reInit(inputData) {
		this._sceneViewDataVer = inputData._sceneViewDataVer || latestDataVer;
		this.type = inputData.type || this.constructor._targetType;

		if (this._sceneViewDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got '${this._sceneViewDataVer}', should be '${latestDataVer}'`); }
		if (this.type !== this.constructor._targetType) { throw new Error(`Trying to create object with wrong type: Got '${this.type}', should be '${this.constructor._targetType}'`); }
	}

	serialize() {
		const ser = {
			_sceneViewDataVer: this._sceneViewDataVer,
			type: this.type,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			_sceneViewDataVer: this._sceneViewDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}

	/**
	 * Virtual function, override at child
	 * @param {Vector} vector 
	 */
	// eslint-disable-next-line no-unused-vars
	static fromVector(vector) { }

	/**
	 * Virtual function, override at child
	 */
	// eslint-disable-next-line no-unused-vars
	round({ decimals }) { }

	/**
	 * Virtual function, override at child
	 */
	// eslint-disable-next-line no-unused-vars
	add(view) { }

	/**
	 * Virtual function, override at child
	 */
	// eslint-disable-next-line no-unused-vars
	subtract(view) { }

	/**
	 * Virtual function, override at child
	 */
	// eslint-disable-next-line no-unused-vars
	modIntoRange({ min, max }) { }

	/**
	 * Virtual function, override at child
	 */
	isDefault() { return true; }
}