import { cleanUndefinedKeysRecurse } from '../../lib/util';
import { renderingTypes } from './renderingTypes';
import Rendering from './Rendering';

/**
 * @typedef {import('./Rendering').RenderingData & ChildClassData} RenderingPanoData
 * @combinedata
 */
/**
 * @typedef {Object} ChildClassData
 * @property {number} _renderingPanoDataVer
 * @ignore
 */

const latestDataVer = 1;

export default class RenderingPano extends Rendering {
	static _targetType = renderingTypes.pano;

	/**
	 * @param {RenderingPanoData} inputData 
	 */
	constructor(inputData) {
		super(inputData);
	}

	/**
	 * @param {RenderingPanoData} inputData 
	 */
	_reInit(inputData) {
		super._reInit(inputData);
        this._renderingPanoDataVer = inputData._renderingPanoDataVer || latestDataVer;

		if (this._renderingPanoDataVer !== latestDataVer) { throw new Error(`Metadata version not latest: Got ${this._renderingPanoDataVer}, should be ${latestDataVer}`); }
	}

	serialize() {
		const ser = {
			...super.serialize(),
			_renderingPanoDataVer: this._renderingPanoDataVer,
		};

		return cleanUndefinedKeysRecurse(ser);
	}

	getUpdatableData() {
		// we can only request to directly update these
		const updates = {
			...super.getUpdatableData(),
			_renderingPanoDataVer: this._renderingPanoDataVer, // even though we can't technically update this, send it so the backend can throw if we are sending the wrong data ver
		};

		return cleanUndefinedKeysRecurse(updates);
	}
}